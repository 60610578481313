$icomoon-font-family: "ihp" !default;
$icomoon-font-path: "/src/app/fonts/icons" !default;

$icon-cancel1: "\e95c";
$icon-privacy: "\e95a";
$icon-doctor: "\e95b";
$icon-hand-02: "\e959";
$icon-hand: "\e958";
$icon-user-03: "\e956";
$icon-user-02: "\e957";
$icon-rx: "\e955";
$icon-star: "\e954";
$icon-prescription: "\e952";
$icon-note: "\e953";
$icon-trash: "\e950";
$icon-edit: "\e951";
$icon-telephone: "\e94e";
$icon-information: "\e94f";
$icon-menu-03: "\e949";
$icon-menu-04: "\e94a";
$icon-checklist: "\e94b";
$icon-discount: "\e941";
$icon-secure-payment: "\e942";
$icon-money-back: "\e943";
$icon-search-2: "\e944";
$icon-shopping-bag: "\e945";
$icon-user: "\e946";
$icon-credit-card2: "\e947";
$icon-globe: "\e940";
$icon-thumbs-up: "\e93e";
$icon-airmail: "\e935";
$icon-check: "\e93c";
$icon-carousel-right: "\e936";
$icon-carousel-left: "\e939";
$icon-shipping: "\e93a";
$icon-transit: "\e937";
$icon-countries: "\e938";
$icon-categories: "\e93b";
$icon-menu02: "\e917";
$icon-cart: "\e900";
$icon-close: "\e901";
$icon-dropdown: "\e902";
$icon-menu: "\e903";
$icon-search: "\e904";
$icon-plus: "\e92b";
$icon-minus: "\e910";
$icon-arrow-down-left: "\e905";
$icon-arrow-down-right: "\e906";
$icon-arrow-up-left: "\e907";
$icon-arrow-up-right: "\e908";
$icon-location-arrow: "\e909";
$icon-arrow-right: "\e90a";
$icon-arrow-left: "\e90b";
$icon-arrow-down: "\e90c";
$icon-arrow-up: "\e90d";
$icon-right-quote: "\e92c";
$icon-left-quote: "\e92d";
$icon-vpn_key: "\e94c";
$icon-account_circle: "\e94d";
$icon-arrow_back_ios: "\e911";
$icon-arrow_forward_ios: "\e91d";
$icon-arrow_right_alt: "\e90e";
$icon-compare_arrows: "\e90f";
$icon-arrow_right: "\e912";
$icon-arrow_left: "\e913";
$icon-arrow_drop_up: "\e914";
$icon-arrow_drop_down_circle: "\e915";
$icon-arrow_drop_down: "\e916";
$icon-keyboard_arrow_up: "\e918";
$icon-keyboard_arrow_right: "\e919";
$icon-keyboard_arrow_left: "\e91a";
$icon-keyboard_arrow_down: "\e91b";
$icon-play_arrow: "\e91c";
$icon-arrow_back: "\e91e";
$icon-keyboard_backspace: "\e91f";
$icon-home: "\e920";
$icon-local_shipping: "\e921";
$icon-credit_card: "\e922";
$icon-local_print_shop: "\e923";
$icon-print: "\e923";
$icon-person: "\e924";
$icon-warning: "\e925";
$icon-clear: "\e926";
$icon-cancel: "\e927";
$icon-info_outline: "\e928";
$icon-info: "\e929";
$icon-check_circle: "\e92a";
$icon-fax: "\e92e";
$icon-credit-card-alt: "\e92f";
$icon-cc-mastercard: "\e930";
$icon-credit-card: "\e931";
$icon-address-card: "\e932";
$icon-vcard: "\e932";
$icon-check-square-o: "\e933";
$icon-facebook-official: "\e934";
$icon-credit-card1: "\e93f";
$icon-truck: "\e9b0";
$icon-exit: "\ea14";
$icon-chat: "\e948";
$icon-spinner: "\e93d";
