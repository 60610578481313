
/*------------------------------------*\
    Colors
\*------------------------------------*/
$white: #fff;
$black: #000;

$primary-color: #ec2024;
$secondary-color: #1b6c0d;

// $danger: #d84a4f;
$danger: $primary-color;
// $success: #5bc3a2;
$success: #1b6c0d;
$warning: #ecbf58;
$info: #318eff;

$light-grey: #f2f2f2;
$dark-grey: #808080;


$color-info: #e2eefb;


/*------------------------------------*\
    Text
\*------------------------------------*/
$text-color: #666;
$text-color2: #404040;
$link-color: $primary-color;
$link-color2: darken($text-color, 10);
$text-light-color: #808080;
$bg-color: #f3f3f3;
$menu-bg: #e6e6e6;
