
/*------------------------------------*\
    BASE
\*------------------------------------*/

html {
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    position: relative;
    margin: 0 auto;
    text-align: left;
    font-family: $font-family;
    color: $text-color;
    font-size: 13px;
    font-weight: normal;
    background-color: #fff;
}

a {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
        color: $link-color;
        text-decoration: none;
    }
}

.page-main {
    @include clearfix;
}

img:focus, img:focus-within{
    outline: none;
}
