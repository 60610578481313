/*------------------------------------*\
    SEALS
\*------------------------------------*/
.site-seals {
    a {
        display: inline-block;
        margin-right: 2px;
        vertical-align: top;

        &:last-child {
            margin-right: 0;
        }
    }
}

.fshipping-images {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    .seals-secure {
        width: 55px;
    }

    .seals-digicert {
        width: 48px;
    }
}

// .fshipping-secure {
//     display: inline-block;
//     width: 55px;
//     vertical-align: middle;
//     margin-right: 2px;
// }
//
// .fshipping-digicert {
//     display: inline-block;
//     width: 50px;
//     height: auto;
//     vertical-align: middle;
// }

/*------------------------------------*\
    Sidebar Trustpilot
\*------------------------------------*/
.sidebar-trustpilot {
    margin-bottom: 20px;
    padding: 10px;
}


/*------------------------------------*\
    Footer Seals
\*------------------------------------*/
.footer-seals .site-seals a.seals-secure.securetrust img {
    /*max-width: 78px;*/
    width: 83px;
}

.footer-seals .site-seals .seals-secure {
    margin-right: 6px;
}