
/*------------------------------------*\
    FAQ
\*------------------------------------*/
.sitemap-content {
    position: relative;
    margin: 20px 0 40px;
    padding: 0;
    list-style: none;
    font-size: 15px;

    @media #{$grid-md-max} {
        padding-left: 18px;
        padding-right: 18px;
    }

    @media #{$grid-md-min} {
        margin: 30px 0 60px;
        padding-top: 80px;
    }

    > li {
        @media #{$grid-md-min} {
            margin-top: 40px;
        }

        &:first-child {
            margin-top: 0;

            @media #{$grid-md-min} {
                columns: 2 auto;
                column-gap: 40px;
            }

            @media #{$grid-lg-min} {
                columns: 3 auto;
                column-gap: 75px;
            }

            > ul {
                margin-bottom: 30px;

                > li {
                    display: inline-block;

                    > a {
                        display: block;
                        margin-bottom: 6px;
                        font-weight: bold;
                    }

                    > ul {
                        margin: 0;
                        padding: 0;

                        ul {
                            padding-left: 15px;
                        }
                    }
                }
            }

            > a {
                @media #{$grid-md-min} {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                }

                @media #{$grid-md-min} and #{$grid-md-max} {
                    left: 18px;
                    right: 18px;
                }
            }
        }


        > ul {
            margin: 0;
            padding: 0;
        }

        > a {
            display: block;
            margin-bottom: 20px;
            padding: 0 0 20px;
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #d6d6d6;

            @media #{$grid-md-min} {
                margin-bottom: 30px;
                padding-top: 20px;
            }
        }
    }

    ul {
        list-style: none;
    }

    li {
        padding: 8px 0 6px;
        display: block;
    }

    a {
        line-height: 1.1;
        text-decoration: none;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
            color: inherit;
        }
    }
}
