/*------------------------------------*\
    CHECKOUT
\*------------------------------------*/

.checkout-container {
    margin: 0 auto;

    @media #{$grid-md-min} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .address_box {
        @include clearfix;
        margin-bottom: 50px;
        padding-bottom: 20px;
    }

    h2 {
        border-bottom: 1px solid #000000;
        padding-bottom: 15px;
        margin-bottom: 30px;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: #000000;

        @media #{$grid-md-min} {
            font-size: 21px;
        }

        a {
            font-size: 80%;
            text-decoration: underline;
            white-space: nowrap;
        }
    }
}

.checkout-section {
    margin-bottom: 50px;
}

.checkout-content {
    @include clearfix;

    @media #{$grid-md-min} {
        flex-basis: calc(70% - 32px);
    }
}

.checkout-sidebar {
    margin-bottom: 30px;

    @media #{$grid-md-min} {
        flex-basis: 30%;
    }
}

.checkout-panel {
    margin-bottom: 24px;
    margin-top: 23px;
    margin-left: 34px;
    background-color: #f3f3f3;
    padding: 10px 30px 30px;
    margin-right: 17px;
    border: solid 1px #dbdbdb;
    border-radius: 5px;
    color: black !important;

    h3 {
        margin-bottom: 5px;
        font-size: 15px;
    }

    .form-group:last-child {
        margin-bottom: 0;
    }
}

.static_content {
    @include clearfix;
}

/*------------------------------------*\
    Dr's info
\*------------------------------------*/
.checkout-panel-result {
    display: none;
}

.static_content,
.left_static_content {
    .checkout-panel-form {
        display: none;
    }

    .checkout-panel-result {
        display: block;
    }

    .checkout-panel {
        color: #808080 !important;
    }
}

.error-same-doctor-patient-name {
    display: none;
    color: $danger;
}

/*------------------------------------*\
    Shipping
\*------------------------------------*/
#staticShippingAddressblock,
#staticBillingAddressblock {
    position: relative;
}

.checkout-shipping-edit {
    position: absolute;
    top: 0;
    right: 0;

    .icon-edit {
        padding-right: 5px;
    }
}

.checkout-shipping-btn {
    margin-bottom: 30px;
}

#dynamicShippingMethod {
    td {
        position: relative;
    }

    input[type="radio"] {
        position: absolute;
    }

    label {
        display: block;
        position: relative;
        top: -1px;
        margin-bottom: 10px;
        padding-left: 28px;
    }
}

#staticShippingMethod {
    input,
    label {
        display: none;
    }

    input[type="radio"]:checked + label {
        display: block;
    }
}

#staticPaymentMethod {
    > strong {
        padding-left: 10px;
    }
}

.checkout-billing-sub-title {
    display: block;
    margin-bottom: 20px;

    br {
        display: none;
    }
}

#billingEqualsShippingBlock {
    &.static_content {
        display: none;
    }
}

.checkout-billing-content {
    margin-bottom: 50px;

    @media #{$grid-lg-min} {
        margin-bottom: 80px;
    }
}

.checkout-shipping-commercial {
    .checkout_txtbx_commercialaddress {
        display: none !important;
    }
}

// .checkout-billing-content {
//     &.same-address {
//         #dynamicBillingAddress,
//         #billSameMessageBlock {
//             display: none !important;
//         }
//     }
// }

/*------------------------------------*\
    Payment
\*------------------------------------*/
.checkout-payment-method {
    &.static_content {
        .checkout-payment-method-info {
            display: none;
        }
    }
}

.checkout-payment-method-info {
    margin-bottom: 30px;
    font-weight: 700;
}

.echeckTerms_notice {
    .checkout_txtbx {
        margin-top: 40px;
    }
}

#staticPaymentMethodblock {
    margin-bottom: 30px;
}

#sign-pad {
    width: 100% !important;
    height: 100px !important;
    border: 1px solid #919593;
}

.signature_lbl {
    margin-bottom: 10px;
}

.signature-pad--body {
    position: relative;
    height: auto !important;
    padding-bottom: 15px;
}

.signature_caption {
    position: absolute;
    top: 46px !important;
    padding: 10px;
    pointer-events: none;

    @media #{$grid-md-min} {
        top: 36px !important;
    }
}

.prescription_h2 {
    margin-bottom: 20px!important;
}

.prescription_skip_container {
    height: 30px;
    margin-bottom: 20px;
}

.payment_method {
    .checkout-continue {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.checkout-container {
    .payment_method_selector {
        position: relative;

        span {
            display: block;

            label {
                display: inline-block;
                padding: 10px 20px 10px 0;

                * {
                    pointer-events: none;
                }
            }
        }
    }
}

#showHelpBtn_PaperCheck,
#hideHelpBtn_PaperCheck {
    position: absolute;
    top: 12px;
    right: 0;
}

/*------------------------------------*\
    Sidebar
\*------------------------------------*/
.checkout-sidebar-placeorder {
    display: block;
    margin-top: 30px;
    text-align: center;

    @media #{$grid-md-min} {
        display: block;
        margin-top: 15px;
    }
}

/*------------------------------------*\
    Order Summary
\*------------------------------------*/

#DOBBlock {
    .checkout_lbl {
        position: absolute;
        top: 5px;
        font-size: 12px;
        left: 0;
        padding-left: 0;
    }

    .checkout_txtbx_dob_ct {
        display: none !important;
    }
}

#btnShowDOBHelpBlock {
    position: relative;
    top: 8px;
}

.checkout_field_inner {
    @include clearfix;
    display: block !important;
    margin-bottom: 20px;
}

#dynamicShippingAddress {
    .checkout_field_inner {
        position: relative;
        margin-top: 7px;
        padding-left: 20px;

        input {
            position: absolute;
            top: 1px;
            left: 0;
        }
    }
}

/*------------------------------------*\
    Create an account
\*------------------------------------*/
.checkout-create-account {
    @include clearfix;
    margin-bottom: 30px;
}

.checkout-create-account-item {
    input[type="radio"] {
        position: absolute;
        top: 1px;
        left: 0;
    }

    label {
        display: block;
        position: relative;
        padding-left: 28px;
        margin-bottom: 10px;
    }

    .left_static_content & {
        input[type="radio"] {
            display: none;
        }

        label {
            padding-left: 0;
            pointer-events: none;
            color: #808080;
        }
    }
}

.checkout-place-order-lg {
    margin-bottom: 50px;

    .checkout-place-order {
        min-width: 280px;
    }
}

.checkout-sidebar-placeorder {
    .checkout-place-order {
        width: 100%;
    }
}

.skin7,
.skin11,
.skin14 {
    #subscriptionBlock.static_content,
    #termsBlock.left_static_content,
    #termsBlock.static_content,
    #createAccount {
        @include clearfix;

        @media #{$grid-md-min} {
            margin-bottom: 100px;
        }
    }
}

#createAccount,
#termsBlock {
    margin-bottom: 50px;
}

#staticPrescriptionBlock {
    &.static_content {
        input[type="radio"] {
            display: none;
        }
    }
}

.order-summary-details {
    .checkout-rx-radio-title {
        padding-left: 0;
    }
}

.order-summary-notes {
    h4 {
        margin-top: 0;

        small {
            display: block;
            margin-top: 7px;
            font-weight: 400;
            font-size: 13px;
        }
    }
}
