
/*------------------------------------*\
    Colors
\*------------------------------------*/
// $white: #fff;
// $black: #000;
// $danger: #ec2024;
// $success: #008000;
// $light-grey: #f2f2f2;
// $dark-grey: #808080;

$primary-color: #00c0f3;
$secondary-color: #1b6c0d;


/*------------------------------------*\
    Text
\*------------------------------------*/
// $text-color: #666;
// $text-color2: #404040;
$link-color: $primary-color;
// $link-color2: darken($text-color, 10);
// $text-light-color: #808080;
// $bg-color: #f3f3f3;
// $menu-bg: #e6e6e6;
