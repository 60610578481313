/*------------------------------------*\
    PROGRESS BAR
\*------------------------------------*/
.order-summary-content {
    margin: 30px 0 40px;
}

.order-summary-item {
    position: relative;
    margin: 10px 0 20px;
    padding: 24px 30px;
    background-color: $color-info;
}

.order-summary-title {
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
}

.order-summary-details {
    > strong {
        font-weight: 400;
    }
}

.order-summary-edit {
    position: absolute;
    right: 24px;
    top: 30px;
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}
