
/*------------------------------------*\
    SLIDER
\*------------------------------------*/
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    img {
        display: block;
    }
}



/*------------------------------------*\
    DOTS
\*------------------------------------*/
.slick-dots {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%, 0);
    list-style: none;

    li {
        display: inline-block;
        margin: 0 2px;

        @media #{$grid-sm-max} {
            margin: 0 5px;
        }
    }

    button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 11px;
        height: 11px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 1px solid #fff;
        outline: none;
        background: transparent;
        border-radius: 50%;

        @media #{$grid-md-min} {
            width: 13px;
            height: 13px;
        }

        @media #{$grid-sm-max} {
            width: 20px;
            height: 20px;
        }
    }

    .slick-active {
        button {
            background: #fff;
        }
    }
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 35px;
    height: 35px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #888;
    border: none;
    outline: none;
    background: rgba(255, 255, 255, .8);
    border-radius: 50%;
    z-index: 10;

    &:before {
        position: relative;
        bottom: 1px;
        @include icon;
        font-size: 15px;
    }
}

.slick-prev {
    left: 20px;

    &:before {
        content: $icon-carousel-left;
        right: 1px;
    }
}

.slick-next {
    right: 20px;

    &:before {
        left: 1px;
        content: $icon-carousel-right;
    }
}
