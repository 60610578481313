
/*------------------------------------*\
    Variables
\*------------------------------------*/

// Media Queries
$grid-xs-max: "(max-width: 425px)";

$grid-sm-min: "(min-width: 576px)";
$grid-sm-max: "(max-width: 767px)";

$grid-md-min: "(min-width: 768px)";
// $grid-md-max: "(max-width: 991px)";
$grid-md-max: "(max-width: 1024px)";

$grid-lg-min: "(min-width: 1025px)";
// $grid-lg-min: "(min-width: 992px)";
$grid-lg-max: "(max-width: 1199px)";

$grid-xl-min: "(min-width: 1200px)";
$grid-xxl-max: "(max-width: 1399px)";

$grid-xxl-min: "(min-width: 1400px)";

$grid-size: 960px;
$gutter: 20px;
$gutter-sm: 15px;

/* UNIQID */
$uniqeid: unique_id();


/*------------------------------------*\
    FONT
\*------------------------------------*/
$font-family-name: 'Arial MT';
$font-family: $font-family-name, Arial, Helvetica, sans-serif;
$font-base-font-size: 13px;


/*------------------------------------*\
    Buttons
\*------------------------------------*/
$btn-default-color: #666;
$btn-success-color: $secondary-color;
$btn-default-reverse-border: #ccc;
$btn-grey-color: #efefef;

$btn-padding: 8px 18px;
$btn-border: 1px solid transparent;
$btn-border-radius: 7px;
$btn-letter-spacing: initial;
$btn-text-transform: inherit;
$btn-font-weight: 400;
$btn-line-height: 1;


$btn-lg-padding: 5px 18px 4px;
$btn-lg-font-size: 16px;


/*------------------------------------*\
    Forms
\*------------------------------------*/
$input-padding: .375rem .75rem;
$select-padding: .375rem 2rem .375rem .75rem;
$input-border-radius: .25rem;
$label-margin-bottom: .5rem;
$input-line-height: 1.5;

$checkbox-bg: $btn-success-color;


/*------------------------------------*\
    Product page
\*------------------------------------*/
$btn-pdp-add-to-cart: $btn-success-color;


/*------------------------------------*\
    Checkout
\*------------------------------------*/
$progress-bar: $secondary-color;
$checkout-btn-placeorder: $btn-success-color;


/*------------------------------------*\
    Cookies Button
\*------------------------------------*/
$btn-cookies-accept: $btn-success-color;
$btn-cookies-close: $btn-default-color;
