
/*------------------------------------*\
    TABLE
\*------------------------------------*/

.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;

    thead th {
        padding-bottom: .75rem;
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
    }
    
    td,
    th {
        padding: 1.25rem;
        vertical-align: top;
    }

    td {
        border-top: 1px solid #dee2e6;
    }
}

.table-shipping-country {
    td,
    th {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
    th {
        font-size: 15px;
        font-weight: 500;
        
        @media #{$grid-md-min} {
            font-size: 19px;
        }
    }
}