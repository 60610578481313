
$page-grid: 35px;
$banner-height-sm: 66px;
$banner-height-lg: 122px;

/*------------------------------------*\
    PAGE BANNER
\*------------------------------------*/
.page-banner,
.faq-banner {
    position: relative;
    margin: 15px 0 30px;

    h1 {
        font-size: 22px;
        line-height: 1.3em;
        z-index: 1;

        @media #{$grid-md-min} {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            padding-left: 20px;
        }
    }

    &:after {
        display: block;
        margin: 0 -20px;
        height: $banner-height-sm;
        content: '';
        background-image: url(../images/faq/banner.jpg);
        background-size: cover;
        background-position: center right;
        background-repeat: no-repeat;

        @media #{$grid-md-min} {
            height: $banner-height-lg;
            margin: 0;
        }
    }
}

.skin5 {
  .page-banner,
  .faq-banner {

    &:after {
      background: #f1f3f5;
    }
  }
}
