/*------------------------------------*\
    ADDRESS
\*------------------------------------*/

.acct-section.acct-order-history {
    background: transparent;
    padding: 0;
}

.order-list {
}

.order-item {
    margin-bottom: 40px;
    background: #fff;
    border: 1px solid #f6f6f6;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.order-item-top {
    padding: 22px 20px;
    background: #fbfbfb;
}

.order-item-middle {
    padding: 15px 20px;

    @media #{$grid-md-min} {
        margin: 0 -15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .item {
        padding: 10px 0;

        @media #{$grid-md-min} {
            text-align: center;
            padding: 15px;
            flex-basis: 33.333%;
        }
    }
}

.order-item-bottom {
    border-top: 1px solid #f3f3f3;
    padding: 15px 20px;
    text-align: right;
}

.order-number {
    display: block;
    margin-bottom: 3px;
    color: $primary-color;
    font-size: 1.15em;
    font-weight: 700;

    @media #{$grid-md-min} {
        display: inline-block;
        margin-bottom: 0;
        padding-right: 6px;
    }
}

.order-date {
    display: inline-block;
}

.order-links {
    float: right;

    a {
        display: inline-block;
        padding: 0 5px;
        border-right: 1px solid $text-color;

        @media #{$grid-md-min} {
            padding: 0 10px;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            border-right: 0;
        }
    }
}

.order-products,
.order-notes {
    border-top: 1px solid #f3f3f3;
    padding: 15px 20px;
}

.order-total {
    font-weight: 700;
    font-size: 1.1em;

    .title {
        display: inline-block;
        color: initial;
    }

    .price {
        display: inline-block;
        width: 40%;

        @media #{$grid-md-min} {
            width: 15%;
        }
    }
}

.order-payment {
    .payment-label {
        display: none;
    }

    span {
        display: block;
    }
}

.order-item-title {
    display: block;
    margin-bottom: 7px;
    font-weight: 700;
}

.order-item-title {
}
