@mixin container() {
    width: 100%;
    padding-right: $gutter-sm;
    padding-left: $gutter-sm;
    margin-right: auto;
    margin-left: auto;

    @media #{$grid-md-max} {
        padding-right: $gutter;
        padding-left: $gutter;
        max-width: 100%;
    }

    // @media #{$grid-md-min}{
    //     max-width: 720px;
    // }

    @media #{$grid-lg-min} {
        max-width: $grid-size;
    }

    @media #{$grid-xl-min} {
        max-width: 1140px;
    }
}

/*------------------------------------*\
    CONTAINER
\*------------------------------------*/
.container {
    @media #{$grid-md-max} {
        padding-right: $gutter;
        padding-left: $gutter;
        max-width: 100%;
    }

    // @media #{$grid-lg-min}{
    //     max-width: $grid-size;
    // }

    // @media #{$grid-xl-min}{
    //     max-width: 1140px;
    // }
}

.page-container {
    @include clearfix;

    @media #{$grid-lg-min} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.page-content {
    margin-bottom: 40px;
    @media #{$grid-lg-min} {
        flex-basis: 70%;
    }
}

.page-sidebar {
    @media #{$grid-md-max} {
        display: none;
    }
    @media #{$grid-lg-min} {
        flex-basis: 30%;
        padding-left: 32px;
    }
}

/*------------------------------------*\
    PAGE TITLE
\*------------------------------------*/
.page-title {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;

    @media #{$grid-md-min} {
        margin-bottom: 50px;
        font-size: 28px;
    }
}

/*------------------------------------*\
    Random
\*------------------------------------*/

.img-fluid {
    max-width: 100%;
    height: auto;
}

.clearfix {
    @include clearfix;
}

.hidden,
.hidden-ihp,
.hidden-ihc,
.hidden-pr,
.hidden-5star {
    display: none !important;
}

.hidden-xs {
    @media #{$grid-sm-max} {
        display: none !important;
    }
}

.visible-sm {
    @media #{$grid-md-min} {
        display: none !important;
    }
}

.visible-md {
    @media #{$grid-lg-min} {
        display: none !important;
    }
}

.hidden-sm {
    @media #{$grid-md-max} {
        display: none !important;
    }
}

.noscroll {
    overflow: hidden;

    @media #{$grid-lg-min} {
        margin-right: 17px;
    }
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.back-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #666;
    color: #fff;
    font-size: 1.6rem;
    cursor: pointer;
    opacity: 0;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    visibility: hidden;

    @media #{$grid-lg-min} {
        right: 40px;
        bottom: 95px;
        height: 45px;
        width: 45px;

        .skin5 & {
            bottom: 40px;
        }
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.show {
        opacity: 0.75;
        visibility: visible;
    }

    .skin14 & {
        @media #{$grid-lg-min} {
            bottom: 50px;
        }
    }
}

.panel {
    margin: 20px 0;
    padding: 24px 30px;
    // border-radius: 5px;

    h3 {
        margin-bottom: 5px;
        font-size: 15px;
    }
}

.panel-default {
    background-color: #f1f3f5;
    // border: solid 1px #DBDBDB;
}

.panel-info {
    background-color: $color-info;
}

.message {
    display: block;
    position: relative;
    margin: 10px 0 15px;
    padding: 24px 30px 24px 51px;

    &:before {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 21px;
        @include icon;
    }

    &.success {
        background: rgba($success, 0.15);

        &:before {
            color: $success;
            content: $icon-check_circle;
        }
    }

    &.danger {
        background: rgba($danger, 0.15);
        // background: lighten($danger, 37%);

        &:before {
            color: $danger;
            content: $icon-warning;
        }
    }

    &.info {
        // background: lighten($info, 37%);
        background: rgba($info, 0.15);

        &:before {
            color: $info;
            content: $icon-info;
        }
    }

    a {
        text-decoration: underline;
    }
}
