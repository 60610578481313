/*------------------------------------*\
    Product listings page
\*------------------------------------*/
.category-container {
    @include clearfix;

    @media #{$grid-lg-min} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .skin14 & {
        margin-top: 30px;
        @media #{$grid-lg-min} {
            flex-direction: row-reverse;
        }
    }
}

.category-content {
    margin-bottom: 40px;
    @media #{$grid-lg-min} {
        flex-basis: 70%;
    }
}

.category-sidebar {
    @media #{$grid-md-max} {
        display: none;
    }
    @media #{$grid-lg-min} {
        flex-basis: 30%;
        padding-left: 32px;
    }

    .skin14 & {
        @media #{$grid-lg-min} {
            padding-left: 0;
            padding-right: 32px;
        }
    }
}

.category-name {
    margin: 15px 0 20px;
    font-size: 27px;
    font-weight: 400;
    color: $text-color;
}

.category-summary {
    margin-bottom: 20px;
    line-height: 1.6;
}

.category-desc {
    position: relative;
    margin-top: 20px;
    line-height: 1.6;

    &-content {
        position: relative;
        overflow: hidden;
        height: 200px;
    }
}
/*------------------------------------*\
    Sub categories
\*------------------------------------*/
.subcat-summary {
    margin-bottom: 30px;
    line-height: 1.6;

    > br {
        display: none;
    }
}

.subcat-desc {
    margin-top: 20px;
    line-height: 1.6;
}

.subcat-desc-content {
    height: 200px;
}

.subcat-item {
    @include clearfix;
    border-bottom: 1px solid #c3c3c3;

    &.active {
        .subcat-item-link:before {
            content: $icon-arrow_drop_down;
        }
    }
}

.subcat-item-link {
    position: relative;
    padding: 23px 0 23px 18px;
    text-transform: uppercase;
    color: #333;

    &:before {
        @include icon;
        display: inline-block;
        position: absolute;
        top: 48%;
        left: -6px;
        transform: translate(0, -50%);
        content: $icon-arrow_right;
        font-size: 20px;
    }

    &:hover {
        color: $primary-color;
        cursor: pointer;
    }
}

.subcat-item-content {
    display: none;
    padding: 20px 0 50px;
    border-top: 1px solid #c3c3c3;
}

.subcat-item-products {
    @include clearfix;
    position: relative;
    min-height: 120px;

    .loader {
        position: absolute;
        color: $text-color;
        background: transparent;
    }
}
/*------------------------------------*\
    Product item list
\*------------------------------------*/

.product-items {
    @include clearfix;
    padding: 20px 0;
    background-image: linear-gradient(
        90deg,
        #c3c3c3 50%,
        hsla(0, 0%, 100%, 0) 0
    );
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;

    .item {
        position: relative;
        width: 45%;
        float: left;
        @media #{$grid-md-min} {
            width: 50%;
        }
    }

    .generic {
        float: right;
    }

    a {
        display: block;
    }
}

.product-item-image-wrapper {
    @media #{$grid-md-min} {
        width: 40%;
        float: left;
    }

    img {
        width: 80px;
        margin: 0 auto;
    }
}

.product-item-info {
    word-break: break-word;
    @media #{$grid-md-max} {
        text-align: center;
    }
    @media #{$grid-md-min} {
        width: 60%;
        float: left;
    }
}
/*------------------------------------*\
    Product item name
\*------------------------------------*/

.product-item-name {
    display: block;
    font-weight: 500;

    .skin14 & {
        font-weight: 700;
    }
}
/*------------------------------------*\
    GENERIC
\*------------------------------------*/

.product-item-generic {
    display: inline-block;
    margin: 5px 0 3px;
    padding: 2px 10px;
    background-color: #666;
    color: #fff;
    font-size: 11px;

    .skin14 & {
        background-color: #f08080;
    }
}

.product-list-head {
    @include clearfix;
    position: relative;
    margin: 18px 0;
}

.product-list-head-item {
    width: 50%;
    float: left;
    padding: 0 8px;
    text-align: center;

    > span {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        padding: 3px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        background-color: #619aac;
        @media #{$grid-md-min} {
            max-width: 180px;
        }

        &.generic {
            background-color: #f08080;
        }
    }
}
