/*------------------------------------*\
    ADDRESS
\*------------------------------------*/
.page-main {
    .address-form-container {
        display: none;
    }
}

.adress-book-container {
    margin-bottom: 20px;
}

.address {
    font-style: normal;

    > span {
        display: block;
    }
}

/*------------------------------------*\
    TOP
\*------------------------------------*/
.address-top {
    margin-bottom: 20px;

    @media #{$grid-md-min} {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.address-default-billing,
.address-default-shipping {
    margin-bottom: 30px;

    @media #{$grid-md-min} {
        flex-basis: calc((100% + 35px) / 2 - 35px);
    }
}

/*------------------------------------*\
    OTHER ADDRESS
\*------------------------------------*/
.address-list {
    margin-bottom: 30px;

    @media #{$grid-md-min} {
        margin: 0 -15px 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.address-item {
    margin-bottom: 30px;
    border-radius: $btn-border-radius;

    @media #{$grid-md-min} {
        margin-bottom: 0;
        padding: 15px;
        flex-basis: 33.333%;
    }

    // .skin5 & {

    // }

    @media #{$grid-xl-min} {
        flex-basis: 25%;
    }

    &.active {
        .address-content {
            padding-bottom: 80px;
            border: 2px solid $success;
        }

        .address-selected-label {
            display: block;
        }
    }

    .form-check.disabled {
        opacity: 0.35;
        pointer-events: none;
    }
}

.address-content {
    position: relative;
    padding: 30px;
    height: 100%;
    background: #fff;
    border: 1px solid #919593;
    transition: 0.3s all;
}

.address-selected-label {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 24px 30px 24px 51px;
    background: rgba($success, 0.15);

    &:before {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 21px;
        @include icon;
        content: $icon-check_circle;
        color: $success;
    }
}

.address-name {
    font-size: 1.15em;
}

.address-details {
    @media #{$grid-md-min} {
        min-height: 140px;
    }

    .message {
        margin-top: 20px;
    }
}

/*------------------------------------*\
    ADD
\*------------------------------------*/
.address-item-add {
    padding: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s all;

    @media #{$grid-md-min} {
        min-height: 300px;
    }

    &:hover {
        background: rgba(145, 149, 147, 0.05);
        color: $primary-color;
    }

    i {
        display: inline-block;
        position: relative;
        top: -3px;
        padding-right: 5px;
        font-size: 23px;
    }
}

/*------------------------------------*\
    BUTTONS
\*------------------------------------*/
.address-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
}

.address-button-item {
    display: inline-block;
    padding: 5px;
    font-size: 15px;
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}

.address-form-btn {
    margin: 50px auto 0;
    text-align: center;

    .btn-success {
        width: 100px;
    }
}

/*------------------------------------*\
    CHECKBOX
\*------------------------------------*/
.address-options {
    margin: 15px 0 5px;
}

/*------------------------------------*\
    MODAL CONFIRM
\*------------------------------------*/
.modal-address-confirm {
    .modal-content {
        text-align: center;
    }

    h3 {
        margin-bottom: 40px;
        font-size: 16px;
    }
}
