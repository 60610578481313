// $progress-bar-current: $secondary-color;
$progress-bar-pending: $light-grey;
$progress-bar-icon-size: 40px;
$progress-bar-icon-size-lg: 40px;

/*------------------------------------*\
    PROGRESS BAR
\*------------------------------------*/
.progress-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    padding: 0;
    list-style: none;
    counter-reset: i;
    z-index: 1;

    @media #{$grid-md-min} {
        margin-bottom: 60px;
    }
}

.progress-bar-item {
    position: relative;
    flex: 1 1 0px;
    text-align: center;
    pointer-events: none;

    &:before {
        content: "";
        display: block;
        height: 5px;
        width: 100%;
        position: absolute;
        top: 18px;
        background: $progress-bar-pending;
        z-index: -1;
    }

    &:first-child {
        &:before {
            width: 50%;
            left: 50%;
        }
    }

    &:last-child {
        &:before {
            width: 50%;
        }
    }

    a {
        display: block;
    }

    span {
        display: none;
        counter-increment: i;

        @media #{$grid-md-min} {
            display: block;
        }

        &:before {
            display: inline-block;
            content: counter(i) ".";
        }
    }

    &._complete,
    &._active {
        pointer-events: auto;
        color: $progress-bar;
        font-weight: 700;

        &:before {
            background: $progress-bar;
        }

        .progress-bar-icon {
            color: $progress-bar;
            border-color: $progress-bar;
            background: #fff;
        }
    }

    &._active {
    }
}

.progress-bar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    height: $progress-bar-icon-size-lg;
    width: $progress-bar-icon-size-lg;
    border-radius: 50%;
    background: $progress-bar-pending;
    border: 1px solid $progress-bar-pending;
    color: lighten($text-color, 5%);
    font-size: 18px;

    i {
        display: block;
    }

    .progress-bar-shipping & {
        font-size: 21px;
    }

    .progress-bar-payment & {
        font-size: 20px;
    }

    .progress-bar-summary &,
    .progress-bar-prescription & {
        font-size: 16px;
    }
}
