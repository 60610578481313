/*------------------------------------*\
    ACCOUNTS
\*------------------------------------*/
.page-account {
    .breadcrumbs {
        @media #{$grid-sm-max} {
            display: none;
        }
    }
}

.acct-heading {
    position: relative;
    // padding-bottom: 12px;
    margin-bottom: 32px;
    // border-bottom: 1px solid #666;
    font-size: 17px;
    font-weight: 500;
    color: #000;

    @media #{$grid-md-min} {
        font-size: 22px;
    }
}

.acct-heading-link {
    position: relative;
    display: inline-block;
    padding-left: 5px;
    font-size: $font-base-font-size;
    text-decoration: underline;

    @media #{$grid-md-min} {
        float: right;
        padding-left: 0;
        font-size: 14px;
    }

    i {
        font-size: 16px;
        vertical-align: middle;
    }

    span {
        position: relative;
        top: 1px;
        vertical-align: middle;
    }
}

.account-container {
    @media #{$grid-md-min} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.account-content {
    @media #{$grid-md-min} {
        flex-basis: calc(100% - 250px);
    }
}

.account-sidebar {
    margin-bottom: 50px;

    @media #{$grid-md-min} {
        flex-basis: 230px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        line-height: 1.7;
        font-size: 14px;
    }

    li {
        display: block;
        padding: 8px 15px 12px;
        white-space: nowrap;

        @media #{$grid-md-min} {
            padding-left: 0;
            padding-right: 0;
        }

        i {
            position: absolute;
            top: 45%;
            left: 0;
            font-size: 15px;
            transform: translate(0, -50%);
        }
    }

    .icon-person {
        font-size: 23px;
    }

    .icon-doctor {
        font-size: 18px;
    }

    .icon-vpn_key {
        font-size: 16px;
    }
}

.acct-dropdown {
    margin: 0 auto 20px;

    @media #{$grid-sm-max} {
        @include dropdown;
    }

    .dropdown-toggle {
        display: block;
        padding-left: 15px;
        padding-right: 12px;
        border: 1px solid #919593;
        font-weight: 700;

        @media #{$grid-md-min} {
            display: none;
        }

        &:after {
            float: right;
        }
    }

    .dropdown-menu {
        width: 100%;
    }
}

.acct-dropdown-active {
    display: inline-block;
    position: relative;
    padding-left: 29px;

    i {
        position: absolute;
        top: 45%;
        left: 0;
        font-size: 15px;
        transform: translateY(-50%);
    }
}

.acct-link,
.acct-link-signout {
    display: block;
    position: relative;
    padding-left: 35px;

    &.active {
        color: $primary-color;
        font-weight: 700;
    }
}

.acct-section {
    margin-bottom: 50px;
    display: none;

    @media #{$grid-lg-min} {
        margin-bottom: 60px;
    }

    .form-buttons {
        margin-top: 35px;
    }

    &.active {
        display: block;
    }
}

.accountselect,
.accountinput {
    @include input-form;
}

.dob-title-wrapper {
    margin-bottom: 6px;
    margin-top: 4px;
    font-weight: 700;
}

.ddlBirthYear,
.ddlBirthMonth,
.ddlBirthDay {
    width: auto;
    padding-left: 0.375rem;
    margin-right: 5px;
    margin-bottom: 5px;
}

/*------------------------------------*\
    MANAGE ADDRESS
\*------------------------------------*/
.acct-address {
    margin-bottom: 30px;
}

.acct-address-title {
    display: block;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 700;
}

.acct-address-link {
    display: inline-block;
    margin-top: 21px;
    text-decoration: underline;
}

.page-manage-address {
    margin-top: 50px;
}

.manage-address-list {
    .btn-address-save,
    .btn-address-delete {
        display: inline-block;
        margin-right: 5px;
    }
}

.manage-address-item {
    margin-bottom: 40px;

    .acct-address-btn {
        .btn {
            margin-bottom: 10px;
        }
    }

    .btn-link {
        padding: 0 10px;
        text-decoration: underline;

        &:hover {
            color: $primary-color;
        }

        &:first-child {
            padding-left: 0;
        }
    }
}

.manage-address-back {
    text-align: center;
}

/*------------------------------------*\
    PERSONAL INFORMATION
\*------------------------------------*/
.acct-my-details {
    tr {
        &:nth-child(even) {
            td {
                padding-bottom: 10px;
            }
        }

        &:nth-child(-n + 8) {
            .accountlabel span {
                font-weight: 700;

                &:after {
                    content: "*";
                    display: inline-block;
                    color: $danger;
                }
            }
        }
    }
}

.cbOver18_Account {
    display: inline-block;
    padding-right: 10px;
    vertical-align: middle;
}

/*------------------------------------*\
    CHANGE PASSWORD
\*------------------------------------*/
.acct-change-password {
    tr {
        &:nth-child(even) {
            td {
                padding-bottom: 10px;

                span {
                    display: none;
                }
            }
        }

        .accountlabel span {
            font-weight: 700;

            &:after {
                content: "*";
                display: inline-block;
                color: $danger;
            }
        }
    }
}

/*------------------------------------*\
    ORDER HISTORY
\*------------------------------------*/

.order-history-table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    border: 1px solid #dee2e6;

    thead {
        th {
            padding: 1rem 0.75rem;
            text-align: center;
            vertical-align: bottom;
            background: #3c4453;
            border-bottom: 0;
            font-weight: 700;
            color: #fff;
        }
    }

    td,
    th {
        padding: 0.75rem 0.15rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    tbody {
        tr {
            &:nth-of-type(even) {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    .number {
        width: 82px;
        text-align: center;
    }

    .date {
        width: 100px;
        text-align: center;
    }

    .payment {
        width: 130px;
        text-align: center;
    }

    .total {
        width: 150px;
        text-align: center;
    }

    .prescription {
        text-align: center;
    }

    .payment-label {
        display: none;
    }

    .payment-status {
        display: block;
        color: $danger;
    }
}

/*------------------------------------*\
    Prescription
\*------------------------------------*/
// .account-rx-btn-wrapper {
//   margin: 10px 0;
//   text-align: center;
// }

.account-rx-btn {
    @include btn;
    @include btn-default;
}

.account-rx-input-file {
    display: none;
}

.page-subheading {
    display: block;
    margin-bottom: 20px;
    padding: 0 0 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #d6d6d6;

    @media #{$grid-md-min} {
        margin-bottom: 30px;
        padding-top: 20px;
    }
}

/*------------------------------------*\
    Privacy Preference
\*------------------------------------*/
.acct-consent-message {
    display: none;
}

/*------------------------------------*\
    Doctor's Info
\*------------------------------------*/
.acct-doctors-details {
    .input-wrapper {
        position: relative;
    }

    .form-group {
        &.valid,
        &.invalid {
            .input-wrapper:before {
                @include icon;
                display: inline-block;
                position: absolute;
                top: 48%;
                right: 10px;
                transform: translate(0, -50%);
                font-size: 18px;
            }
        }

        &.valid .input-wrapper:before {
            content: $icon-check_circle;
            color: $success;
        }

        &.invalid .input-wrapper:before {
            content: $icon-cancel;
            color: $danger;
        }
    }
}
