/*------------------------------------*\
    CART
\*------------------------------------*/

.cart-container {
    @media #{$grid-lg-min} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.cart-items-header {
    display: none;
}

.cart-content {
    margin-bottom: 40px;

    @media #{$grid-lg-min} {
        flex-basis: 70%;
    }
}

.cart-sidebar {
    @media #{$grid-lg-min} {
        flex-basis: 30%;
        padding-left: 32px;
    }
}

.cart-sidebar-title {
    padding: 7px 15px;
    background-color: #f3f3f3;
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;

    &:first-child {
        border-radius: 5px 5px 0px 0px;
    }
}

.cart-sidebar-content {
    padding: 15px;
    border: 1px solid #f3f3f3;

    p {
        margin-top: 0;
    }

    dl {
        @include clearfix;
        margin: 5px 0 8px;
    }

    dt,
    dd {
        margin: 0;
        float: left;
        width: 50%;
    }
}

/*------------------------------------*\
    CART ITEMS
\*------------------------------------*/
.cart-items {
    @media #{$grid-md-min} {
        .item {
            padding: 40px 40px 40px 90px;
        }
    }

    .item-name a {
        font-weight: 700;
        color: $text-color;
    }
}

/*------------------------------------*\
    Summary
\*------------------------------------*/

.cart-summary {
    margin: 10px 0 30px;

    @media #{$grid-md-min} {
        width: 100%;
        float: right;
        max-width: 450px;
    }

    .total {
        font-size: 22px;
    }
}

/*------------------------------------*\
    Links
\*------------------------------------*/
.order-history-sidebar,
.cart-sidebar,
.minicart_body {
    a {
        display: block;
        position: relative;
        padding: 5px 0 5px 20px;

        &:before {
            @include icon;
            display: inline-block;
            position: absolute;
            top: 48%;
            left: 0;
            transform: translate(0, -50%);
            content: $icon-arrow_forward_ios;
            // font-size: 18px;
        }
    }
}

.cart-top-button {
    text-align: right;

    .btn {
        @media #{$grid-sm-max} {
            display: block;
            width: 100%;
            margin-bottom: 5px;
        }
    }
}

.btn-cart-shopping,
.btn-cart-to-checkout {
    @media #{$grid-sm-max} {
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }
}

/*------------------------------------*\
    Links
\*------------------------------------*/
.promotionlistitem {
    &:last-child {
        margin-bottom: 30px;
    }

    .promotionlistitemcode {
        display: block;
        font-weight: 700;
    }

    .promotioncodeentrylink {
        color: $danger;
    }
}

.promotioncodeentryerror {
    &:last-child {
        margin-bottom: 30px;
    }
}
