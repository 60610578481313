/*------------------------------------*\
    Dropdown
\*------------------------------------*/
@mixin dropdown-menu() {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8rem;
  padding: .5rem 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}

@mixin dropdown-menu-center() {
  @include dropdown-menu;
  left: 50%;
  transform: translateX(-50%);
}

@mixin dropdown-menu-right() {
  @include dropdown-menu;
  left: initial;
  right: 0;
}


.dropdown {
  position: relative;

  &.show {
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-toggle {
  cursor: pointer;
}

/*------------------------------------*\
    DROPDOWN
\*------------------------------------*/
@mixin dropdown() {
  .dropdown-toggle {
    display: inline-block;
    position: relative;
    padding: 16px 0 20px;

    &:after {
      @include icon;
      display: inline-block;
      content: $icon-keyboard_arrow_down;
    }
  }

  .dropdown-menu {
    @include dropdown-menu;
  }
}
