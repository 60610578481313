
/*------------------------------------*\
    Page not found
\*------------------------------------*/
.page-error-top {
    @include clearfix;
    padding: 21px 0 20px;
    background: #808080;
    color: #fff;
    text-align: center;

    h1 {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 28px;
    }
}

.page-error-banner {
    img {
        width: 100%;
    }
}

.page-error-search {
    .RadSearchBox {
        position: relative;
        width: 275px;
        margin: 0 auto 15px;

        .rsbInner {
            padding: 0px 2px 1px 5px;
            border-radius: 11px;
        }

        .rsbInput {
            height: 22px;
            font-style: normal;
            font-family: $font-family;
            font-size: 11px;
            color: #454545;
        }

        .rsbButtonSearch {
            position: absolute;
            top: 50%;
            right: 2px;
            height: auto;
            width: auto;
            margin: 0;
            padding: 3px 7px 3px 3px;
            vertical-align: initial;
            border-radius: 10px;
            background: #454545;
            transform: translateY(-50%);
            color: #fff;
            font-size: 11px;

            &:before {
                @include icon;
                display: inline-block;
                content: $icon-search;
                font-size: 13px;
                line-height: 11px;
                position: relative;
                top: 1px;
            }

            &:after {
                display: inline-block;
                content: 'Search';
            }

            .rsbIcon {
                display: none;
            }

            &:hover,
            &:active {
                border: 0;
                background: #454545;
            }
        }
    }
}

.page-error-common {
    color: #fff;

    @media #{$grid-lg-min} {
        font-size: 16px;
    }
}


.page-error-common-title {
    display: block;

    @media #{$grid-lg-min} {
        display: inline-block;
        padding-right: 15px;
    }
}

.page-common-products {
    display: block;
    margin-bottom: 5px;

    @media #{$grid-lg-min} {
        display: inline-block;
        margin-bottom: 0;
    }

    a {
        display: inline-block;
        padding: 6px 15px;
        text-decoration: none;
        font-weight: 700;

        &:last-child() {
            @media #{$grid-lg-min} {
                padding-right: 0;
            }
        }

    }
}

.page-error-content {
    @include clearfix;
    padding: 30px 0 10px;
    text-align: center;

    h2 {
        font-size: 17px;
        color: #000;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    li {
        flex-basis: 100%;
        flex-shrink: 0;
        padding: 8px 5px;

        @media #{$grid-md-min}{
            flex-basis: 33.333%;
        }
    }

    a {
        font-size: 17px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}
