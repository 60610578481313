
/*------------------------------------*\
    FOOTER
\*------------------------------------*/

.footer {
    margin-top: 30px;

    @media #{$grid-sm-max} {
        font-size: 14px;
    }
}

.footer-bottom {
    padding-top: 40px;
    background: #f2f2f2;

    @media #{$grid-lg-min}{
        padding-top: 50px;
    }
}

.footer-copyright {
    margin-top: 30px;
    padding: 16px 0;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;

    @media #{$grid-lg-min}{
        margin-top: 20px;
    }
}

.footer-title {
    margin: 30px 0 20px;
    text-transform: uppercase;
    font-weight: 800;

    @media #{$grid-md-min}{
        margin-top: 0;
    }
}

.footer-regulations {
    margin-bottom: 30px;
    line-height: 1.6;

    @media #{$grid-md-min}{
        margin-bottom: 20px;
    }
}

.footer-links {
    padding: 0;
    margin: 0;
    list-style: none;
    min-width: 120px;

    li {
        padding: 5px 0;
    }

    a {
        display: block;
        font-weight: normal;
    }

    @media #{$grid-sm-max} {
        a {
            font-size: 14px;
        }
    }
}


/*------------------------------------*\
    Seals v1
\*------------------------------------*/
.footer-images {
    img {
        display: block;
    }
}

.footer-digicert {
    display: inline-block;
    width: 65px;
    height: auto;
    margin-right: 2px;
    vertical-align: middle;
}

.footer-secure {
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

/*------------------------------------*\
    Seals v2
\*------------------------------------*/
.footer-seals {
    a {

    }
}


/*------------------------------------*\
    Mobile feedback
\*------------------------------------*/

.footer-feedback-btn {
    position: fixed;
    background: url('../images/footer/feedback-lb-v2-c.jpg') repeat scroll 0 0 transparent !important;
    background-size: cover!important;
    cursor: pointer;
    height: 41px;
    width: 180px;
    bottom: 72px;
    right: 0;

    @media #{$grid-md-min}{
        bottom: 48px;
    }

    @media #{$grid-xl-min}{
        bottom: 15px;
    }

    @media #{$grid-lg-max}{
        position: absolute !important;
    }
}

/*------------------------------------*\
    Footer icons
\*------------------------------------*/
.footer-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;

    &.fb_iframe_widget_fluid {
        display: inline-block;
    }
}

.footer-fb {
    a {
        display: block;
        position: relative;
        font-size: 24px;
        overflow: hidden;
        color: #365899;
        height: 24px;
        width: 22px;
        text-align: center;
        border-radius: 7px;
        top: -4px;
        &:hover {
            color: #848484;
        }
    }
}

.footer-trustpilot {
    position: relative;
    margin-bottom: 30px;
}

.footer-trustpilot-bottom {
    display: none;
    margin-top: 10px;

    .page-home & {
        display: block;
    }
}
/*
    Footer image
*/
.footerlogo_box {
    display: block;
    margin-top: 15px;
}
.footerlogo_box img {
    width: 80px;
}
