$search-results-border-color: #e8e8e8;

/*------------------------------------*\
    MODAL
\*------------------------------------*/

.search-error-auto,
.search-auto {
    position: relative;

    &.active {
        .search-results-wrapper,
        .search-error-results-wrapper {
            display: block;
        }
    }
}

.header-search .search-input,
.search-input {
    display: block;
    width: 100%;
    padding: 6px 9px 5px;
    border: 2px solid $primary-color;
    border-radius: 10px;
    line-height: 24px;
    color: $text-color;
    font-size: 16px;
    height: auto;

    @media #{$grid-lg-min} {
        font-size: 12px;
    }

    @include placeholder {
        font-size: 12px;
    }

    // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //   font-size: 12px;
    // }
    // &::-moz-placeholder { /* Firefox 19+ */
    //   font-size: 12px;
    // }
    // &:-ms-input-placeholder { /* IE 10+ */
    //   font-size: 12px;
    // }
    // &:-moz-placeholder { /* Firefox 18- */
    //   font-size: 12px;
    // }
}

.search-btn,
.search-btn-close {
    display: block;
    position: absolute;
    top: 6px;
    right: 4px;
    padding: 5px;
    font-size: 16px;
    color: #333;
    cursor: pointer;

    &:hover {

    }
}

.search-btn-close {
  top: 2px;
  font-size: 24px;
}

.search-auto-all {
    padding: 10px 15px;
    border-top: 1px solid $search-results-border-color;

    a {
        display: block;
        max-width: 350px;
        margin: 10px auto;
        width: 90%;
        padding: 15px 0;
        background: $primary-color;
        border: 1px solid $primary-color;
        color: #fff;
        text-align: center;
        font-weight: 500;

        &:hover {
            background: darken($primary-color, 8%);
        }
    }
}

.search-highlight {
    font-weight: 700;
    background-color: rgba(251, 221, 2, .5);
}

/*------------------------------------*\
    Results
\*------------------------------------*/
.search-error-results-wrapper,
.search-results-wrapper {
    display: none;
    position: absolute;
    width: 90vw;
    right: 0;
    background: #fff;
    border: 1px solid $search-results-border-color;
    box-shadow: 0 3px 4px rgba(0,0,0,.2);
    letter-spacing: .3px;
    z-index: 800;

    @media #{$grid-md-min}{
        left: 6px;
        right: 6px;
        width: auto;
    }

    .loading {
        position: relative;
        height: 80px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #333;
        font-size: 17px;

        &:before {
            @include icon;
            display: inline-block;
            content: $icon-spinner;
            animation: icon-spin 2s linear infinite;
        }
    }
}

.search-error-results,
.search-results {
    @include clearfix;
}

.search-no-results {
    padding: 25px 15px;
    font-size: 14px;
}


/*------------------------------------*\
    Title
\*------------------------------------*/
.search-auto-title {
    margin-bottom: 5px;
    padding: 5px 15px;
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid $search-results-border-color;
}

.search-auto-item {
    padding-bottom: 10px;
    border-bottom: 1px solid $search-results-border-color;

    &:last-child {
        border-bottom: 0;
    }
}

/*------------------------------------*\
    Query
\*------------------------------------*/
.search-auto-query {
    border-bottom: 1px solid $search-results-border-color;

    a {
        display: block;
        @include clearfix;
        padding: 5px 15px;
    }
}


/*------------------------------------*\
    Queries
\*------------------------------------*/
.search-auto-queries {
    .item {
        @include clearfix;
        padding: 5px 15px;
    }
}

/*------------------------------------*\
    Products
\*------------------------------------*/
.search-auto-products {
    .item {
        @include clearfix;
        padding: 10px 15px;

        // &:last-child {
        //     border-bottom: 0;
        // }
    }

    a {
        display: block;
    }

    img {
        display: block;
    }

    .product-image {
        width: 50px;
        float: left;
    }

    .product-content {
        width: calc(100% - 65px);
        float: right;
    }

    .product-ing {
        display: block;
        font-size: 12px;
    }

    .product-desc {
        display: block;
        font-size: 12px;
    }

    .product-name {
        display: block;
        font-weight: 700;
    }

    .product-price {
        display: block;
        font-size: 12px;
    }
}

.search-auto-product {
    @include clearfix;
}

/*------------------------------------*\
    Categories
\*------------------------------------*/
.search-auto-categories {
    .item {
        @include clearfix;
        padding: 5px 15px;
    }
}


/*------------------------------------*\
    Page error search
\*------------------------------------*/
.search-error-results-wrapper {
    width: 100%;
    width: 100vw;
    max-width: 700px;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
    color: $text-color;
    text-align: left;
}

.search-error-auto {
    width: 310px;
    margin: 0 auto 12px;
}

.search-error-input {
    width: 100%;
    padding: 5px 16px 5px 11px;
    border: 1px solid #8e8e8e;
    border-radius: 11px;
    background: #fff;
}

.search-error-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    height: auto;
    width: auto;
    margin: 0;
    padding: 3px 7px 3px 3px;
    vertical-align: initial;
    border-radius: 10px;
    background: #454545;
    color: #fff;
    font-size: 11px;

    .icon {
        position: relative;
        top: 1px;
        font-size: 13px;
        line-height: 11px
    }
}
