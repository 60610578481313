@mixin btn() {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: $btn-border;
    padding: $btn-padding;
    font-size: inherit;
    font-weight: $btn-font-weight;
    letter-spacing: $btn-letter-spacing;
    text-transform: $btn-text-transform;
    line-height: $btn-line-height;
    border-radius: $btn-border-radius;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
        border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    cursor: pointer;

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

@mixin btn-custom($color) {
    color: #fff;
    background-color: $color;
    border-color: $color;

    &:hover {
        background-color: darken($color, 7%);
        border-color: darken($color, 7%);
        color: #fff;
    }
}

@mixin btn-success() {
    color: #fff;
    background-color: $btn-success-color;
    border-color: $btn-success-color;

    &:hover {
        background-color: darken($btn-success-color, 7%);
        border-color: darken($btn-success-color, 7%);
        color: #fff;
    }
}

@mixin btn-default() {
    color: #fff;
    background-color: $btn-default-color;
    border-color: $btn-default-color;

    &:hover {
        background-color: darken($btn-default-color, 7%);
        border-color: darken($btn-default-color, 7%);
        color: #fff;
    }
}

@mixin btn-primary() {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;

    &:hover {
        background-color: darken($primary-color, 7%);
        border-color: darken($primary-color, 7%);
        color: #fff;
    }
}

@mixin btn-secondary() {
    color: #fff;
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
        background-color: darken($secondary-color, 7%);
        border-color: darken($secondary-color, 7%);
        color: #fff;
    }
}

/*------------------------------------*\
    Buttons
\*------------------------------------*/

input:focus,
button:focus {
    outline: 0;
}

a.btn {
    text-decoration: none;
}

.btn-link {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.btn {
    @include btn;

    &.disabled {
        pointer-events: none;
        opacity: 0.65;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-default {
    @include btn-default;
}

.btn-primary {
    @include btn-primary;
}

.btn-success {
    @include btn-success;
}

.btn-grey {
    @include btn;

    background-color: $btn-grey-color;
    border-color: $btn-grey-color;

    &:hover {
        background-color: darken($btn-grey-color, 7%);
        border-color: darken($btn-grey-color, 7%);
    }
}

// .btn-secondary {
//     @include btn-secondary;
// }

.btn-default-reverse {
    color: $btn-default-color;
    background-color: transparent;
    border-color: $btn-default-reverse-border;

    &:hover {
        background-color: $btn-default-color;
        border-color: $btn-default-color;
        color: #fff;
    }
}

.btn-reverse {
    color: #fff;
    background-color: transparent;
    border-color: #fff;

    &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #666;
    }
}

.btn-xs {
    font-size: 9.5px;
}

.btn-md {
    font-size: 14px;
}

.btn-lg {
    padding: $btn-lg-padding;
    font-size: $btn-lg-font-size;
}

/*------------------------------------*\
    Other buttons
\*------------------------------------*/

.btn-add-cart,
.btn-add-cart-ajax,
.btn-out-of-stock {
    @include btn;
    @include btn-success;
    @include btn-custom($btn-pdp-add-to-cart);
    min-width: 100px;

    &.disabled {
        pointer-events: none;
        opacity: 0.65;
    }
}

.SignInLogin,
.SignInRequest,
.ClickToRegister,
.CreateMyAccountButton {
    @include btn;
    @include btn-default;
}

/*------------------------------------*\
    Checkout
\*------------------------------------*/

.checkout-continue {
    @include btn;
    @include btn-custom($checkout-btn-placeorder);
    position: relative;
    padding-left: 14px;
    padding-right: 35px;
    margin-top: 10px;

    &:after {
        @include icon;
        display: inline-block;
        content: $icon-arrow_forward_ios;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
    }

    &.disabled,
    &.error {
        color: #fff;
        background-color: #666;
        border-color: #666;

        &:hover {
            background-color: darken(#666, 5%);
            border-color: darken(#666, 5%);
        }
    }

    &.prescription_continue_btn {
        margin-top: 0;
        float: right;
    }
}

.checkout-place-order {
    @include btn;
    @include btn-custom($checkout-btn-placeorder);
    padding: 16px 24px;
    font-size: 14px;

    &.disabled,
    &.error {
        color: #fff;
        background-color: #666;
        border-color: #666;

        &:hover {
            background-color: darken(#666, 5%);
            border-color: darken(#666, 5%);
        }
    }
}

.checkout-update-cart {
    @include btn;
    @include btn-default;
    position: relative;
    bottom: 1px;
    padding: 3px 10px 3px;
    font-size: 10px;
}

a.link {
    text-decoration: underline;
}
