$cart-item-img-sm: 50px;
$cart-item-img: 75px;

/*------------------------------------*\
    CART ITEMS
\*------------------------------------*/
.cart-items-header {
    @include clearfix;
    display: none;
    padding: 20px 20px;
    background: $primary-color;
    color: #fff;
    font-weight: 500;
    font-size: 17px;

    @media #{$grid-md-min} {
        display: block;
    }
}

.cart-items {
    .item {
        @include clearfix;
        position: relative;
        padding: 40px 0 40px 70px;
        border-bottom: 1px solid #f6f6f6;
    }

    .item-info {
        display: block;
        margin-bottom: 10px;
    }

    .item-img {
        position: absolute;
        top: 40px;
        left: 0;

        img {
            width: $cart-item-img-sm;

            @media #{$grid-md-min} {
                width: $cart-item-img;
            }
        }
    }

    .item-name {
        margin-bottom: 5px;
        color: $primary-color;
        font-size: 15px;
    }

    .item-qty {
        display: block;
        margin-bottom: 10px;

        &:before {
            content: "Qty:";
            display: inline-block;
            padding-right: 5px;
            font-weight: 700;
        }
    }

    .item-subtotal {
        &:before {
            content: "Sub Total:";
            display: inline-block;
            padding-right: 5px;
            font-weight: 700;
        }
    }

    .item-size,
    .item-shipping {
        display: none;
    }

    .item-remove {
        position: absolute;
        right: 0;
        text-align: right;
        z-index: 10;

        @media #{$grid-sm-max} {
            bottom: 40px;
        }

        @media #{$grid-md-min} {
            top: 35px;
        }
    }
}

.cart-summary-inner {
    .item {
        display: flex;
        padding: 12px 0;
    }

    dt {
        width: 50%;
        font-weight: 500;
    }

    dd {
        width: 50%;
        text-align: right;
    }

    .total {
        padding: 30px 0 20px;
        font-size: 15px;
        font-weight: 700;
        border-top: 1px solid #f3f3f3;
    }

    .discount {
        span {
            display: block;
        }
    }

    .coupon {
        dd > span {
            color: $danger;
        }
    }
}

.item-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    &:hover {
        cursor: pointer;

        &:after {
            color: $primary-color;
        }
    }

    input {
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }

    &:after {
        @include icon;
        display: inline-block;
        position: absolute;
        content: $icon-trash;
        font-size: 16px;
        z-index: -1;
    }
}
