$modal-gutter: 50px;

/*------------------------------------*\
    MODAL
\*------------------------------------*/

// .modal {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: rgba(0, 0, 0, 0.5);
//     z-index: 999;
//     display: none;
//
//     &.active {
//         display: block;
//     }
// }

// .modal-content {
//     position: relative;
//     top: 50px;
//     width: 90%;
//     margin: 0 auto;
//     padding: 20px 40px 40px;
//     background: #fff;
//
//     @media #{$grid-md-min} {
//         top: 100px;
//         width: 500px;
//     }
//
//     p {
//         margin-bottom: 20px;
//     }
// }

.modal-create {
    display: none;
}

.modal-open {
    position: fixed;
    padding-right: 17px;
    width: 100%;
    overflow: hidden;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999;
    display: none;

    &.active {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.modal-heading {
    margin-bottom: $modal-gutter;
    font-size: 21px;
    text-align: center;

    &:after {
        display: block;
        content: '';
        height: 1px;
        width: 50px;
        margin: 20px auto;
        background: #666;
    }
}

.modal-title {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 700;
}

.modal-img {
    margin: (-$modal-gutter) (-$modal-gutter) $modal-gutter;
    background: #000;

    img {
        display: block;
        // opacity: 0.8;
    }
}

.modal-inner {
    position: relative;
    width: 100%;
    max-width: 800px;
    padding: $modal-gutter $modal-gutter ($modal-gutter + 10);
    background: #fff;

    @media #{$grid-md-min} {
        top: 50px;
        left: 50%;
        width: 70%;
        transform: translate(-50%,0);
    }

    p {
        margin-bottom: 20px;
    }
}

.modal-close {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 5px;
    height: 30px;
    width: 30px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}

.modal-category {
  .modal-close {
      color: #b2b2b2;

      &:hover {
          color: #fff;
      }
  }
}
