
/*------------------------------------*\
    FAQ
\*------------------------------------*/

.faq-container {
    h2 {
        text-align: center;
        color: #666;
        font-size: 22px;
    }

    .faq-sub-heading {
        margin: 70px 0 30px;
        font-size: 28px;

        @media #{$grid-md-max}{
            font-size: 24px;
        }
    }
}

.faq-acc {
    margin: 20px 0 55px;
    font-size: 16px;

    p {
        margin: 0 0 20px;
        line-height: 1.4em;
    }

    a {
        text-decoration: none;
        color: #000;
        font-size: inherit;

        &:hover {
            font-size: inherit;
        }
    }
}

.faq-acc-item {
    border-top: 1px solid #ccc;

    &:last-child {
        border-bottom: 1px solid #ccc;
    }


    &.active {
        .faq-acc-title:after {
            content: $icon-minus;
        }
    }
}

.faq-acc-title {
    position: relative;
    padding: 15px 20px 15px 0;
    font-size: 17px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    line-height: 1.3em;

    @media #{$grid-md-min}{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &:after {
        @include icon;
        content: $icon-plus;
        position: absolute;
        top: 24px;
        right: 0;
        transform: translateY(-50%);
        font-size: 24px;

        @media #{$grid-md-min}{
            top: 30px;
        }
    }
}

.faq-acc-content {
    display: none;

    @media #{$grid-xl-min}{
        max-width: 80%;
    }

    &.active {
        display: block;
        margin-top: 23px;
        max-width: 100%;
    }
}
