// @import "variables";

@charset "UTF-8";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?v=#{$uniqeid}");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?v=#{$uniqeid}#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?v=#{$uniqeid}")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?v=#{$uniqeid}")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?v=#{$uniqeid}##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  @include icon;
}

.icon-cancel1 {
  &:before {
    content: $icon-cancel1;
  }
}
.icon-privacy {
  &:before {
    content: $icon-privacy;
  }
}
.icon-doctor {
  &:before {
    content: $icon-doctor;
  }
}
.icon-hand-02 {
  &:before {
    content: $icon-hand-02;
  }
}
.icon-hand {
  &:before {
    content: $icon-hand;
  }
}
.icon-user-03 {
  &:before {
    content: $icon-user-03;
  }
}
.icon-user-02 {
  &:before {
    content: $icon-user-02;
  }
}
.icon-rx {
  &:before {
    content: $icon-rx;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-prescription {
  &:before {
    content: $icon-prescription;
  }
}
.icon-note {
  &:before {
    content: $icon-note;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-telephone {
  &:before {
    content: $icon-telephone;
  }
}
.icon-information {
  &:before {
    content: $icon-information;
  }
}
.icon-menu-03 {
  &:before {
    content: $icon-menu-03;
  }
}
.icon-menu-04 {
  &:before {
    content: $icon-menu-04;
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist;
  }
}
.icon-discount {
  &:before {
    content: $icon-discount;
  }
}
.icon-secure-payment {
  &:before {
    content: $icon-secure-payment;
  }
}
.icon-money-back {
  &:before {
    content: $icon-money-back;
  }
}
.icon-search-2 {
  &:before {
    content: $icon-search-2;
  }
}
.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-credit-card2 {
  &:before {
    content: $icon-credit-card2;
  }
}
.icon-globe {
  &:before {
    content: $icon-globe;
  }
}
.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up;
  }
}
.icon-airmail {
  &:before {
    content: $icon-airmail;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-carousel-right {
  &:before {
    content: $icon-carousel-right;
  }
}
.icon-carousel-left {
  &:before {
    content: $icon-carousel-left;
  }
}
.icon-shipping {
  &:before {
    content: $icon-shipping;
  }
}
.icon-transit {
  &:before {
    content: $icon-transit;
  }
}
.icon-countries {
  &:before {
    content: $icon-countries;
  }
}
.icon-categories {
  &:before {
    content: $icon-categories;
  }
}
.icon-menu02 {
  &:before {
    content: $icon-menu02;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-arrow-down-left {
  &:before {
    content: $icon-arrow-down-left;
  }
}
.icon-arrow-down-right {
  &:before {
    content: $icon-arrow-down-right;
  }
}
.icon-arrow-up-left {
  &:before {
    content: $icon-arrow-up-left;
  }
}
.icon-arrow-up-right {
  &:before {
    content: $icon-arrow-up-right;
  }
}
.icon-location-arrow {
  &:before {
    content: $icon-location-arrow;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-right-quote {
  &:before {
    content: $icon-right-quote;
  }
}
.icon-left-quote {
  &:before {
    content: $icon-left-quote;
  }
}
.icon-vpn_key {
  &:before {
    content: $icon-vpn_key;
  }
}
.icon-account_circle {
  &:before {
    content: $icon-account_circle;
  }
}
.icon-arrow_back_ios {
  &:before {
    content: $icon-arrow_back_ios;
  }
}
.icon-arrow_forward_ios {
  &:before {
    content: $icon-arrow_forward_ios;
  }
}
.icon-arrow_right_alt {
  &:before {
    content: $icon-arrow_right_alt;
  }
}
.icon-compare_arrows {
  &:before {
    content: $icon-compare_arrows;
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left;
  }
}
.icon-arrow_drop_up {
  &:before {
    content: $icon-arrow_drop_up;
  }
}
.icon-arrow_drop_down_circle {
  &:before {
    content: $icon-arrow_drop_down_circle;
  }
}
.icon-arrow_drop_down {
  &:before {
    content: $icon-arrow_drop_down;
  }
}
.icon-keyboard_arrow_up {
  &:before {
    content: $icon-keyboard_arrow_up;
  }
}
.icon-keyboard_arrow_right {
  &:before {
    content: $icon-keyboard_arrow_right;
  }
}
.icon-keyboard_arrow_left {
  &:before {
    content: $icon-keyboard_arrow_left;
  }
}
.icon-keyboard_arrow_down {
  &:before {
    content: $icon-keyboard_arrow_down;
  }
}
.icon-play_arrow {
  &:before {
    content: $icon-play_arrow;
  }
}
.icon-arrow_back {
  &:before {
    content: $icon-arrow_back;
  }
}
.icon-keyboard_backspace {
  &:before {
    content: $icon-keyboard_backspace;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-local_shipping {
  &:before {
    content: $icon-local_shipping;
  }
}
.icon-credit_card {
  &:before {
    content: $icon-credit_card;
  }
}
.icon-local_print_shop {
  &:before {
    content: $icon-local_print_shop;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-clear {
  &:before {
    content: $icon-clear;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-info_outline {
  &:before {
    content: $icon-info_outline;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-check_circle {
  &:before {
    content: $icon-check_circle;
  }
}
.icon-fax {
  &:before {
    content: $icon-fax;
  }
}
.icon-credit-card-alt {
  &:before {
    content: $icon-credit-card-alt;
  }
}
.icon-cc-mastercard {
  &:before {
    content: $icon-cc-mastercard;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-address-card {
  &:before {
    content: $icon-address-card;
  }
}
.icon-vcard {
  &:before {
    content: $icon-vcard;
  }
}
.icon-check-square-o {
  &:before {
    content: $icon-check-square-o;
  }
}
.icon-facebook-official {
  &:before {
    content: $icon-facebook-official;
  }
}
.icon-credit-card1 {
  &:before {
    content: $icon-credit-card1;
  }
}
.icon-truck {
  &:before {
    content: $icon-truck;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner;
  }
}
