
/*------------------------------------*\
    HOME
\*------------------------------------*/
.page-home {
    .images_home {
        padding-bottom: 10px;
        padding-right: 20px;
        margin-top: -15px;

        img {
            width: 100%;
        }
    }

    .footer-trustpilot {
        display: none;
    }
}


/*------------------------------------*\
    HOME
\*------------------------------------*/
.home-section {
    margin-top: 40px;
    margin-bottom: 40px;

    @media #{$grid-lg-min}{
        margin-top: 60px;
        margin-bottom: 60px;
    }

    p {
        margin-top: 0;
    }
}


/*------------------------------------*\
    SLIDER
\*------------------------------------*/
.home-slider {
    position: relative;
    img {
        width: 100%;
    }

    &:not(.slick-slider) {
        .home-slider-item {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }
}

.home-slider-item {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 220px;

    @media #{$grid-md-min}{
        min-height: 350px;
    }
}

.home-slider-title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    font-family: $font-family-gotham;
    font-size: 24px;
    font-weight: 100;
    font-style: italic;
    color: #fff;
    line-height: .92;
    text-align: center;

    @media #{$grid-md-min} {
        font-size: 53px;
    }

    @media #{$grid-lg-min} {
        font-size: 65px;
        line-height: .9;
    }
}

.home-slider-left {
    text-align: left;

    @media #{$grid-md-min}{
        max-width: 580px;
    }
}

/*------------------------------------*\
    TOP
\*------------------------------------*/
.home-top {
    @media #{$grid-sm-max}{
        position: relative;
        padding-top: 20px;
    }


    @media only screen and #{$grid-md-max} and #{$grid-md-min}  {
        display: flex;
        flex-direction: column-reverse;
    }

    @media only screen and (max-width: 991px) and #{$grid-md-min}  {
        position: relative;
        top: 40px;
    }
}

.home-title {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    font-weight: 400;

    @media only screen and #{$grid-md-max} and #{$grid-md-min}  {
        margin-bottom: 10px;
    }
}

.home-trustpilot-top {
    @media #{$grid-sm-max}{
        position: absolute;
        top: -35px;
        width: 134%;
        left: 50%;
        transform: translateX(-50%) scale(.75);
    }

    @media #{$grid-lg-min}{
        display: none;
    }
}


/*------------------------------------*\
    ICONS
\*------------------------------------*/
.home-icons {
    display: block;
    margin-top: 24px;
    text-align: center;

    @media #{$grid-lg-min}{
        margin-top: 18px;
    }
}

.home-icon {
    position: relative;
    height: 72px;
    width: 72px;
    margin: 0 auto 11px;
    background: $primary-color;
    border-radius: 50%;
    font-size: 70px;
    color: #fff;

    @media #{$grid-md-min}{
        height: 85px;
        width: 85px;
        font-size: 85px;
    }

    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .icon-globe {
        margin-top: -1px;
    }
}

.home-icon-title {
    max-width: 190px;
    margin: 0 auto;
    font-size: 14px;
}


/*------------------------------------*\
    TEXT
\*------------------------------------*/
.home-text {
    text-align: center;
    font-size: 12.5px;
    line-height: 17px;

    p:last-child {
        margin-bottom: 0;
    }
}

.home-text-title {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 700;
}

.home-text-content {
    width: 90%;
    max-width: 580px;
    margin: 0 auto;

    @media #{$grid-lg-min}{
        width: 70%;
    }
}


/*------------------------------------*\
    BLOCK
\*------------------------------------*/
.home-block {
    position: relative;
    min-height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #fff;

        img {
            transform: scale(1.05);
        }

        .btn  {
            background-color: #fff;
            border-color: #fff;
            color: #666;
        }
    }

    @media #{$grid-md-min} {
        height: 210px;
    }

    h3 {
        margin: 0 0 4px;
        font-size: 25px;
    }
}

.home-block-top {
    @media #{$grid-sm-max} {
        margin-bottom: 40px;
    }
}

.home-block-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;

    img {
        transition: all .25s ease-in-out;
    }
}

.home-category-sm,
.home-category-lg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.home-category-sm {
    @media #{$grid-lg-min}{
        display: none;
    }
}

.home-category-lg {
    @media #{$grid-md-max}{
        display: none;
    }
}

.modal-content-category {
    text-align: center;
    font-size: 17px;
    line-height: 1.7;

    .nav {
        columns: 3;
        column-fill: balance;

        // @media #{$grid-xl-min}{
        //     columns: 5;
        // }
    }

    .nav-item {
        display: block;
        // padding: 4px 0;
        margin-bottom: 5px;
    }

    a {
        display: block;

        &:hover {
            color: $link-color;
        }

        &.active {
            color: $link-color;
        }
    }
}


/*------------------------------------*\
    HOME FEATURE BLOCK
\*------------------------------------*/
.hfeature {
    min-height: 250px;
    background: #f2f2f2;

    @media #{$grid-md-min}{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .btn {
        display: inline-block;
        margin-top: 14px;
    }
}

.hfeature-reverse {
    @media #{$grid-md-min}{
        flex-direction: row-reverse;
    }
}

.hfeature-title {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 700;
}

.hfeature-content,
.hfeature-img {
    @media #{$grid-md-min}{
        flex-basis: 50%;
    }
}

.hfeature-content {
    padding: 30px 20px;
    font-size: 12.5px;
    line-height: 17px;

    @media #{$grid-md-min}{
        padding: 30px 28px;
    }

    @media #{$grid-lg-min}{
        padding: 20px 9%;
    }
}

.hfeature-img {
    position: relative;
    overflow: hidden;

    @media #{$grid-md-min} {
        height: 250px;
    }

    img {
        display: block;
        width: 100%;

        @media #{$grid-md-min} {
            position: absolute;
            width: 555px;
            max-width: 555px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}


/*------------------------------------*\
    HOME FEATURE BLOCK
\*------------------------------------*/
.home-trustpilot {
    position: relative;
    bottom: 18px;
}
