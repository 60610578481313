/*------------------------------------*\
    CONTACT
\*------------------------------------*/
.page-contact {
    .page-banner {
        @include container;
    }
}

.contact-phones,
.contact-fax {
    .table {
        width: 100%;
    }
}

.contact-section {
    @include clearfix;
    margin: 30px 0 60px;

    @media #{$grid-md-min} {
        margin: 60px 0 80px;
    }
}

.contact-title {
    margin-top: 40px;
}

.operating-specific {
    @include clearfix;
}

.operating-info {
    margin-bottom: 20px;
}

.select-operating-wrapper {
    @include clearfix;
    margin: 20px 0;
}

.select-operating-label {
    display: inline-block;
    margin: 10px 10px 10px 0;
}

.select-operating {
    width: 100%;

    @media #{$grid-md-min} {
        display: inline-block;
        width: auto;
    }

    select {
        width: auto;
        min-width: 150px;
    }
}

.operating-country {
    font-weight: 700;
}

.opening-hours-wrapper {
    margin: 30px 0;
}

.opening-hours {
    position: relative;
    margin: 15px 0;
    padding: 0;
    list-style-type: none;

    li {
        margin-bottom: 1em;
        line-height: 1.5;

        &:nth-last-child(2),
        &:last-child {
            position: absolute;
            left: 50%;
            top: 0;

            @media #{$grid-md-min} {
                left: 30%;
            }
        }

        &:last-child {
            top: 50px;
        }
    }

    .day {
        display: block;
    }

    .start-time {
        display: inline;
    }

    .time {
        display: inline;
        text-transform: lowercase;

        &:last-child:before {
            display: inline;
            content: " - ";
        }
    }
}

/*------------------------------------*\
    FORM TOP
\*------------------------------------*/

.contact-form {
    @media #{$grid-md-min} {
        max-width: 600px;
    }

    textarea {
        height: 200px;
    }
}

/*------------------------------------*\
    FORM BOTTOM
\*------------------------------------*/

.btn-contact-submit {
    @include btn;
    @include btn-default;

    &.enabled {
        @include btn-success;
    }
}

.contact-form-bottom {
    margin: 30px 0 40px;
}
