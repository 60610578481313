
/*------------------------------------*\
    Sidebar
\*------------------------------------*/

.sidebar-container {

}

.sidebar-title {
    font-size: 22px;
    padding-bottom: 6px;
    margin-bottom: 25px;

    background-image: linear-gradient(to right, #c3c3c3 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;

    .skin14 & {
        padding: 10px;
        background: $primary-color;
        color: #fff;
        font-size: 22px;
        font-weight: 700;
    }
}


.sidebar-category {
    padding-bottom: 21px;

    .level2 {
        padding-left: 20px;
        padding-bottom: 10px;

        > a {
            font-weight: 400;
        }
    }

    > a {
        font-weight: 700;
    }
}

.sidebar-category-link {
    display: block;
    padding-bottom: 5px;
    color: #333;
}

.sidebar-products {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-product-item {
    display: block;
    padding: 4px 0;
    font-weight: normal;

    .skin14 & {
        position: relative;
        padding-left: 10px;

        &:before {
            @include icon;
            display: inline-block;
            position: absolute;
            top: 48%;
            left: -6px;
            transform: translate(0, -50%);
            content: $icon-arrow_right;
            font-size: 16px;
        }
    }
}

/*------------------------------------*\
    PDP
\*------------------------------------*/

.pdp-sidebar {
    padding-left: 32px;

    .sidebar-list {
        padding-left: 0;
    }
}

.pdp-sidebar-title {
    padding-top: 3px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    background-image: linear-gradient(to right, #c3c3c3 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
    font-weight: 700;
    color: #000;
}

.pdp-sidebar-secure {
    margin-bottom: 20px;
}
