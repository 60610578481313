/*------------------------------------*\
    Mixins
\*------------------------------------*/

@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

/*------------------------------------*\
    Read more
\*------------------------------------*/
@mixin readmore() {
    position: relative;
    height: 200px;
    overflow: hidden;

    &::after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 120px;
        background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
}

.readmore-active {
    @include readmore;
}

.readmore-btn {
    display: block;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;

    &:after {
        @include icon;
        display: inline-block;
        position: relative;
        top: 3px;
        left: 7px;
        font-size: 18px;
        content: $icon-arrow_right_alt;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}
