/*------------------------------------*\
    COVID ALERT MESSAGE
\*------------------------------------*/
.covid-content {
    padding: 20px 30px;
    background: #666;
    color: #fff;
    text-align: center;
    line-height: 1.5;

    p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 15px;
    }

    .skin5 & {
        background: #f1f3f5;
        color: #141a1f;
    }

    .skin5:not(.page-home) & {
        margin-bottom: 2.5em;
    }
}

.covid-content-section {
    margin: 17px 0;

    h3 {
        font-size: 17px;
        margin: 8px 0;

        &:after {
            display: block;
            content: "";
            width: 25px;
            height: 1px;
            background: #fff;
            margin: 4px auto 4px;
        }
    }
}

.covid-title {
    display: block;
    font-size: 17px;
    text-transform: initial;
}

.covid-btn-modal {
    display: inline-block;
    display: none;
    margin: 0 auto;
    width: 150px;
    padding: 12px;
    background: #fff;
    color: #333;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
        background: #e6e6e6;
        color: #333;
    }
}

.covid-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;

    &.active {
        display: block;
    }
}

.covid-modal-content {
    position: relative;
    top: 50px;
    width: 90%;
    margin: 0 auto;
    padding: 20px 40px 40px;
    background: #fff;

    @media #{$grid-md-min} {
        top: 100px;
        width: 500px;
    }

    p {
        margin-bottom: 20px;
    }
}

.covid-modal-close {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 5px;
    height: 30px;
    width: 30px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
}

.covid-form-group {
    margin: 0 0 16px;

    label {
        display: none;
    }
}

.covid-input {
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    border-radius: 0px;
    height: 48px;
    border: 1px solid #919593;
}

.covid-submit {
    height: 48px;
    width: 100%;
    background: #333;
    color: #fff;
    border: 1px solid #333;
    cursor: pointer;

    &:hover {
        background: #4a4a4a;
    }
}

.covid-success p {
    margin-bottom: 0;
}
