
/*------------------------------------*\
    HEADER
\*------------------------------------*/
.breadcrumbs {
    margin-top: 10px;
    padding: 5px 0;
    font-size: 12px;
    color: #000;

    a {
        color: #b2b2b2;

        &:hover {
            color: $link-color;
        }

        &:after {
            display: inline-block;
            content: '>';
            margin: 0 5px;
        }
    }
}

.breadcrumb_text_seprator {
    display: none !important;
}
