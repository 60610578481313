
$reviews-bg: #ebebeb;
$reviews-border: #ccc;

/*------------------------------------*\
    REVIEWS
\*------------------------------------*/
.reviews {
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 1.4;

    @media #{$grid-md-min}{
        margin-bottom: 50px;
    }
}

.reviews-container {
    @media #{$grid-md-max}{
        padding: 0 20px;
    }
}

.menu-reviews {
    position: relative;

    &:before {
        @include icon;
        content: '\f146';
        position: absolute;
        height: 22px;
        width: 19px;
        top: 5px;
        left: 14px;
        font-size: 18px;
        color: #000;
        background: #e6e6e6;
        text-align: center;
    }
}


/*------------------------------------*\
    Top
\*------------------------------------*/
.reviews-top {
    margin-top: 35px;
    margin-bottom: 32px;
}


/*------------------------------------*\
    List
\*------------------------------------*/
.review-list {
    margin: 20px 0 35px;
    border-top: 1px solid $reviews-border;
}

.review-item {
    position: relative;
    margin: 20px -20px;
    padding: 20px 60px 20px 20px;
    background: $reviews-bg;

    @media #{$grid-md-min}{
        margin: 20px 0;
    }

    &-name {
        font-weight: 700;
    }

    &-tagline {
        font-size: 12px;
    }

    &-desc {
        &:before {
            display: inline-block;
            content: '"';
        }

        &:after {
            display: inline-block;
            content: '"';
        }
    }


}

.review-quote {
    position: absolute;
    top: 20px;
    right: 18px;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
        @include icon;
        content: $icon-right-quote;
        display: inline-block;
        padding-left: 2px;
        padding-right: 2px;
        height: 11px;
        font-size: 11px;
    }

    &:before {
        // transform: rotate(180deg);
        content: $icon-left-quote;
    }
}


/*------------------------------------*\
    Bottom
\*------------------------------------*/
.reviews-bottom {
    margin-top: 35px;
    border-top: 1px solid $reviews-border;

    @media #{$grid-md-min}{
        padding: 0 20px;
    }

    &:first-child {
        margin-top: 0;
    }

    h2 {
        font-size: 17px;
        font-weight: 700;
        color: #000;
    }
}

.reviews-bottom-image {
    img {
        display: block;
        width: 100%;
    }
}
