

.mpc_content_left {
    float: left;
    width: 60%;
}

.mpc_content_right {
    float: right;
    width: 40%;
    max-width: 280px;
}

.addressCellText {
    padding-top: 10px;
    padding-bottom: 3px;
}

.addressCellField {
}

.addressCellButton {
    padding-top: 10px;
    float: right;
    margin-left: 40px;
}

.required_color {
    color: $danger;
}

.mpcTextbox {
    width: 280px;
}

.mpcDropDown {
    width: 285px;
}

.mpcCheckbox {
    width: 280px;
}


.clear {
    clear: both;
}

.underline {
    width: 100%;
    border-bottom: 1px solid darkgray;
}


.checkout_field {
    position: relative;
    min-height: 60px;
    // width: 95%;
    display: block;
    padding-top: 25px;
    clear: both;
}

.checkout_lbl, .checkout_ddl_lbl, .checkout_dob_lbl, .checkout_expiry_lbl {
    position: absolute;
    display: block;
    top: 34px;
    left: 10px;
    // padding-left: 10px;
    // font-size: 14px;
    // width: 90%;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    // line-height: 14px;
    // margin-bottom: 8px;
    color: #AAA;
    z-index: 2;
}

.checkout_lbl_digitalsign {
    position: absolute;
    display: block;
    left: 10px;
    // padding-left: 10px;
    // font-size: 14px;
    // width: 90%;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    // line-height: 14px;
    // margin-bottom: 8px;
    color: #AAA;
    z-index: 2;
}

.floated {
    position: absolute;
    top: 5px;
    font-size: 12px;
    left: 0px;
    padding-left: 0px;
    color: black;
    /*color: #4959a7;*/
}

.checkout_lbl_digitalsign.floated {
    top: inherit;
    padding-left: 10px;
}

.no_border {
    border: none !important;
}

.checkout_txtbx, .checkout_txtbx_alt, .checkout_txtbx_expiry, .checkout_txtbx_dob,.checkout_txtbx_dob_ba, .checkout_txtbx_dob_ec  {
    // margin-top: 25px;
    // left: 0px;
    // font-size: 14px;
    // padding-left: 10px;
    // width: 100%;
    // height: 25px;
    // border: solid 1px Grey;
    /*box-shadow: 0 4px 6px -5px hsl(0, 0%, 40%), inset 0px 4px 6px -5px gray;*/
    /*-moz-border-radius: 5px;*/
    /*-webkit-border-radius: 5px;*/
    /*border-radius: 5px;*/ /* future proofing */
    /*-khtml-border-radius: 5px;*/ /* for old Konqueror browsers */
}

/*.checkout_txtbx_expiry {
            border:none;
        }*/

.checkout_txtbx_giftcard{
    width:50%;
}

.error_message {
    /*position: absolute;*/
    color: $danger;
    text-align: left;
    padding-left: 10px;
    min-height: 25px;
    margin-top: 5px;
    /*top: 60px;
            left: 0px;*/
    overflow: hidden;
    display: none;
}

.helper_message {
    margin-top: 5px;
    padding: 3px;
    width: 100%;
    color: #000080;
    border: 1px solid #000;
    border-radius: 3px;
    display: none;
}

.checkout_field_alt > span {
    display: block;
}

.dotted_line {
    clear: both;
    border-bottom: 1px dashed grey;
    margin: 10px 0px 10px 0px;
}


// .checkout_field_success {
//     background-image: url('/PH Images/mpc/success_tick.png');
//     background-position: right 20px center;
//     background-repeat: no-repeat;
//     border-color: $success;
// }

// .checkout_field_failure {
//     background-image: url('/PH Images/mpc/failure_cross.png');
//     background-position: right 20px center;
//     background-repeat: no-repeat;
//     border-color: $danger;
// }

.no_bg {
    background: none !important;
}

// span.checkout_field_success, span.checkout_field_failure {
//     background-position: right 25px center;
// }


.checkout_ddl_dob_yy,
.checkout_ddl_dob_mm,
.checkout_ddl_dob_dd,
.checkout_ddl_expiry_mm,
.checkout_ddl_expiry_yy,
.checkout_ddl_dob {
    float: left;
    width: auto;
    margin: 1px 5px 1px 1px;
    padding-left: 8px;
    padding-right: 20px;

    @media (min-width: 768px) {
      padding-left: 12px;
      padding-right: 40px;
    }
}


/*//////////////////////*/
.ui-datepicker-trigger {
    margin-top: 5px;
    padding-top: 5px;
    height: 25px;
}

#ui-datepicker-div {
    font-size: 10px;
}

.ui-datepicker {
    position: relative;
    background: #333;
    border: 1px solid #555;
    color: #EEE;
    z-index: 4 !important;
    margin-left: 200px;
    /*margin-top:200px;*/
}


.surcharge_notice, .paymentTerms_notice {
    border: solid;
    border-color: black;
    padding: 10px;
    margin-right: 12%;
    margin-top: 10px;
}

.echeckTerms_notice{
    border: 1px solid 919593;
    padding: .375rem .75rem;
}

.checkout_notice {
    border: 1px solid #F00;
    padding: 10px;
    background-color: #FAEBE7;
    color: #F00;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
}

#loading {
    width: 50px;
    height: 57px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -28px 0 0 -25px;
}

.checkout_password {
    display: none;
}


.checkout_button {
    clear: both;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    text-align: right;
}

#create_account {
    display: none;
}

.place_order {
    /*display: none;*/
    text-align: center;
}

// .inactive_element {
//     color: darkgray;
// }


#staticShippingAddressblock {
    display: none;
}



.summary_field_edit input[type=image] {
    height: 14px;
    float: right;
}



.summary_field_title {
    float: left;
    background-image: url('/PH Images/mpc/summary_title_icon.png');
    background-position: left center;
    padding-left: 15px;
    background-repeat: no-repeat;
    /*width: 80%;*/
}

.order_field {
    width: 100%;
    padding-bottom: 10px;
    color: #808080;
    font-weight: bold;
    clear: both;
}

.summary_field_edit {
    float: right;
    /*width: 20%;*/
    text-align: right;
    padding-right: 10px;
}


.minicart_order_total {
  padding: 15px;
  border-radius: 0px 0px 5px 5px;
  background-color: #F3F3F3;
}

.left_clear {
    clear: left;
}

#left_createaccount_block {
}

#right_createaccount_block {
    display: none;
}

#createaccount_block {
    // max-width: 600px;
    display: none;
}

.display_none {
    display: none;
}

.display_block {
    display: block;
}

.forgot_password {
    margin-top: 15px;
    text-align: right;
}

.advancedSearchTextbox {
    width: 526px;
    margin-right: -4px;
}

.unshippable_items {
    clear: both;
}

.unshippable_items_left {
    float: left;
    width: 40%;
    text-align: center;
    color: $danger;
    font-size: 13px;
    padding-left: 10px;
}

.unshippable_items_right {
    float: left;
    width: 40%;
    text-align: center;
    padding-right: 10px;
}

// input.btnUpdateProductQuantity:focus, /*input[type=text]:focus,*/ select.checkout_ddl:focus, input.checkout_txtbx:focus, textarea:focus {
//     box-shadow: 0 0 5px rgba(128, 128, 128, 1);
//     border: 1px solid rgba(81, 203, 238, 1);
// }


.minicart_head {
    background-color: #F3F3F3;
    // border: 1px solid #DBDBDB;
    font-size: 14px;
    font-weight: bold;
    // color: #2F2E2E;
    background: #F3F3F3 none repeat scroll 0% 0%;
    line-height: 24px;
    padding: 7px 15px;
}

.minicart_body {
    padding: 15px;
    border: 1px solid #f3f3f3;
    /*line-height: 20px;*/
    // clear: both;
}

.minicart_img {
    position: absolute;
}

.minicart-product-name {
  position: relative;
  font-weight: 700;
  word-break: break-all;
}

.minicart-product-name,
.minicart_title_price,
.minicart_total_row,
.minicart_update_row {
  padding: 0 0 4px 65px;
}

.minicart_row_left {
    float: left;
    clear: left;
}

.minicart_row_right {
    float: right;
    text-align: right;
    clear: right;
    padding-right: 20px;
}

/*#scrollingCart,*/
// #scrollingCartBlock {
//     margin-top: 20px;
//     margin-right: 20px;
//     // width: 260px;
//     float: right;
//     position: fixed;
// }

.minicart_img img {
    width: 50px;
}

img {
    max-width: 100%;
    height: auto;
    border: medium none;
}

.mpc_errorBox {
    color: #F00 !important;
    background: #FAEBE7 none repeat scroll 0% 0% !important;
    border: 1px solid #F00 !important;
    padding: 5px 10px !important;
    margin: 5px 0px !important;
}

// .payment_method_body {
//     padding-left: 20px;
//     /*overflow: auto;*/
// }

.orderdetails_box {
    border: 1px solid black;
    padding: 10px;
}


.orderdetails_body {
    padding: 10px 5px 5px 10px;
    /*line-height: 20px;*/
    clear: both;
    width: 280px;
    float: right;
}


.ordersummary_row {
    clear: both;
    text-align: right;
}

.ordersummary_row_left {
    float: left;
    clear: left;
    width: 130px;
}

.ordersummary_row_right {
    float: right;
    text-align: right;
    clear: right;
    width: 130px;
}

#leftErrorBlock {
    color: #F00 !important;
    background: #FAEBE7 none repeat scroll 0% 0% !important;
    border: 1px solid #F00 !important;
    padding: 5px 10px !important;
    margin: 5px 0px !important;
    text-align: center;
}


.addressOverlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
}


.addressDetails {
    clear: both;
    text-align: left;
}

.addressLink {
    padding: 10px 0;
    clear: both;
    float: right;
    text-align: right;
}

.addressBookbox {
    margin: 0 auto;
    padding: 30px;
    max-width: 460px;
    min-width: 300px;
    width: 90%;
    clear: both;
    background-color: white;
    border: 2px solid #4959a7;
}

    .addressBookbox h2 {
        font-size: 16px;
        color: #4957a5 !important;
        font-size: 18px;
        background-image: none !important;
        padding-top: 20px;
    }

.address_underline {
    border-bottom: 1px solid darkgray;
}

.editAddressbookLink {
    margin-top: 20px;
    margin-bottom: 20px;
    // padding-left: 40px;
}

.checkout_guestaccount {
    padding-left: 0px !important;
}

#dynamicOver17 {
    clear: both;
    padding-bottom: 10px;
}

#completeOrderBlock {
    display: none;
}

.checkout_field_paymentTerms_chkbx label {
    display: inline-block;
    max-width: 80%;
    vertical-align: top;
    padding-left: 5px;
}



@media screen and (max-width: 640px) {
    .mpc_content_left {
        clear: both;
        width: 100%;
        // margin: 10px;
    }

    .checkout_txtbx_giftcard{
        width: 90%;
    }


}





.background_color {
    background-color: red;
    width: 500px;
    height: 500px;
    margin: 0 auto;
}

.giftcard_list{
    list-style: none;
    padding: 0px;
}

ul.giftcard_list li{
    line-height: 30px;
}

@media (max-width: 767px){
    #ibGiftCardNext{
        display: none;
    }
}

.bitpay-wallet{
    height: 75px;
}



#subscription_box label, #terms_box label {
    display: block;
    clear: both;
    margin-bottom: 18px;
    overflow: auto;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    min-height: 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.termsBlock {
  padding-left: 35px;
}

#subscription_box label input, #terms_box label input {
  position: absolute;
  opacity: 0;
}

span.terms_text {
    display: block;
    float: right;
    width: calc(100% - 0px);
    line-height: 18px;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #d9f5d9;
  border: solid 1px $success;
}


#subscription_box label:hover input ~ .checkmark,
#terms_box label:hover input ~ .checkmark
 {
  background-color: #ccc;
}


#subscription_box label input:checked ~ .checkmark,
#terms_box label input:checked ~ .checkmark {
  background-color: $success;
}

#subscription_box label input:disabled ~ .checkmark,
#terms_box label input:disabled ~ .checkmark {
  border-color: #d3d3d3;
  background-color: #d3d3d3;
  cursor: default;
}

#subscription_box label.disabled_input ,
#terms_box label.disabled_input{
      cursor: default;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


#subscription_box label input:checked ~ .checkmark:after,
#terms_box label input:checked ~ .checkmark:after {
  display: block;
}


#subscription_box label .checkmark:after,
#terms_box label .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#subscription_box label.disabled_input,
#terms_box label.disabled_input {
    cursor: default;
}

.signature-pad--footer{
    min-height: 20px;
}

#billSameMessageBlock {
    margin-top: 20px;
}