/*------------------------------------*\
    MODAL
\*------------------------------------*/
.feedback-modal {
    .modal-inner {
        max-width: 500px;
    }
}

.feedback-rating {
    display: inline-block;

    span {
        position: relative;
        float: right;

        input {
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;
        }

        label {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 2px;
            margin-bottom: 0;
            text-align: center;
            font-size: 30px;
            line-height: 30px;
            color: #ccc;
            cursor: pointer;
        }

        &.checked {
            label {
                color: $primary-color;
            }

            ~ {
                span {
                    label {
                        color: $primary-color;
                    }
                }
            }
        }

        &:hover {
            ~ {
                span {
                    label {
                        color: darken($primary-color, 10%);
                    }
                }
            }

            label {
                color: darken($primary-color, 10%) !important;
            }
        }
    }
}

.feedback-submit {
    display: block;
}
