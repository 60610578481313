/*------------------------------------*\
    Skin Old layout
\*------------------------------------*/
.content_container {
    @include clearfix;
}

.content_left {
    padding-top: 15px;

    @media #{$grid-lg-min} {
        width: 70%;
        float: left;
    }
}

.content_right {
    padding-bottom: 10px;

    @media #{$grid-lg-min} {
        width: 30%;
        float: left;
    }
}

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.grecaptcha-badge {
    bottom: 65px !important;
}
