/*------------------------------------*\
    Cookies
\*------------------------------------*/
.cookie-content-list {
    margin: 20px 0;
}

.cookie-content-item {
    margin-bottom: 24px;

    p {
        margin-top: 0;
    }
}

/*------------------------------------*\
    Cookies
\*------------------------------------*/
.dialogue_text {
    margin: 0;

    @media #{$grid-lg-min} {
        margin-right: 20px;
    }

    a {
        text-decoration: underline;
    }
}

#cookiesDialogue {
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 25px 20px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.75);
    text-align: center;
    color: #fff;

    .wrapper {
        background-color: transparent;

        @media #{$grid-lg-min} {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }
}

.cookie_buttons {
    @media #{$grid-md-max} {
        margin-top: 20px;
    }
}

#btnAccept,
#btnClose {
    @include btn;
    display: inline-block;
    margin: 0 7px;

    .skin5 & {
        padding: 12px 20px;
    }
}

#btnAccept {
    @include btn-custom($btn-cookies-accept);
}

#btnClose {
    @include btn-custom($btn-cookies-close);
}
