/*------------------------------------*\
    Category root
\*------------------------------------*/

.page-all-categories {
    margin: 20px 0 40px;
    @media #{$grid-md-min} {
        margin: 30px 0 60px;
    }
}

.category-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 15px;
    @media #{$grid-md-min} {
        columns: 2 auto;
        column-gap: 40px;
    }
    @media #{$grid-lg-min} {
        columns: 3 auto;
        column-gap: 75px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 8px 0 6px;
        display: block;
    }

    a {
        line-height: 1.1;
        text-decoration: none;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
            color: $primary-color;
        }
    }

    > li {
        break-inside: avoid;
        @media #{$grid-md-min} {
            margin-bottom: 20px;
        }
    }

    .category-item {
        display: block;
        margin-bottom: 6px;
        font-weight: 700;
    }
}
