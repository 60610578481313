@mixin input-form() {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    padding: $input-padding;
    font-size: 1rem;
    line-height: $input-line-height;
    // color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #919593;
    border-radius: $input-border-radius;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none !important;

    &:focus {
        border-color: #141a1f;
        outline: 0;
        // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        // box-shadow: 0 0 5px rgba(128, 128, 128, 1);
    }
}

@mixin select-form() {
    display: inline-block;
    width: 100%;
    padding: $select-padding;
    line-height: $input-line-height;
    vertical-align: middle;
    background: transparent;
    background-image: url("/src/images/down-arrow.png");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 6px);
    background-position-y: 50%;
    background-size: 12px;
    border: 1px solid #919593;
    border-radius: $input-border-radius;
    font-size: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus,
    &:focus-visible {
        border: 1px solid #141a1f;
        border-radius: $input-border-radius;
        outline: 0;
    }
}

/*------------------------------------*\
    FORMS
\*------------------------------------*/
.form-group {
    margin-bottom: 1em;

    label,
    .label {
        display: inline-block;
        margin-bottom: 0.5em;
        font-weight: 700;
    }

    &.invalid {
        .form-control {
            border-color: $danger;
        }

        .input-error {
            display: block;
        }
    }

    &.valid {
        .form-control {
            border-color: $success;
        }
    }
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

    .custom-file,
    .custom-select,
    .form-control {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
}

.input-group-append,
.input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.form-control {
    @include input-form;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-buttons {
    margin-top: 0.8rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

label,
.label {
    &.required:after {
        content: "*";
        display: inline-block;
        color: $danger;
    }
}

.form-info {
    position: relative;
    margin: 1rem 0 0;
    padding: 0.75rem 1.25rem;
    border-radius: 0.25rem;
    background-color: #e2eefb;
    border: 1px solid #dbebfd;
    color: #4959a7;

    &.invalid {
        display: none;
    }
}

// Reset styling for all inputs
textarea,
input[type="text"],
.txtinput {
    @include input-form;
}

select {
    @include select-form;
}

// CSS from Checkout
.checkout_txtbx {
    @include input-form;
}

.checkout_txtbx,
.checkout_ddl {
    &.checkout_field_failure {
        border-color: $danger;
    }

    &.checkout_field_success {
        border-color: $success;
    }
}

.checkout-input-status {
    display: none;
}

.checkout_field_success,
.checkout_field_failure {
    ~ .checkout-input-status {
        display: block;
        position: absolute;
        right: 8px;
        top: 36px;
        color: $success;
        font-size: 19px;

        @media #{$grid-md-min} {
            right: 15px;
        }

        &:before {
            @include icon;
            display: inline-block;
            content: $icon-check;
        }
    }
}

select {
    &.checkout_field_success,
    &.checkout_field_failure {
        background-image: none;
    }
}

.checkout_field_failure {
    ~ .checkout-input-status {
        color: $danger;

        &:before {
            content: $icon-close;
        }
    }
}


/*------------------------------------*\
    Checkbox
\*------------------------------------*/
.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.8em;
    margin-bottom: 7px;

    .form-check-input {
        float: left;
        margin-left: -1.8em;
        margin-right: 10px;
    }
}

.form-check-input {
    width: 15px;
    height: 15px;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    &:checked {
        background-color: $checkbox-bg;
        border-color: $checkbox-bg;
    }

    &:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &:disabled {
        opacity: 0.4;

        & ~ .form-check-label {
            color: #6c757d;
        }
    }
}

.form-check-label {
    position: relative;
    top: 4px;
}

/*------------------------------------*\
    FORM w/ error messages
\*------------------------------------*/
.form-group {
    &.has-error {
        .form-control {
            border-color: $danger;
        }
    }
}

.error {
    display: block;
    color: $danger;
    margin-top: 7px;
}

.field-error {
    display: block;
    color: $danger;
    margin-top: 7px;
}

.input-error {
    display: none;
    color: $danger;
    margin-top: 7px;
}

.error_class,
.error_class_dob {
    color: $danger;
}
