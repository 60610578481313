/*------------------------------------*\
    Create Account
\*------------------------------------*/
.create-content {
    @include clearfix;
    margin-top: 20px;

    .form-group,
    .form-check {
        margin-bottom: 1.5em;
    }

    .skin5 & {
        margin-top: 30px;

        @media #{$grid-lg-min} {
            margin-top: 50px;
        }
    }
}

.create-section {
    margin-bottom: 50px;
}

.create-title {
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-weight: 700;
    color: #000;
    border-bottom: 1px solid #000;
    letter-spacing: 0.5px;
    font-size: 17px;

    @media #{$grid-lg-min} {
        font-size: 21px;
    }
}

.create-btn-wrapper {
    text-align: center;
}

.create-desc {
    margin: 20px 0;
}

.create-same-address {
    margin: 30px 0 0;
    font-weight: 700;
}

/*------------------------------------*\
    Date of Birth
\*------------------------------------*/
.dob-content {
    display: none;
    padding-top: 10px;
    padding-bottom: 5px;
}

.dob-title {
    display: inline-block;
}

.dob-select-container {
    display: inline-block;
}
