/*------------------------------------*\
    FORGOT PASSWORD
\*------------------------------------*/
.page-signout {
}

.page-forgot {
    .breadcrumbs {
        display: none;
    }
}

.reset-pw-content,
.forgot-content {
    @media #{$grid-md-min} {
        width: 80%;
        margin: 0 auto;
        max-width: 550px;
    }

    table {
        width: 100%;
    }

    p {
        margin-bottom: 1.5rem;
    }
}

.forgot-submit {
    @include clearfix;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.btn-forgot {
    @media #{$grid-sm-max} {
        width: 100%;
    }
}

.forgot-back {
    display: block;
    margin: 30px 0;
    text-align: center;

    @media #{$grid-md-min} {
        margin: 0;
        float: right;
    }
}

.forgot-desc {
    a {
        font-weight: 700;
    }
}

.forgot-content {
    .submitted {
        .forgot-desc,
        .form-group,
        .btn-forgot {
            display: none;
        }
    }
}

.reset-buttons {
    margin: 2rem 0;
    text-align: center;

    .btn {
        width: 200px;
    }

    .link {
        display: block;
        margin: 1em 0;
    }
}
