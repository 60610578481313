/*------------------------------------*\
    Prescription
\*------------------------------------*/
.checkout-rx-title {
    margin: 16px 0;
    font-weight: bold;
    font-size: 15px;
}

.checkout-rx-info {
    margin: 0 0 30px;

    #staticPrescription & {
        display: none;
    }
}

.checkout-rx-item {
    position: relative;
    margin-bottom: 10px;

    .checkout-panel {
        margin-left: 0;
        margin-right: 0;
    }

    &.selected {
        .checkout-rx-radio-content,
        .checkout-rx-upload-content {
            display: block;
        }
    }
}

.checkout-rx-radio {
    position: absolute;
    top: 0;
    left: 0;
}

.checkout-rx-radio-title {
    padding-left: 25px;
    cursor: pointer;
}

.checkout-rx-radio-content,
.checkout-rx-upload-content {
    display: none;
    margin: 10px 0 35px;
    padding: 24px;
    background-color: #f1f3f5;

    @media #{$grid-lg-min} {
        padding: 24px 30px;
    }
}

.checkout-rx-radio-content {
    a {
        word-wrap: break-word;
    }
}

.checkout-rx-products {
    margin-bottom: 20px;

    ul {
        margin: 0;
        padding: 0;
        padding-left: 12px;
    }
}

.checkout-rx-upload-wrapper {
    @include clearfix;

    &.invalid {
        .rx-msg-empty {
            display: block;
        }
    }

    .rx-new-files.loading:after {
        background: rgba(241, 243, 245, 0.5);
    }
}

.checkout-rx-selected {
    &.rx-upload-prescription {
        display: none;
    }

    .checkout-rx-radio-title {
        padding-left: 0;
    }
}

.checkout-rx-warning {
    margin: 1rem 0 2rem;
    padding: 1.55rem;
    border-radius: 0.25rem;
    background-color: #e2eefb;
    border: 1px solid #dbebfd;
    color: #4959a7;
    line-height: 1.4;
}

.checkout-rx-btn-wrapper {
    margin: 10px 0;
    text-align: center;
}

.checkout-rx-btn {
    @include btn;
    @include btn-default;
}

.checkout-rx-input-file {
    display: none;
}

ul.checkout-rx-checklist {
    list-style: none; /* Remove default bullets */
}

ul.checkout-rx-checklist li::before {
    content: "\2713"; /* Add tick symbol */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -2.5em; /* Also needed for space (tweak if needed) */
    margin-right: 0.25em;
}


.rx-info {
    margin: 0 0 30px;
}

.rx-msg {
    display: none;
    margin: 20px 0;
    background-color: $color-info;
}

.rx-msg-empty {
    display: none;

    &.error {
        display: block;
        color: $danger;
    }
}

.rx-new-files {
    margin-bottom: 50px;
    line-height: 1.5;

    .item {
        position: relative;
        padding: 10px 55px 10px 30px;

        @media #{$grid-md-min} {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 10px;
        }

        &:before {
            @include icon;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate(0, -50%);
            content: $icon-prescription;
            font-size: 17px;
        }
    }

    &.loading {
        position: relative;
        min-height: 30px;
        color: #000;

        &:before {
            color: #000;
        }

        &:after {
            background: rgba(255, 255, 255, 0.5);
        }
    }
}

.rx-account {
    .rx-new-files .item {
        padding: 15px 35px 15px 40px;
        background: #f1f3f5;
        margin-bottom: 2px;

        @media #{$grid-md-min} {
            padding-right: 10px;
        }

        &:before {
            left: 10px;
        }
    }
}

.rx-file-title {
    display: none;
}

.rx-file-title-inner {
    padding: 15px 10px 15px;
    background: #3c4453;
    color: #fff;
    font-weight: 700;

    @media #{$grid-sm-max} {
        > span {
            display: none;
        }

        &:before {
            content: "Prescription Files";
            display: inline-block;
        }
    }

    @media #{$grid-md-min} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.rx-count {
    display: block;
    margin-bottom: 7px;
    font-weight: 700;

    &:before {
        @include icon;
        display: inline-block;
        position: relative;
        top: 1px;
        padding-right: 3px;
        content: $icon-rx;
    }
}

.rx-btn-add {
    text-decoration: underline;
}

.rx-order-file,
.rx-new-file {
    &.uploading {
        &:after {
            @include icon;
            display: inline-block;
            content: $icon-spinner;
            animation: icon-spin 2s linear infinite;
            float: right;
            font-size: 15px;
        }

        .rx-delete,
        .rx-file-msg {
            display: none;
        }
    }

    &.complete {
        // color: $success;

        &:after {
            @include icon;
            display: inline-block;
            content: $icon-check_circle;
            color: $success;
            font-size: 18px;
            padding-left: 5px;

            @media #{$grid-sm-max} {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translate(0, -50%);
            }
        }

        &.failed {
            color: $danger;

            &:after {
                content: $icon-cancel;
                color: $danger;
            }
        }

        .rx-delete {
            @media #{$grid-sm-max} {
                right: 35px;
            }
        }
    }
}

.rx-file-name {
    flex: 1 1 auto;
    word-break: break-all;
}

.rx-file-msg,
.rx-file-added,
.rx-delete,
.rx-file-status {
    @media #{$grid-md-min} {
        flex-grow: 0;
    }

    @media #{$grid-sm-max} {
        display: block;
    }
}

.rx-file-msg,
.rx-file-added {
    @media #{$grid-md-min} {
        padding: 0 10px;
        text-align: center;
    }
}

.rx-delete {
    position: relative;
    top: 1px;
    color: $danger;
    cursor: pointer;

    @media #{$grid-md-min} {
        padding-left: 10px;
    }

    @media #{$grid-sm-max} {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
    }
}

.rx-file-status {
    @media #{$grid-md-min} {
        width: 70px;
        text-align: right;
    }
}

/*------------------------------------*\
    Prescription Modal
\*------------------------------------*/
.rx-modal {
    @media #{$grid-sm-max} {
        .modal-inner {
            padding: 30px 30px 40px;
        }
    }
}

.rx-modal-title {
    font-size: 18px;
}

.rx-modal-subtitle {
    font-size: 16px;
}

.rx-duplicate-files {
    margin-bottom: 30px;
    padding-left: 17px;
}

.rx-modal-buttons {
    text-align: center;

    .btn {
        margin: 8px 7px;
        min-width: 140px;
    }
}
