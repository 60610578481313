/*------------------------------------*\
    Typography
\*------------------------------------*/

h1 {
    font-size: 22px;
    font-weight: 700;
}

h2 {
    font-size: 18px;
    font-weight: 700;
}

h3 {
    font-size: 13px;
    font-weight: 700;
}

h4 {
    font-size: 13px;
    font-weight: bold;
}

h5 {
    font-size: 13px;
    font-weight: bold;
}

h6 {
    font-size: 11px;
    font-weight: bold;
}

p {
}
