
/*#region Old Style*/

/* CSS Document */

/*html, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,

	{
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	}
*/

#overlayBG {
    display: none;
    width: 100%;
    height: 100%;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

#overlayContent2 {
    display: none;
    width: 639px;
    height: 413px;
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 705;
}

.topic_text_visible {

    color: #333333;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
    background: #F3F3F3;
    padding: 3px;
}

.errorLg {
    border: 1px solid #FF0000;
    color: #FF0000;
    padding: 5px 5px 5px 5px;
    display: block;
    width: 82%;
}

.country_postage {
    width: 600px;
}

    .country_postage td {
        vertical-align: top;
        width: 250px;
        line-height: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

.PriceLine {
    color: #50729F;
    font-weight: normal;
}

.shortdesc {

    color: #666666;
    font-size: 13px;
    font-weight: normal;
}

#ErrorPanel {
    border: 1px solid #000000;
    background-color: #F3F3F3;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
}

.title {

    color: #e20d15;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
}

.content {

    color: #333333;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
}

/*#main .searchinput {
    width: 300px;
    height: 18px;
    margin: 10px 0 0 20px;
    border: 1px solid;
    padding: 10px 10px 10px 10px;
}*/

/*#main .RadSearchBox .rsbInner {
    width: 260px;
    height: 20px;
    margin: 0 0 0 0;
    border: 1px solid #e20d15; #922e8c - chemist.vu
    padding: 5px 5px 5px 5px;
}

#main .RadSearchBox .rsbInput {
    font: 15px Arial, Helvetica, sans-serif;
    color: #515151;
}

#main .RadSearchBoxPopup {
    border: 2px solid #F38800;
}*/

#easyTooltip2 {
    padding: 5px 10px;
    background: #F7F7F7 repeat-x;
    /*background:#F3F3F3 url(bg2.gif") repeat-x;border:1px solid #5e5e5e;*/
    color: #fff;
    width: 200px;
}

    #easyTooltip2 h4, #easyTooltip2 p {
        margin: .25em 0;
    }

.greyheader {
    color: 333;
}

.darkgreyheader {
    color: 999;
}

.questionanswer {
    color: 006;
}

.topictooltip {
    cursor: default;
    margin: 0px;
    padding: 0px;
}

textarea {
    resize: none;
}

pad {
    padding-top: 20px;
}

.border {
    background: url("/App_Themes/Skin_7/images/horizontal_line.gif") no-repeat;
    height: 1px;
}

#ProdImg img {
    width: 80px;
    height: 80px;
}

.right_prod_pane {
    padding-top: 0px;
    width: 25%;
    text-align: left;
}

.right_prod_panel {
    padding-top: 8px;
    width: 25%;
    text-align: left;
}

.right_prod {
    margin-top: 10px;
    width: 25%;
    text-align: left;
}

.ProductProdHeader {
    margin-left: 12px;
    color: #333333;
}

.ProductProdHeaderHover {
    margin-left: 12px;
    color: #e20d15;
}

.ProductProdHeader a {
    color: #333333;
    cursor: pointer;
    font-size: 13px;

}

.ProductProdHeaderhover a {
    color: #e20d15;
    cursor: pointer;
    font-size: 13px;

}

.prodHeader {
    padding-bottom: 5px;
    margin-left: -12px;
}

.prodHeaderhover {
    color: #e20d15;
    padding-bottom: 5px;
    margin-left: -12px;
}

.prodHeader a {
    color: #333333;
    cursor: pointer;
    font-size: 13px;

}

.prodHeaderhover a {
    color: #e20d15;
    cursor: pointer;
    font-size: 13px;

}

.right_prod_list {
    text-align: left;
    list-style-image: url("/PH Images/greyarrow.gif");
}

    .right_prod_list ul li {
        text-align: left;
        padding-top: 6px;
        width: 135px;
    }

a.read {
    font-size: 11px;
    cursor: pointer;
}

.read a:hover {
    font-size: 11px;
    cursor: pointer;
}

.tbmain {
    margin-left: 35px;
    margin-right: 35px;
    background-color: #ffffff;
    text-align: left;

    color: #666666;
    font-size: 13px;
    font-weight: normal;
}

.pad {
    padding-top: 55px;
    padding-bottom: 5px;
}

.mright {
    padding-left: 11px;
}

.back {
    background-color: #f3f3f3;
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.catName {
    margin: 0;
    font-size: 27px;
    font-weight: 400;

    padding-bottom: 6px;
    color: #969696;
}

.postCatName {
    font-size: 28px;

    padding-bottom: 6px;
}

#prosum {

    font-size: 13px;
    font-style: normal;
    color: #969696;
    line-height: 20px;
}

#desc {

    font-size: 13px;
    font-style: normal;
    color: #666666;
}


.catName_Right {
    padding-bottom: 3px;
    font-size: 22px;

}

.login {

    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    color: #666666;
}

.username {

    color: #e20d15;
    font-size: 13px;
    font-weight: bold;
}


.accountname {

    color: #666666;
    font-size: 13px;
    font-weight: normal;
}

    .accountname a {

        color: #666666;
        font-size: 13px;
        font-weight: bold;
    }

.user_text {

    color: #e20d15;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
}

    .user_text a {

        color: #666666;
        font-size: 13px;
        font-weight: bold;
        line-height: 20px;
    }

        .user_text a:hover {

            color: #E20D15;
            font-size: 13px;
            font-weight: bold;
        }


.topic_text {

    color: #666666;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
}

    .topic_text a {

        color: #666666;
        font-size: 13px;
        line-height: 20px;
        font-weight: normal;
        text-decoration: none;
    }

        .topic_text a:hover {

            color: #e20d15;
            font-size: 13px;
            line-height: 20px;
            font-weight: normal;
            text-decoration: none;
        }

.body_text {

    color: #666666;
    font-size: 11px;
    font-weight: normal;
    line-height: 20px;
}

    .body_text a {

        color: #666666;
        font-size: 11px;
        font-weight: bold;
        line-height: 20px;
        text-decoration: none;
    }

        .body_text a:hover {

            color: #e20d15;
            font-size: 11px;
            font-weight: bold;
            line-height: 20px;
            text-decoration: none;
        }

.countryq {

    font-size: 11px;
    font-weight: bold;
    padding-right: 2px;
}

.hide {
    display: block;
    text-indent: -9999px;
    outline: 0;
}

/*.logo {
    background: url("/App_Themes/Skin_7/images/logo.gif") no-repeat right;
    outline: none;
    width: 184px;
    height: 143px;
}*/

div.nudge {
    padding-left: 10px;
    width: 200px;
    height: 156px;
}

div.topimg {
    padding-bottom: 5px;
    padding-right: 50px;
}

div.botimg {
    padding-right: 50px;
}


.gray_line {
    height: 1px;
    background-color: #333333;
}

.lightgray_line {
    height: 1px;
    background-color: #C3C3C3;
}

.horizontal_line {
    background: url("/App_Themes/Skin_7/images/horizontal_line.gif") no-repeat;
    height: 1px;
}

.horizontal_solid_line {
    background: url("/App_Themes/Skin_7/images/horizontal_solid_line.gif") no-repeat;
    height: 1px;
}

.columns {

    font-size: 13px;
    /*font-weight:bold;*/
    color: #333333;
    line-height: 19.5px;
}

.vertical_line {
    height: 143px;
    background: url("/App_Themes/Skin_7/images/vertical_line.gif") no-repeat;
    width: 22px;
}




.searchtxtinput {
    width: 154px;
    border: 1px solid #E20D15;
    padding-left: 5px;
    padding-right: 5px;
    color: #666666;

    font-size: 12px;
}

.innersearchtxtinput {
    border: 1px solid #E20D15;
    padding-left: 5px;
    color: #666666;

    font-size: 12px;
    height: 18px;
}

.search_button_div {
    float: left;
    position: relative;
    right: -15px;
}

.search_button {
    background: url("/App_Themes/Skin_7/images/search.jpg") no-repeat left;

    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
    width: 70px;
    height: 29px;
    border: none;
    cursor: pointer;
    margin: 0px;
    padding-bottom: 5px;
}

    .search_button:hover {
        background: url("/App_Themes/Skin_7/images/search_rollover.jpg") no-repeat left;
        padding-bottom: 5px;

        color: #ffffff;
        font-weight: normal;
        font-size: 11px;
        width: 70px;
        height: 29px;
        line-height: 29px;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
    }

#SelectCoun {
    margin: 0px;
    padding: 0px;
}

#MainTD {
    height: 41px;
}

    #MainTD .RadSearchBox {
        position: relative;
        left: -106px;
    }

        #MainTD .RadSearchBox .rsbInner {
            width: 250px;
            height: 16px;
            margin: 0 0 0 0;
            border: 1px solid #e20d15;
            padding: 5px 5px 5px 5px;
        }

        #MainTD .RadSearchBox .rsbInput {
            font: 15px Arial, Helvetica, sans-serif;
            color: #515151;
        }

        #MainTD .RadSearchBox .rsbEmptyMessage {
            color: #aaaaaa;
        }

.searchtext {
    width: 238px;
    height: 15px;
    border: 2px solid #F38800;
    padding: 5px 5px 5px 5px;
}


.big_text {

    font-size: 17px;
    font-weight: normal;
    color: #858585;
    text-align: left;
    padding-left: 16px;
    height: 129px;
    vertical-align: middle;
}

.OrderHome {
    list-style-position: outside;
    margin-left: 0;
    padding-left: 3em;
}

    .OrderHome li {
        padding: 0;
        border: none;
        margin: 0;
        list-style-position: outside;
    }

// .seals {
//     color: #858585;
//     font-weight: bold;
//     font-size: 14px;
//     text-align: left;
//     font-weight: lighter;
//     line-height: 30px;
//     padding-left: 21px;
// }



.help_you {

    font-size: 40px;
    font-weight: normal;
    color: #000000;
    text-align: left;
    vertical-align: bottom;
}

.free_shipping_head {

    font-size: 15.5px;
    font-weight: normal;
    color: rgb(102,102,102);
    text-align: center;
}

.free_shipping_subhead {

    font-size: 8.6px;
    font-weight: normal;
    color: rgb(102,102,102);
    text-align: center;
    padding-bottom: 5px;
}

/*.search_for_medicine {

    font-size: 24px;
    color: #E20D15;
    font-weight: bold;
    line-height: 27px;
}

.search_for_medicine .RadSearchBox {
    width: 160px;
}

    .search_for_medicine .RadSearchBox .rsbInner {
        width: 160px;
        height: 25px;
        border: 2px solid #e20d15;
        padding: 0 0 0 5px;
    }

    .search_for_medicine .RadSearchBox .rsbInput {
        font: 12px Arial, Helvetica, sans-serif, bold;
        color: #515151;
        padding: 5px 0 5px 0;
    }

    .search_for_medicine .RadSearchBox .rsbButton {
        margin-top: 3px;
    }*/


.order_for_home {

    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    color: #000000;
    line-height: 20px;
}

.print_thispage {

    font-size: 11px;
    font-weight: normal;
    color: #666666;
    line-height: 20px;
    text-align: right;
}

    .print_thispage a {

        font-size: 11px;
        font-weight: normal;
        color: #333333;
        text-align: center;
        height: 26px;
        line-height: 26px;
        text-decoration: none;
        padding: 12px;
    }

        .print_thispage a:hover {

            font-size: 11px;
            font-weight: normal;
            color: #FF0000;
            text-align: center;
            height: 26px;
            line-height: 26px;
            text-decoration: underline;
        }

.footer_navigation {

    font-size: 13px;
    font-weight: normal;
    color: #333333;
    text-align: center;
    height: 26px;
    line-height: 26px;
}

    .footer_navigation a {

        font-size: 13px;
        font-weight: normal;
        color: #333333;
        text-align: center;
        height: 26px;
        line-height: 26px;
        text-decoration: none;
    }

        .footer_navigation a:hover {

            font-size: 13px;
            font-weight: normal;
            color: #FF0000;
            text-align: center;
            height: 26px;
            line-height: 26px;
            text-decoration: underline;
        }

.copyright {

    font-size: 11px;
    font-weight: normal;
    color: #333333;
    text-align: center;
}

    .copyright a {

        font-size: 11px;
        font-weight: normal;
        color: #333333;
        text-align: center;
        text-decoration: none;
    }

        .copyright a:hover {

            font-size: 11px;
            font-weight: normal;
            color: #FF0000;
            text-align: center;
            text-decoration: underline;
        }

.lineborder {
    background: url("/App_Themes/Skin_7/images/horizontal_line.gif") no-repeat;
    height: 1px;
    width: 180px;
}

.nvbg {
    font-weight: normal;
}

    .nvbg a {
        color: #333333;
        font-weight: normal;
    }

#SubName {
    padding-top: 35px;
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 13px;

}

    #SubName p {

        text-transform: none;
    }

    #SubName a {
        padding-top: 16px;
        padding-bottom: 20px;
        cursor: pointer;
        background: url("/PH Images/redarrow.gif") no-repeat left center;
        padding-left: 12px;
        text-transform: uppercase;
        font-size: 13px;

        color: #000;
    }

        #SubName a:hover {
            color: #e20d15;
            padding-top: 16px;
            padding-bottom: 20px;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 13px;

            background: url("/PH Images/redarrow_hover.gif") no-repeat left center;
        }

#ProdName {
    padding-bottom: 2px;
    text-align: left;
}

    #ProdName a {
        padding-top: 16px;
        padding-bottom: 20px;
        cursor: pointer;
        background: url("/PH Images/redarrow.gif") no-repeat left center;
        padding-left: 12px;
        color: #000;
    }

        #ProdName a:hover {
            color: #e20d15;
            padding-top: 16px;
            padding-bottom: 20px;
            cursor: pointer;
            background: url("/PH Images/redarrow_hover.gif") no-repeat left center;
        }

    #ProdName h3 {
        margin: 0px;
        padding: 0px;
        display: inline;
    }



.SubHeading {
    text-transform: uppercase;
    font-size: 13px;

    color: #333333;
    /*line-height: 16px;*/
}

    .SubHeading H2 {
        text-transform: uppercase;
        font-size: 13px;

        color: #333333;
        margin: 0;
        padding: 0;
        display: inline-block;
        width: 85%;
    }

    .SubHeading a {
        color: #333333;
        cursor: pointer;
        font-size: 13px;

    }


.SubHeadingHover {
    text-transform: uppercase;
    font-size: 13px;

    color: #333333;
    background-color: #F3F3F3;
}

    .SubHeadingHover a {
        text-transform: uppercase;
        font-size: 13px;

        color: #333333;
        background-color: #F3F3F3;
    }

.SubCatName {
    color: #e20d15;
    padding-top: 16px;
    padding-bottom: 20px;
    padding-left: 12px;
    cursor: pointer;
    background: url("/App_Themes/Skin_7/images/redarrowldowngif.gif") no-repeat left center !important;
}


#SubProduct {
    padding-top: 5px;
    padding-bottom: 15px;
}

#innerdesc-3 {
    padding: 0px;
    margin: 0px;
}

.SecDiv {
    color: #333333;
    line-height: 19.5px;
    font-size: 13px;

    font-weight: normal;
    padding-left: 3px;
}

    .SecDiv a:link, .SecDiv a:hover, .SecDiv a:visited {
        color: #333333;
        line-height: 19.5px;
        font-size: 13px;

        font-weight: normal;
        padding-left: 3px;
    }

.SecHover {
    color: #e20d15;
    line-height: 19.5px;
    font-size: 13px;

    font-weight: normal;
    padding-left: 3px;
}

    .SecHover a:link, .SecHover a:hover, .SecHover a:visited {
        color: #e20d15;
        line-height: 19.5px;
        font-size: 13px;

        font-weight: normal;
        padding-left: 3px;
    }



.CheckoutRegisterButton {
    background: transparent url("/App_Themes/Skin_7/images/checkout_register.jpg") no-repeat scroll;
    height: 27px !important;
    width: 250px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .CheckoutRegisterButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/checkout_register_rollover.jpg") no-repeat scroll;
        height: 27px !important;
        width: 250px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.CheckoutAnonButton {
    background: transparent url("/App_Themes/Skin_7/images/checkout_anon.jpg") no-repeat scroll;
    height: 27px !important;
    width: 250px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .CheckoutAnonButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/checkout_anon_rollover.jpg") no-repeat scroll;
        height: 27px !important;
        width: 250px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.CheckoutSigninButton {
    background: transparent url("/App_Themes/Skin_7/images/checkout_signin.jpg") no-repeat scroll;
    height: 27px !important;
    width: 250px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .CheckoutSigninButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/checkout_signin_rollover.jpg") no-repeat scroll;
        height: 27px !important;
        width: 250px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.UpdateCartButton {
    background: transparent url("/App_Themes/Skin_7/images/updatecart.jpg") no-repeat scroll center center;
    height: 27px !important;
    width: 100px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .UpdateCartButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/updatecart_rollover.jpg") no-repeat scroll center center;
        height: 27px !important;
        width: 100px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.MoveToCartButton {
    background: transparent url("/App_Themes/Skin_7/images/Add_To_Cart.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 99px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    display: block;
    margin: 0px;
    padding-bottom: 0px;
}

    .MoveToCartButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Add_To_Cart_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 99px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        display: block;
        margin: 0px;
        padding-bottom: 0px;
    }

.AddToCartButton_over {
    background: transparent url("/App_Themes/Skin_7/images/Add_To_Cart_Rollover.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 99px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

.AddToWishButton {
    background: transparent url("/App_Themes/Skin_7/images/Add_To_Favorites.jpg") no-repeat scroll center center;
    height: 15px !important;
    width: 100px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
}

    .AddToWishButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Add_To_Favorites_Rollover.jpg") no-repeat scroll center center;
        height: 15px !important;
        width: 100px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
    }

.SignUpdateCartButton {
    background: transparent url("/App_Themes/Skin_7/images/signin_checkout.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 141px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

.RegisterUpdateCartButton {
    background: transparent url("/App_Themes/Skin_7/images/regsiter_checkout.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 157px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

.ContinueShoppingButton {
    background: transparent url("/App_Themes/Skin_7/images/Continue_Shopping.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 142px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .ContinueShoppingButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Continue_Shopping_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 142px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.CheckoutNowButton {
    background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 155px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .CheckoutNowButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 155px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.UpdateWishButton {
    background: transparent url("/App_Themes/Skin_7/images/Update_Favorites.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 131px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .UpdateWishButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Update_Favorites_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 131px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.SaveNewAddressButton {
    background: transparent url("/PH Images/Save_New_Address.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 168px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .SaveNewAddressButton:hover {
        background: transparent url("/PH Images/Save_New_Address_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 168px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
        padding: 0px;
    }

#pnlAddress {
    width: 400px;
}

.AddressButton {
    margin-right: 20px;
}

.EditAddressButton {
    background: transparent url("/PH Images/save_edited_address.jpg") no-repeat scroll center center;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

    .EditAddressButton:hover {
        background: transparent url("/PH Images/save_edited_address_Rollover.jpg") no-repeat scroll center center;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        display: block;
        margin: 0px;
        padding: 0px;
    }

.DeleteAddressButton {
    background: transparent url("/PH Images/Delete_Address.jpg") no-repeat scroll center center;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

    .DeleteAddressButton:hover {
        background: transparent url("/PH Images/Delete_Address_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 168px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        display: block;
        margin: 0px;
        padding: 0px;
    }

.PageNumber {

    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
}

    .PageNumber a:active {

        font-size: 13px;
        font-weight: bold;
        line-height: 20px;
        text-decoration: underline;
    }

.pagingbg a:active {

    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: underline;
}

.pagingbg {

    color: #e20d15;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
    text-align: right;
}

.pagenav {

    color: #000000;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
}

#MProdImg {
    vertical-align: top;
    text-align: left;
}

    #MProdImg a img {
        margin-left: -40px;
        position: relative;
        padding-top: 5px;
    }

    #MProdImg img {
        float: left;
        margin-left: -100px;
        position: relative;
    }

.MProductImg {
}

.ProductNameText {
    color: #333333;

    font-size: 13px;
    line-height: 19.5px;
}


.ProductName {
    color: #000000;

    font-size: 22px;
}

    .ProductName H1 {
        color: #000000;

        font-size: 22px;
        font-weight: bold;
        margin: 0px;
    }

.Discounts {
    font-size: 13px;
    font-weight: normal;
    padding-right: 125px;
}

.borderline {
    background: url("/PH Images/pipe.jpg") no-repeat;
    height: 1px;
    text-align: center;
}

#cartImg img {
    height: 80px;
    width: 80px;
}

.txtforgot {
    font-size: 11px;

    color: #666666;
}

.UpdateAccountButton {
    background: transparent url("/App_Themes/Skin_7/images/update_my_personal_details.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 204px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .UpdateAccountButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/update_my_personal_details_rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 204px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }

.AccountPageContinueCheckoutButton {
    background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 170px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .AccountPageContinueCheckoutButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Proceed_to_Purchase_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 170px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }


#pnlBilling img {
    padding-top: 3px;
    padding-bottom: 3px;
}

#pnlShipping img {
    padding-top: 3px;
    padding-bottom: 3px;
}
/*
  impactecommerce: Date Sept 13,2009
  Description: Changed 150px to 200px
*/

#SelectCoun select {
    width: 170px;
    border: 1px solid #C3C3C3;
}

.ShippingPageContinueCheckoutButton {
    background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 170px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin-top: 15px;
    padding: 0px;
}

    .ShippingPageContinueCheckoutButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Proceed_to_Purchase_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 170px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin-top: 15px;
        padding: 0px;
    }

.ReviewPageContinueCheckoutButton {
    background: transparent url("/App_Themes/Skin_7/images/confirm_purchase.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 185px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
}

    .ReviewPageContinueCheckoutButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/confirm_purchase_rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 185px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
    }

.chk {
    color: #666666;

    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
}

.ShipText {
    color: #666666;

    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
}

.LightCellText {
    color: #666666;

    font-size: 13px;
    /*font-weight:bold;*/
    text-decoration: none;
}

.forgot {
    color: #000000;

    font-size: 13px;
    font-weight: bold;
}


.LightCellText2 {
    color: #333333;

    font-size: 12px;
    font-weight: bold;
}

.PrivateSignInLogin {
    background-color: #a3a3a3;
    color: white;
    font-size: 12px;
    width: 119px;
    height: 22px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
}

    .PrivateSignInLogin:hover {
        background-color: #b2b0b0;
    }

.PrivateLostPasswordButton {
    background-color: white;
    color: #a3a3a3;
    font-size: 13px;
    cursor: pointer;
    border: 0;
    width: 200px;
    padding: 0;
    text-align: left;
}

    .PrivateLostPasswordButton:hover {
        color: red;
    }

#privateLostPassword h2 {
    color: red;
    font-size: 30px;
    font-weight: lighter;
    margin-bottom: 0;
}



a.ClickToRegister {
    background: transparent url("/PH Images/Click_To_Register.jpg") no-repeat scroll center center;
}

    a.ClickToRegister:hover {
        background: transparent url("/PH Images/Click_To_Register_Rollover.jpg") no-repeat scroll center center;
    }

#tdright {
    width: 100%;
    float: left;
}

.member {
    color: #e20d15;

    font-size: 1.5em;
    padding-top: 10px;
    padding-bottom: 8px;
    text-decoration: none;
}

#paymentPanes table tr td {
    padding: 3px;
}

.CardInput {
    width: 100%;
    border: 1px solid #666666;
    padding-left: 5px;
    color: #666666;

    font-size: 12px;
}

.CheckOutTermsBorder {
    color: #666666;

    font-size: 13px;
}

#pnlErrorMsg {
    margin-top: 10px;
    color: red;

    font-size: 13px;
    /*background:none repeat scroll 0 0 pink;*/
    /*border:1px solid red;*/
    padding: 10px;
}

.pnlCartAllowsShippingMethodSelection {
    color: #666666;

    font-size: 13px;
}

.error {
    color: red;

    font-size: 13px;
}

.TopSearchError {
    color: red;

    font-size: 13px;
}

.Address {
    color: #666666;

    font-size: 13px;
    padding: 5px;
    line-height: 1.7;
}


.credit {
    color: #666666;

    font-size: 13px;
    padding: 5px;
}

.CardSelect {
    width: 190px;
    border: 1px solid #666666;
    color: #666666;

    font-size: 12px;
}

.CardLabel {
    font-weight: bold;
}

.contactinput {
    width: 400px;
    border: 1px solid #C3C3C3;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    color: #666666;
    font-size: 12px;
}

.contactlabel {
    font-size: 13px;
    color: #666666;
}

.contactlabel2 {
    font-size: 11px;
    color: #666666;

}

.contactformlabel {
    font-size: 12px;
    color: #333333;
    font-weight: bold;

}

.accountlabel2 {
    font-size: 11px;
    color: #666666;

}

#pnlAccountInfo table tr td {
    padding-bottom: 5px;
    padding-top: 5px;
}

#pnlShippingInfo table tr td {
    padding-bottom: 5px;
    padding-top: 5px;
}


#pnlBillingInfo table tr td {
    padding-bottom: 5px;
    padding-top: 5px;
}


.accounthead {
    font-size: 22px;
    color: #333333;
    font-weight: bold;

    padding: 0px;
}

.accountheadshoppingcart {
    font-size: 22px;
    color: #333333;
    font-weight: bold;

    padding: 0px;
}

.chkshipping {
    font-size: 13px;
    color: #e20d15;

    font-weight: bold;
}

#pnlAddressListBottom span {
    font-size: 13px;
    color: #333333;
    font-weight: bold;

}

#pnlAddressListBottom li {
    list-style: none;
    color: #666666;
    font-size: 12px;
    line-height: 22px;
}

#pnlAddressListBottom {
    list-style: none;
}

.bill {
    font-size: 13px;

    color: #000000;
    font-weight: bold;
}

a.lnk {
    font-size: 11px;

    color: #666666;
    font-weight: bold;
}

a:hover.lnk {
    font-size: 11px;

    color: #e20d15;
    font-weight: bold;
}


.accountlabel {
    font-weight: bold;
    padding-bottom: 5px;
    padding-top: 5px;
}

.accountlabeladdress {
    font-size: 13px;
    color: #333333;
    font-weight: bold;

    padding-bottom: 5px;
    padding-top: 15px;
}
//
// .ContinueCheckoutButton {
//     background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase.jpg") no-repeat scroll center center;
//     height: 31px !important;
//     width: 185px;
//     font-size: 0px;
//     line-height: 0px;
//     cursor: pointer;
//     border: 0;
//     clear: none;
//     margin: 0px;
// }
//
//     .ContinueCheckoutButton:hover {
//         background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase_ROllover.jpg") no-repeat scroll center center;
//         height: 31px !important;
//         width: 185px;
//         font-size: 0px;
//         line-height: 0px;
//         cursor: pointer;
//         border: 0;
//         clear: none;
//         margin: 0px;
//     }

.SendButton {
    background: transparent url('/PH Images/submit_black.png') no-repeat scroll center center;
    height: 31px !important;
    width: 91px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
}

    .SendButton:hover {
        background: transparent url('/PH Images/submit_grey.png') no-repeat scroll center center;
        height: 31px !important;
        width: 91px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
    }

.SendButtonGoGreen {
    background: transparent url('/PH Images/submit_green.png') no-repeat scroll center center;
}

.cancelButton {
    background: transparent url("/App_Themes/Skin_7/images/cancel.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 71px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
}

    .cancelButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/cancel_rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 71px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        clear: none;
        margin: 0px;
    }

.searchagain {
    background: transparent url("/App_Themes/Skin_7/images/searchagain.gif") no-repeat scroll center center;
    height: 31px !important;
    width: 109px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin: 0px;
}

.searchagainLive {
    background: transparent url("/App_Themes/Skin_7/images/searchagain.gif") no-repeat scroll center center;
    height: 31px !important;
    width: 109px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    clear: none;
    margin-left: 200px;
}

.prodhead a {
    color: #000000;
    font-size: 13px;

    font-weight: bold;
}

    .prodhead a:hover {
        color: #e20d15;
        font-size: 13px;

        font-weight: bold;
    }

.view a {
    font-size: 10px;

    font-weight: bold;
}

    .view a:hover {
        font-size: 10px;

        font-weight: bold;
    }

.discount {
    // font-size: 16px;
    // color: #e20d15;
    font-weight: bold;
    // padding-left: 3px;
}

.dispro {
    font-size: 12px;
    color: #666666;
    font-weight: bold;

    padding-left: 5px;
}

.background {
    background-color: #F6F7F7;
    padding-left: 8px;
}

#CCDetailsTable input {
    margin: 0px 0px;
}

#CCDetailsTable select {
    margin: 0px 10px 10px 0px;
}

#DOBTable input {
    margin: 0px 0px;
}

#DOBTable select {
    margin: 0px 10px 10px 0px;
}

#SSNTable input {
    margin: 0px 0px;
}

#SSNTable select {
    margin: 0px 10px 10px 0px;
}

.PaymentPageContinueCheckoutButton {
    background: transparent url("/App_Themes/Skin_7/images/Proceed_To_Purchase.jpg") no-repeat scroll center center;
    height: 31px !important;
    width: 170px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    /*text-indent:-1000px;*/
    margin: 0px;
}

    .PaymentPageContinueCheckoutButton:hover {
        background: transparent url("/App_Themes/Skin_7/images/Proceed_to_Purchase_Rollover.jpg") no-repeat scroll center center;
        height: 31px !important;
        width: 170px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        /*text-indent:-1000px;*/
        margin: 0px;
    }

.vertical_line {
    background: url("/App_Themes/Skin_7/images/vertical_line.gif") no-repeat;
    width: 22px;
}

#EntityPanel ul {
    list-style: none;
    margin-top: 0px;
}

.uppercontent {
    font-size: 13px;
    color: #666666;
    font-weight: bold;

}

.middlecontent {
    font-size: 13px;
    color: #666666;
    font-weight: bold;

    padding-left: 20px;
}

/*.tophead {
    color: #3F3F3F;

    font-size: 15px;
    font-weight: bold;
}*/

.labelhead {
    color: #333333;

    font-size: 12px;
    font-weight: bold;
    width: 24%;
}

.labelhead2 {
    color: #666666;

    font-size: 13px;
    font-style: normal;
    width: 76%;
}

.ordercolumns {
    font-size: 12px;
    color: #333333;
    font-weight: bold;

}

.ordercolumnsheadbg {
    background-color: #F0F0F0;
    height: 50px;
    padding-top: 5px;
}

.ordercolumnsdetail {
    font-size: 12px;
    color: #666666;
    font-weight: bold;

    padding: 5px;
}

.ordercolumnsdetailID {
    font-size: 13px;
    text-decoration: underline;
    color: #333333;
    font-weight: bold;

    padding: 0px;
}

.Billingbold {
    color: #333333;

    font-size: 13px;
    font-weight: bold;
    padding-top: 5px;
}

.vertical_line1 {
    height: 143px;
    background: url("/App_Themes/Skin_7/images/line.gif") no-repeat;
    width: 22px;
}

.pnlBillingtext {
    padding-top: 5px;
    padding-left: 9px;
    color: #666666;

    font-size: 12px;
}

.pnlBillingtitle {
    color: #333333;

    font-size: 15px;
    font-weight: bold;
}

.lineht {
    padding-bottom: 3px;
    padding-top: 3px;
}

.addship {
    font-size: 11px;
    color: #666666;

    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
}

a:hover.addship {

    color: #e20d15;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: none;
}

.leftPName {
    color: #000000;

    font-size: 13px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 3px;
}

.leftPName1 {
    color: #000000;

    font-size: 13px;
    font-weight: bold;
    text-align: left;
    padding-right: 5px;
}

.pdetail {
    color: #666666;

    font-size: 13px;
    padding: 8px;
    text-decoration: none;
}

.sep {
    margin: 0px;
    padding: 0px;
    vertical-align: middle;
}

#blankLine {
    height: 10px;
}

.ProductLink {
    font-weight: normal;
    font-size: 13px;

    color: #333333;
    padding-bottom: 10px;
}


    .ProductLink a {
        font-weight: normal;
        color: #333333;
        cursor: pointer;
        font-size: 13px;

        padding-bottom: 10px;
    }

        .ProductLink a:hover {
            font-weight: normal;
            color: #333333;
            cursor: pointer;
            font-size: 13px;

            padding-bottom: 10px;
        }

.ProductLinkHover {
    font-weight: normal;
    font-size: 13px;

    color: #333333;
    padding-bottom: 10px;
}

    .ProductLinkHover a {
        font-weight: normal;
        font-size: 13px;

        color: #e20d15;
        padding-bottom: 10px;
    }

.SelectedNav {
    font-weight: normal;
    font-size: 13px;

    color: #FFFFFF;
    background-color: #333333;
}

    .SelectedNav a:link, .SelectedNav a:hover, .SelectedNav a:visited {
        font-weight: normal;
        font-size: 13px;

        color: #fff;
        background-color: #333333;
    }

/*.SelectedNav a:hover {
        font-weight: normal;
        font-size: 13px;

        color: #fff;
        background-color: #333333;
    }*/

.proddesc {

    font-size: 13px;
    color: #666666;
    font-weight: normal;
    text-align: justify;
}

#seemore {
    margin-top: -20px;

    font-size: 13px;
    color: #666666;
    font-weight: normal;
}

    #seemore a {
        background: none !important;

        font-size: 13px;
        color: #666666;
        font-weight: normal;
    }

        #seemore a:hover {

            font-size: 13px;
            color: #e20d15;
            font-weight: normal;
        }

#clicktoview {
    margin-left: 5px;
    font-weight: normal;
    font-size: 11px;
}

.view {
    background: url("/PH Images/redarrow.gif") no-repeat scroll left center transparent;
    padding-left: 10px;
    padding-right: 50px;
    padding-top: 5px;
    vertical-align: bottom;
}

.radiotd {
    width: 1%;
    vertical-align: top;
}

.shippingtd {
    width: 90%;
    padding-bottom: 10px;
    padding-top: 3px;
}

.catName_Right_sitemap {

    font-size: 18px;
    padding-bottom: 5px;
}

#EntityPanel {
    /*margin-top:-10px;*/
}

.vertical_line_sitemap {
    -moz-background-clip: border;
    -moz-background-inline-policy: continuous;
    -moz-background-origin: padding;
    background: transparent url("/App_Themes/Skin_7/images/vertical_line.gif") repeat-y scroll 0 0;
}

#Sitemap ul {
    margin-left: 5px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0px;
}

    #Sitemap ul li {
        margin-left: 10px;
    }

#Sitemap {
    text-align: left;
}

.plink a {
    font-weight: normal !important;
}

#pnlAddress table tr td {
    padding-bottom: 5px;
    padding-top: 5px;
    color: #000000;

    font-size: 13px;
    font-weight: bold;
}

.SelectAddressButton {
    background-image: url("images/Add_New_Address.jpg");
    border: 0 none;
    clear: none;
    cursor: pointer;
    font-size: 0;
    height: 30px !important;
    line-height: 0;
    margin: 0;
    /*text-indent:-1000px;*/
    width: 168px;
}

#pnlThisAddress #pnlNewAddress table tr td table tr td input {
    border: 1px solid #C3C3C3;
    color: #666666;

    font-size: 12px;
    padding-left: 5px;
    width: 94%;
}

.nvalid {
    padding-bottom: 5px;
    padding-top: 10px;
    color: #000000;

    font-size: 13px;
    font-weight: bold;
    width: 100%;
}

#pnlThisAddress #pnlNewAddress table tr td table tr td select {
    border: 1px solid #C3C3C3;
    color: #666666;

    font-size: 12px;
    padding-left: 5px;
    width: 95%;
}

#pnlThisAddress #pnlNewAddress table tr td table tr td {
    border: 1px solid #C3C3C3;
    color: #666666;

    font-size: 12px;
    padding-left: 5px;
    width: 95%;
}

.acc {
}

    .acc table tr td table tr td {
        font-size: 10px;
    }

#affiliateerror {
    background-color: #FFC9C7;
    border: 1px solid #FC0004;
    color: #FC0004;
    font-weight: bold;
    margin-left: 11px;
    overflow: hidden;
    padding: 15px;
    width: 498px;
}

#easyTooltip {
    background-color: #ffffff;
    padding: 10px;
    width: 300px;
    border: 1px solid #C3C3C3;
}

div#PopupLayer {
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.popupShadow1 {
    background: none repeat scroll 0 0 #000000;
    height: 100%;
    left: 0;
    opacity: 0.5;
    filter: alpha(opacity = 50);
    zoom: 1;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 998;
}

.popupShadow2 {
    height: 570px;
    left: 50%;
    margin: -285px -338px;
    position: absolute;
    top: 50%;
    width: 674px;
    z-index: 999;
}

.popup {
    left: 53%;
    margin: -230px -328px;
    position: absolute;
    text-align: left;
    top: 50%;
    width: 654px;
    z-index: 1000;
}

img#closepopup {
    position: absolute;
    right: 63px;
    top: -10px;
    cursor: pointer;
}

#ContCheckout {
    padding-left: 580px;
}

.AccountDetails {
    line-height: 25px;
}

.CartSummary {
    width: 530px;
}

.addressImagebuttons {
    width: 50%;
    vertical-align: top;
    line-height: 13px;
}

.ManageAddressBorder1 {
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px 5px 5px 5px;
}

    .ManageAddressBorder1:hover {
        border-bottom: 1px solid #000000;
        border-left: 1px solid #000000;
        border-top: 1px solid #000000;
        border-right: 1px solid #000000;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        -moz-border-radius-bottomleft: 4px;
        -moz-border-radius-bottomright: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #D6D5D5;
        padding: 5px 5px 5px 5px;
        color: #666666;
    }

.ManagePrimaryAddress {
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #F5F5F5;
}

    .ManagePrimaryAddress:hover {
        border-bottom: 1px solid #000000;
        border-left: 1px solid #000000;
        border-top: 1px solid #000000;
        border-right: 1px solid #000000;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        -moz-border-radius-bottomleft: 4px;
        -moz-border-radius-bottomright: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #F5F5F5;
    }

.ManageRadio {
    vertical-align: top;
}

.AddNewAddress {
    vertical-align: middle;
}

.CookieWarning {
    border: 1px solid #000000;
    border-radius: 5px 5px 5px 5px;
    height: 358px;
    margin-left: 190px;
    text-align: center;
    width: 593px;
    margin-top: 35px;
}

.paymentError {
    padding-top: 12px;
}

.errorBlock {
    background: none repeat scroll 0 0 #FAEBE7;
    border: 1px solid #FF0000;
    color: #FF0000 !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    margin-right: 2px;
    margin-top: 5px;
    padding: 13px 10px;
    text-align: center;
    vertical-align: middle;
    width: 520px;
}

.LowerDescription {
    color: #969696;
    line-height: 20px;
}

.LowDesc {
    Display: none;
    line-height: 20px;
}

a.subread {
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
    color: #000000;
}

a.bottomread {
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
    color: #000000;
}

a.summaryread {
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
    color: #000000;
}

div.LowSum {
    color: #969696;
    line-height: 20px;
    margin-left: 42px;
}

    div.LowSum h1 {
        color: #969696;
        line-height: 20px;
    }

    div.LowSum h2 {
        color: #969696;
        line-height: 20px;
    }

.ProductList {
    list-style-type: circle;
}

    .ProductList li {
        list-style-type: circle;
    }

.PrecriptionInput {
    border: 1px solid #91278e;
}

.PersonalDeclaration table tr td {
    width: 0%;
}

.CustomError {
    background: none repeat scroll 0 0 #FAEBE7;
    border: 1px solid #FF0000;
    color: #FF0000 !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    margin-left: -42px;
    padding: 13px 10px;
    text-align: center;
    vertical-align: middle;
    width: 508px;
}

.LiveSearch {
    margin-left: 0px;
    list-style: none;
    width: 152px;
    border: 1px solid #E20D15;
    margin-top: 5px;
}

.LiveSearchItem {
    margin-left: -37px;
    background-color: #F3F3F3;
}

.LiveSearchSelected {
    margin-left: -37px;
    color: #FFFFFF;
    background-color: #333333;
}


.hlight {
    background: yellow;
}

.blight {
    background: black;
}

.LastSearchTerm {
    margin-left: -37px;
    background-color: #F3F3F3;
}

.four_crnrs_search {
    background: url("/App_Themes/Skin_7/images/search.jpg") no-repeat left;

    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
    width: 68px;
    height: 42px;
    line-height: 29px;
    border: none;
    cursor: pointer;
}

    .four_crnrs_search:hover {
        background: url("/App_Themes/Skin_7/images/search_rollover.jpg") no-repeat left;

        color: #ffffff;
        font-weight: normal;
        font-size: 11px;
        width: 68px;
        height: 42px;
        line-height: 29px;
        border: none;
        cursor: pointer;
    }

.ShopHeadingSearch {
    color: #000000;

    font-size: 28px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 5px;
}

    .ShopHeadingSearch b {
        color: #E20D15;

        font-size: 28px;
        font-weight: bold;
    }

.LiveSearchBox {
    border: 1px solid #E20D15;
}

.searchBox {
    height: 1.5em;
    width: 154px;
    border: 1px solid rgb(226,13,21);
    margin-top: 0.7em;
}

/* Promotion Page */
.promotiontable {
}

.promotioncodeentrycell {
}

.promotioncodeentrydescription {
}

.promotioncodeentrybox {
}

.promotioncodeentryvalidation {
}

.promotioncodeentrybutton {
}

.promotioncodeentryerror {
}

.promotionlist {
}

.promotionlistitem {
    padding-bottom: 5px;
}

.promotionlistitemcode {
}

.promotionlistitemdescription {
}

.promotioncodeentrylink {
}

.promotionreason {
    font-size: 8pt;
}

/* Promotion Page */

.promotionCallToAction {
    background: none repeat scroll 0 0 transparent;
    color: red;
    font-weight: bold;
    padding: 3px;
}

.remembershipping {
    color: red;
    font-size: 15.5px;
}

#OPLeftCol .active .checkoutHeader {
    color: #4959a7 !important;
    background: url(/OPCControls/images/arrows_blue.png) no-repeat;
    background-position: 0px 5px;
    padding-left: 25px;
}

#OPLeftCol .active .Over13box {
    background: url(/OPCControls/images/arrows_blue.png) no-repeat;
    background-position: 0px 5px;
    padding-left: 25px;
    padding-top: 0;
    border-bottom: none;
    margin-bottom: 0;
    margin-left: 0;
    padding-bottom: 0;
    line-height: normal;
}

#OPLeftCol .active {
    color: #4959a7 !important;
}

.ErrorBox {
    color: red !important;
    background: #FAEBE7 !important;
    border: 1px solid red !important;
}

.OPButtonPlain {
    color: #4959a7 !important;
}

.OPLoginPlain {
    color: #4959a7 !important;
    font-size: 12px;
    line-height: 18px;
}

.useThisAddress {
    color: #4959a7 !important;
}

    .useThisAddress:hover {
        color: red !important;
    }

#ShippingAddressBookBox {
    border-color: #4959a7 !important;
}

    #ShippingAddressBookBox h2 {
        color: #4959a7 !important;
    }

#BillingAddressBookBox {
    border-color: #4959a7 !important;
}

    #BillingAddressBookBox h2 {
        color: #4959a7 !important;
    }

#privateSignin .txtinput {
    /*border: 1px solid #C3C3C3;*/
    border: none;
    color: #A3A3A3;

    font-size: 16px;
    width: 411px;
    padding: 8px 8px 8px 8px;
}

#privateLostPassword .txtinput {
    /*border: 1px solid #C3C3C3;*/
    border: none;
    color: #A3A3A3;

    font-size: 16px;
    width: 411px;
    padding: 8px 8px 8px 8px;
}

table.PrescriptionOptionList tr label {
    padding-left: 30px;
    display: block;
}

table.PrescriptionOptionList td {
    padding-top: 10px;
}

.PrescriptionOption label {
    padding-left: 30px;
    display: block;
}

.PrescriptionListLabel {
    font-weight: bold;
}

.PrescriptionOrderNumber {
    font-size: 14px;
    font-weight: bold;
}

.DoctorsInformationTable {
}

table.PrescriptionOptionList label {
    padding-left: 25px;
    display: block;
    margin-top: -13px;
}

table.PrescriptionOptionList input {
    display: block;
}



/*NEW CSS*/

#main_div {
    background-color: white;
    width: 974px;
    margin: 0 auto;
}

#main_inside_div {
    background-color: white;
    width: 904px;
    margin: 0 auto;
}

#top_div {
    padding-top: 10px;
    width: 904px;
    height: 60px;
    clear: both;
}

.top_left {
    float: left;
    width: 36%;
}

.top_right {
    float: right;
    clear: right;
    padding-top: 25px;
}

#head_div {
    margin-top: 10px;
    width: 904px;
    overflow: hidden;
    clear: both;
}



.cat_nav_div {
    float: left;
}

.free_shiping_bar {
    width: 904px;
    clear: both;
}

.terms_bar {
    clear: both;
}

/*#endregion*/

/*#region Html Elements */

img {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    border: none;
}

/*#endregion */

/*#region General Layout classes */

.wrapper {
    width: 974px;
    margin: 0 auto;
    background-color: white;
}

.wrapper_inside {
    width: 904px;
    margin: 0 auto;
    text-align: left;
}

.top {
    height: 61px;
}

.left_top {
    padding-top: 5px;
    width: 360px;
    float: left;
}

.left_top_new {
    padding-top: 12px;
    width: 500px;
    float: left;
}
.affiliate_panel {
    padding-top: 1px;
    margin: 0px;
    height: 25px;
}

.affiliate_left {
    width: 100px;
    float: left;
    text-align: right;
    padding-right: 15px;
    font-size: 12px;
    line-height: 20px;
}

.affiliate_left_new {
    width: 100px;
    text-align: right;
    padding-right: 1px;
    font-size: 12px;
    line-height: 20px;
}

.affiliate_right {
    margin: 0px;
    padding: 0px;
}

    .affiliate_right select {
        width: 170px;
        border: 1px solid #C3C3C3;
    }

.affiliate_right_new {
    margin: 0px;
    padding: 0px;
    padding-right: 5px;
}

    .affiliate_right_new select {
        width: 136px;
        padding-right:5px;
    }


.right_top {
    padding-top: 12px;
    width: 544px;
    float: right;
    text-align: right;
}

.right_top_new {
    padding-top: 12px;
    width: 404px;
    float: right;
    text-align: right;
}


.md_right_top {
    padding-top: 6px;
    float: right;
    text-align: right;
    clear: right;
    padding-right: 10px;
    height: 55px;
}



.logo_div {
    width: 185px;
    height: 170px;
    line-height: 170px;
    float: left;
    text-align: center;
}

    .logo_div a:link {
        line-height: 170px;
        vertical-align: middle;
        display: inline-block;
    }

/*#endregion */

.free_ship {
    /*min-width: 320px;*/
    height: 42px;
    line-height: 42px;
    vertical-align: middle;

    font-size: 15.5px;
    font-weight: normal;
    color: rgb(102,102,102);
    text-align: center;
}

.free_ship_alt {
    /*min-width: 320px;*/
    width: 50%;
    float: left;
    height: 42px;
    line-height: 42px;
    vertical-align: middle;

    font-size: 15.5px;
    font-weight: normal;
    color: rgb(102,102,102);
    text-align: center;
}

.search_pnl_full {
    width: 50%;
    float: right;
    height: 42px;
    line-height: 42px;
    clear: right;
    vertical-align: middle;
    text-align: center;
}

.gray_line {
    width: 100%;
    height: 1px;
    background-color: gray;
    clear: both;
}

.horizontal_line {
    background: url("/App_Themes/Skin_7/images/horizontal_line.gif") repeat-x;
    height: 1px;
    clear: both;
}

.content_left {
    width: 70%;
    float: left;
    text-align: left;
    overflow: hidden;
    padding-top: 15px;
}

.content_right {
    width: 30%;
    float: left;
    padding-bottom: 10px;
    overflow: hidden;
}


.help_text {

    font-size: 17px;
    font-weight: normal;
    color: #858585;
    text-align: left;
    padding-left: 16px;
    height: 90px;
    vertical-align: middle;
}

.help_you {

    font-size: 40px;
    font-weight: normal;
    color: #000000;
    text-align: left;
    vertical-align: bottom;
}

.logedin {
    float: right;
    clear: both;
    height: 25px;
    width: 100%;
    vertical-align: bottom;
}

.md_logedin {
    float: right;
    clear: both;
    height: 20px;
    line-height: 20px;
    vertical-align: bottom;
    color: lightgray;
}

    .md_logedin a:link, .md_logedin a:hover, .md_logedin a:visited {
        color: white;
    }

    .md_logedin a:hover {
        color: lightgray;
    }


.img_description {
    width: 210px;
    float: left;
}

.search_bar {
    height: 84px;

    font-size: 24px;
    color: #E20D15;
    font-weight: bold;
    line-height: 27px;
    float: left;
    text-align: center;
}

.prescription_panel {
    min-height: 189px;
    padding-top: 20px;
}

.special_panel {
    width: 100%;
    clear: both;
    margin-bottom: 10px;
}

.special_holder {
    margin: 10px; /*10px 10px 10px 10px; top right bottom left*/
    display: block;
    border: 2px solid black;
    background-color: #F3F3F3;
}

.special_block {
    padding: 10px;
    display: block;
}







.print_email {

    font-size: 11px;
    font-weight: normal;
    color: #666666;
    line-height: 26px;
    text-align: right;
    float: right;
    clear: both;
}

    .print_email a {

        font-size: 11px;
        font-weight: normal;
        color: #333333;
        text-align: center;
        height: 26px;
        line-height: 26px;
        text-decoration: none;
        padding-left: 12px;
        padding-right: 12px;
        vertical-align: middle;
    }

        .print_email a:hover {

            font-size: 11px;
            font-weight: normal;
            color: #FF0000;
            text-align: center;
            height: 26px;
            line-height: 26px;
            text-decoration: underline;
        }

.print {
    height: 26px;
    float: right;
}

.email {
    height: 26px;
    float: right;
}

.uk_regulations {
    height: 14px;
    width: 904px;
    text-align: center;
    clear: both;
}


/*#endregion */

/*#region footer*/

.footer_top {
    width: 904px;
    height: 36px;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 5px;
}

    .footer_top ul {
        list-style-type: none;
        padding-left: 50px;
    }

        .footer_top ul li {
            float: left;
            display: block;
            vertical-align: middle;

            font-size: 13px;
            font-weight: normal;
            color: #333333;
            text-align: center;
            height: 26px;
            line-height: 26px;
        }

            .footer_top ul li a:link, .footer_top ul li a:active, .footer_top ul li a:visited, .footer_top ul li a:hover {
                display: block;

                font-size: 13px;
                font-weight: normal;
                color: #333333;
                text-align: center;
                height: 26px;
                line-height: 26px;
                text-decoration: none;
            }

            .footer_top ul li a:hover {

                font-size: 13px;
                font-weight: normal;
                color: #FF0000;
                text-align: center;
                height: 26px;
                line-height: 26px;
                text-decoration: underline;
            }

.bar_alt {
    width: 19px;
    height: 26px;
    background-image: url("/App_Themes/Skin_7/images/sep.gif");
    background-repeat: no-repeat;
    background-position: center 7px;
}

/*#endregion footer*/

/*#region Common Classes */

.middle_container {
    display: block;
    vertical-align: middle;
}

.hide {
    height: 0px;
    width: 0px;
    visibility: hidden;
}

.hide_info {
    height: 0px;
    width: 0px;
    visibility: hidden;
    display: none;
}


.clear {
    clear: both;
}

.spacer {
    height: 25px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.7;
    z-index: 1;
    display: none;
}

.ui-dialog { z-index: 1000 !important ;}
.ui-front { z-index: 1000 !important; }

.ui-dialog .ui-dialog-titlebar-close{
    /*display: none !important;*/
    /*content: none !important;*/
}

/*.ui-dialog .ui-dialog-titlebar-close span {
    margin: 0px !important;
}*/

button.ui-dialog-titlebar-close {
    display: none !important;
}

button.ui-button {
    padding: 5px !important;
}

.float_right {
    float: right;
}

.rad_search {
    width: 150px;
}

.pad_top {
    padding-top: 10px;
}

.pad_bottom {
    padding-bottom: 10px;
}

.pad_LR {
    padding-left: 10px;
    padding-right: 10px;
}

.clear_10 {
    height: 10px;
    clear: both;
}

.ca_createbtn {
    padding-bottom: 10px;
}

.clear {
    clear: both;
}

.display_none {
    display: none;
    height: 0px;
    width: 0px;
}




/*#endregion */

/*#region Oldhtml*/
/*Styles from Old html*/

.login {
    float: right;
}

.columns {

    font-size: 13px;
    /*font-weight:bold;*/
    color: #333333;
    line-height: 19.5px;
}

.copyright {

    font-size: 11px;
    font-weight: normal;
    color: #333333;
    text-align: center;
}

    .copyright a {

        font-size: 11px;
        font-weight: normal;
        color: #333333;
        text-align: center;
        text-decoration: none;
    }

        .copyright a:hover {

            font-size: 11px;
            font-weight: normal;
            color: #FF0000;
            text-align: center;
            text-decoration: underline;
        }



.seals {
    color: #858585;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    font-weight: lighter;
    line-height: 30px;
}

.topictooltip {
    cursor: default;
    margin: 0px;
    padding-left: 20px;
}

.vertical_line {
    background: url("/App_Themes/Skin_7/images/vertical_line.gif") no-repeat;
    width: 22px;
}

.user_text {

    color: #e20d15;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
}

    .user_text a {

        color: #666666;
        font-size: 13px;
        font-weight: bold;
        line-height: 20px;
        padding-top: 10px;
    }

        .user_text a:hover {

            color: #E20D15;
            font-size: 13px;
            font-weight: bold;
        }

.shopping_cart {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: right;

    color: #666;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
}

.shopping_cart_text {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: right;

    color: #666;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
}

    .shopping_cart_text a {

        color: #666666;
        font-size: 11px;
        font-weight: bold;
        line-height: 20px;
    }

.md_shopping_cart_text {
    margin: 0px;
    padding: 0px;
    float: right;

    color: #FFFFFF;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
    height: 20px;
    clear: both;
}

    .md_shopping_cart_text a {

        color: #FFFFFF;
        font-size: 11px;
        font-weight: bold;
        line-height: 20px;
    }


.shopping_cart a {

    color: #666666;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
}

.order_for_home {

    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    color: #000000;
    line-height: 20px;
}

.topic_text {

    color: #666666;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
}

.ca_accounthead {
    font-size: 22px;
    color: #333333;
    font-weight: bold;

    padding: 0px;
}

.catopic_text {

    color: #666666;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
}


.topic_text a {

    color: #666666;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    text-decoration: none;
}

    .topic_text a:hover {

        color: #e20d15;
        font-size: 13px;
        line-height: 20px;
        font-weight: normal;
        text-decoration: none;
    }

.caHeading {
    color: #000000;

    font-size: 28px;
    font-weight: bold;
    white-space: nowrap;
}

.tophead {
    color: #3F3F3F;

    font-size: 15px;
    font-weight: bold;
}

.labelhead {
    color: #333333;

    font-size: 12px;
    font-weight: bold;
    width: 24%;
}

/*#endregion Oldhtml*/

/*#region Common classes device specific style */

/*Format className_valueDesktop_valueTablet_valueMobile */

.ShopHeading {
    margin: 6px 0 0;
    color: #000000;

    font-size: 28px;
    font-weight: bold;
    white-space: normal;
}

.tophead {
    color: #3F3F3F;

    font-size: 15px;
    font-weight: bold;
}

/*Format propertyName_valueDesktop_valueTablet_valueMobile */
.width_50_50_80 {
    width: 50%;
}

.width_80_100_100 {
    width: 80%;
}

.pad_LR_0_20_20 {
    padding-left: 0px;
    padding-right: 0px;
}

.pad_0_10_10 {
    padding: 0px 0px 0px 0px;
}

.maxWidth_50_80_80 {
    max-width: 50%;
}

/*disable all Screens by Default*/
.scn_full,
.scn_md,
.scn_sm,
.scn_full_md,
.scn_md_sm,
.scn_inline_full,
.scn_inline_md,
.scn_inline_sm,
.scn_inline_full_md,
.scn_inline_md_sm {
    display: none;
}

.scn_full {
    display: block;
}

.scn_inline_full {
    display: inline;
}



/*#endregion */

/*#region product page */
.prod_main_img {
    float: left;
    width: 50%;
}

.prod_main_detail {
    float: left;
    width: 50%;
}

.prod_right_top {
    width: 100%;
}



.prod_right_title {
    float: left;
    width: 70%;
    color: #000000;

    font-size: 22px;
}

    .prod_right_title H1 {
        color: #000000;

        font-size: 22px;
        font-weight: bold;
        margin: 0px;
    }

.prod_generic {
    float: right;
    padding-right: 5px;
}

.prod_related {
    height: 190px;
    float: left;
    width: 33%;
}

.prod_variant_title {
    width: 45%;
    float: left;
    color: #000;
    font-weight: bold;
}

.prod_variant_price {
    width: 20%;
    float: left;
}

.prod_variant_seperator {
    width: 10%;
    float: left;
}

.prod_variant_addtocartbtn {
    width: 20%;
    float: right;
}

.prod_variant_item_seperator {
    width: 100%;
    clear: both;
    padding-bottom: 15px;
}

.prod_item_price {
    clear: both;
}

.prod_item_price_head {
    clear: both;
}

.prod_item_price_head_title {
    float: left;
}

.prod_item_price_head_shipping {
    text-align: right;
    float: right;
    clear: right;
}

.prod_strength {
    clear: both;
}

/*#endregion*/

/*#region Category Page */
.display_same {
    width: 50%;
    float: left;
    min-height: 100px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.cat_img {
    width: 50%;
    float: left;
}

div.cat_img a img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.cat_details {
    width: 50%;
    float: left;
}

/*.onsale
{
    color: red;
    text-transform:uppercase;
    font-weight:bold;
    display:inline-block;
    height:20px;
    line-height:20px;
    width: 70px;
    margin-bottom:5px;
    background-color: lightgray;
}*/

.onsale {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    background-color: red;
    text-align: right;
    padding: 0px 10px;
}

.onsale_minicart {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    height: 15px;
    line-height: 15px;
    margin-bottom: 5px;
    background-color: red;
    text-align: right;
    padding: 0px 10px;
    font-size: 10px;
    margin-left: 5px;
}

div.cart_item_alt .onsale {
    float: right;
}

/*div.cat_details .onsale{
    float:none;
}*/

/*div.search_text .onsale{
    float:left;
    display:block;
}*/

span.onsale img {
}
/*#endregion */

/*#region Search */

.search_results {
    clear: both;
}

.search_img {
    width: 25%;
    float: left;
}

.search_text {
    width: 50%;
    float: left;
}

.search_link {
    width: 25%;
    float: left;
}

div.search_pnl_full .searchinput {
    width: 300px;
    height: 18px;
    margin: 10px 0 0 20px;
    border: 1px solid;
    padding: 10px 10px 10px 10px;
}

div.search_pnl_full .RadSearchBox .rsbInner {
    width: 260px;
    height: 20px;
    margin: 0 0 0 0;
    border: 1px dotted #e20d15;
    padding: 5px 5px 5px 5px;
}

div.search_pnl_full .RadSearchBox .rsbInput {
    font: 15px Arial, Helvetica, sans-serif;
    color: #515151;
}

div.search_pnl_full .RadSearchBoxPopup {
    border: 2px solid #F38800;
}


.search_bar {

    font-size: 24px;
    color: #E20D15;
    font-weight: bold;
    line-height: 27px;
}

    .search_bar .RadSearchBox {
        width: 160px;
    }

        .search_bar .RadSearchBox .rsbInner {
            width: 160px;
            height: 25px;
            border: 2px solid #e20d15;
            padding: 0 0 0 5px;
        }

        .search_bar .RadSearchBox .rsbInput {
            font: 12px Arial, Helvetica, sans-serif, bold;
            color: #515151;
            padding: 5px 0 5px 0;
        }

        .search_bar .RadSearchBox .rsbButton {
            margin-top: 3px;
        }

/*#endregion */

/*#region Shopping Cart */

.cart_items {
    clear: both;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
}

.car_item_img {
    width: 20%;
    float: left;
    clear: left;
    margin-top: 10px;
}

.cart_item_alt {
    width: 80%;
    float: left;
    clear: right;
}

.cart_item_name {
    width: 70%;
    float: left;
    clear: left;
}

.cart_item_removebtn {
    width: 33%;
    float: right;
    clear: right;
    text-align: right;
    margin-bottom: 8px;
}

.cart_item_breif {
    width: 30%;
    float: left;
    font-weight: normal;
}

.cart_item_quantity {
    width: 33%;
    float: left;
    text-align: right;
    font-weight: normal;
}

.cart_item_subtotal {
    width: 33%;
    float: right;
    text-align: right;
}

.cart_update_btn {
    clear: both;
}


/*#endregion */

.facebook_block {
    clear: both;
    text-align: center;
}

.account_bill_add {
    float: left;
    width: 40%;
}

.account_ship_add {
    float: right;
    width: 40%;
}

.primaryAddress {
    float: right;
    width: 70%;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.address_left {
    float: left;
    width: 30%;
}

.address_right {
    float: left;
}
/* mobile phone scn_sm  Between 320px - 640px */
@media screen and (max-width: 640px) {

    /*#region Common classes device specific style */

    /*#region bread crumb specific style */

    /*#region contactus header specific style */
    .contactus_header_text {
        width: 100%;
        margin: 0px;
        padding: 0px;
        float: right;

        color: #666;
        font-size: 11px;
        line-height: 15px;
        font-weight: bold;
    }

    .contactus_header_text_right {
        margin: 0px;
        float: right;
        padding-top: 0px;
    }


    .contactus_header_text a {
        color: #666;
        font-size: 11px;
    }

        .contactus_header_text a:hover {

            color: #e20d15;
            text-decoration: underline;
        }
    /*#region contactus header specific style */
    /*region contact us schedule specific style*/


    .outerDivContactUsSChedule {
        width: 90%;
    }

    .dayNameContactUsSChedule {
        float: left;
        width: 0%;
        font-weight: bold;
    }

    .startsOnContactUsSChedule {
        float: left;
        width: 50%;
    }

    .endsOnContactUsSChedule {
        float: left;
        width: 50%;
    }

    .innerDivContactUsSChedule {
        width: 100%;
        float: left;
    }

    .innerDivHeaderContactUsSChedule {
        width: 100%;
        float: left;
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
    }

    .dayNameHeaderContactUsSChedule {
        float: left;
        width: 0%;
    }

    .startsOnHeaderContactUsSChedule {
        float: left;
        width: 50%;
    }

    .endsAtHeaderContactUsSChedule {
        float: left;
        width: 50%;
    }


    /*region contact us schedule specific style*/
    /*Format className_valueDesktop_valueTablet_valueMobile */

    .ShopHeading {
        color: #000000;

        font-size: 28px;
        font-weight: bold;
        white-space: normal;
    }

    .tophead {
    }

    .width_50_50_80 {
        width: 80%;
    }

    .width_80_100_100 {
        width: 100%;
    }

    .pad_LR_0_20_20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pad_0_10_10 {
        padding: 10px 10px 10px 10px;
    }

    .maxWidth_50_80_80 {
        max-width: 80%;
    }

    /*Enable all Screens by Default*/
    .scn_full, .scn_md, .scn_full_md, .scn_inline_full, .scn_inline_md, .scn_inline_full_md {
        display: none;
    }

    .scn_sm, .scn_md_sm {
        display: block;
    }

    .scn_inline_md_sm, .scn_inline_sm {
        display: inline;
    }
    /*#endregion */



    body {
        background-color: white;
    }

    .wrapper {
        width: 100%;
        margin: 0 auto;
        background-color: white;
        min-width: 320px;
    }

    .wrapper_inside {
        width: 100%;
        margin: 0 auto;
        text-align: left;

        color: #666666;
        font-size: 13px;
        font-weight: normal;
    }

    .sm_top {
        margin-top: 45px;
        height: 120px;
        /*min-width: 320px;*/
    }

    .sm_left_top {
        padding-left: 25px;
        float: left;
    }

    .sm_right_top {
        float: right;
        padding-right: 10px;
        padding-top: 30px;
        /*width: 200px;*/
        overflow: hidden;
        background-image: url('/PH%20Images/mobile_web/mob_contact_header.png');
        background-position: left -5px bottom 15px;
        background-repeat: no-repeat;
    }

    .sm_right_top_contact {
        text-align: right;
    }

        .sm_right_top_contact a:link, .sm_right_top_contact a:hover, .sm_right_top_contact a:visited, .sm_right_top_contact a:active {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
        }

    .sm_logo {
        margin: 0px;
        padding-top: 7px;
        vertical-align: bottom;
        height: 110px;
    }

    .img_sm_logo {
        width: 75px;
    }

    .sm_categories {
        margin-left: 10px;
        margin-right: 10px;
        clear: both;
        background-color: black;
    }

    .sm_categories_head {
        width: 100%;
        height: 40px;
        background-image: url('/PH%20Images/mobile_web/category_down.png');
        background-position: 95% center;
        background-repeat: no-repeat;
        color: white;
        /*background-color: darkgray;*/
    }

        .sm_categories_head a:link, .sm_categories_head a:hover, .sm_categories_head a:visited, .sm_categories_head a:active {
            width: 100%;
            height: 40px;
            line-height: 40px;
            vertical-align: middle;
            color: white;
            font-weight: bold;
            padding-left: 10px;
            display: block;
        }

    .sm_categories_body {
        width: 100%;
        margin: 0px;
        padding: 0px;
        width: 100%;
        clear: both;
        display: none;
    }








    .sm_search {
        width: 20px;
        float: right;
        padding-top: 35px;
        text-align: right;
    }

    .sm_search_panel {
        width: 100%;
        clear: both;
        height: 40px;
        display: none;
    }

    .sm_search_panel_inside {
        width: 80%;
        margin: 0 auto;
        padding: 0px;
        height: 40px;
        vertical-align: middle;
        line-height: 40px;
    }


        .sm_search_panel_inside .searchdiv {
            clear: both;
            margin-left: 10px;
            margin-right: 10px;
            /*overflow:hidden;*/
        }

            .sm_search_panel_inside .searchdiv .searchinput {
                width: 90% !important;
                height: 18px;
                /*margin: 10px 0 0 20px;*/
                border: 1px solid;
                /*padding: 10px 10px 10px 10px;*/
            }

            .sm_search_panel_inside .searchdiv .RadSearchBox {
                margin: 0px;
                width: 100% !important;
            }

                .sm_search_panel_inside .searchdiv .RadSearchBox .rsbInner {
                    /*width: 100% !important;*/
                    height: 20px;
                    margin: 0 0 0 0;
                    border: 1px solid #e20d15;
                    padding: 5px 0px 5px 0px;
                }

                .sm_search_panel_inside .searchdiv .RadSearchBox .rsbInput {
                    font: 15px Arial, Helvetica, sans-serif;
                    color: #515151;
                }

            .sm_search_panel_inside .searchdiv .RadSearchBoxPopup {
                border: 2px solid #F38800;
            }


    sm_search_outside {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .sm_search_panel_outside {
        margin: 0 auto;
        padding: 0px;
        height: 40px;
        /*vertical-align: middle;
        line-height: 40px;*/
    }

        .sm_search_panel_outside .searchdiv {
            clear: both;
            margin-left: 10px;
            margin-right: 10px;
            /*overflow:hidden;*/
        }

            .sm_search_panel_outside .searchdiv .searchinput {
                width: 90% !important;
                height: 18px;
                /*margin: 10px 0 0 20px;*/
                border: 1px solid;
                /*padding: 10px 10px 10px 10px;*/
            }

            .sm_search_panel_outside .searchdiv .RadSearchBox {
                margin: 0px;
                width: 100% !important;
            }

                .sm_search_panel_outside .searchdiv .RadSearchBox .rsbInner {
                    /*width: 100% !important;*/
                    height: 20px;
                    margin: 0 0 0 0;
                    border: 1px solid #e20d15;
                    padding: 5px 0px 5px 0px;
                }

                .sm_search_panel_outside .searchdiv .RadSearchBox .rsbInput {
                    font: 15px Arial, Helvetica, sans-serif;
                    color: #515151;
                }

            .sm_search_panel_outside .searchdiv .RadSearchBoxPopup {
                border: 2px solid #F38800;
            }

    .sm_cart {
        width: 50px;
        float: right;
        padding-top: 30px;
        text-align: right;
    }

    .sm_login {
        width: 50px;
        float: right;
        padding-top: 30px;
        text-align: right;
    }

    .sm_menu {
        padding-top: 20px;
        padding-left: 20px;
        width: 50px;
        float: right;
        text-align: right;
    }

    .sm_menu_panel {
        margin: 0px;
        padding: 0px;
        height: 50px;
        width: 100%;
        display: none;
    }

        .sm_menu_panel ul {
            margin: 0px;
            padding: 0px;
            display: block;
            width: 100%;
            list-style-type: none;
        }

            .sm_menu_panel ul li {
                width: 100%;
                float: left;
                display: block;
                font-size: 14px;
                text-align: center;
                vertical-align: middle;
                line-height: 30px;
                height: 30px;
                background-color: white;
            }

                .sm_menu_panel ul li:nth-child(even) {
                    background-color: lightgray;
                }

                .sm_menu_panel ul li a:link, .sm_menu_panel ul li a:hover, .sm_menu_panel ul li a:visited, .sm_menu_panel ul li a:active {
                    color: black;
                    display: block;
                    line-height: 30px;
                    vertical-align: middle;
                }

                .sm_menu_panel ul li a:hover {
                    color: red;
                }

    .sm_grayline {
        height: 1px;
        width: 100%;
        background-color: black;
    }

    /*nav {
        width: 719px;
        float: right;
    }*/

    /*#endregion */

    .help_you {

        font-size: 20px;
        font-weight: normal;
        color: #000;
        text-align: left;
        vertical-align: bottom;
    }

    .help_text {
        width: 300px;
        margin: 0 auto;

        font-size: 11px;
        font-weight: normal;
        color: #858585;
        text-align: center;
        height: 50px;
        vertical-align: middle;
    }

    .content_left {
        width: 100%;
        clear: both;
    }

    .content_left_alt {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content_right {
        width: 100%;
        clear: both;
        padding-bottom: 10px;
    }

    .prescription_panel {
        min-height: 189px;
        padding-top: 20px;
        padding-left: 20px;
    }

    .img_description {
        /*min-width: 300px;*/
        clear: both;
        padding-left: 20px;
    }

    /*.specials {
        padding-top: 10px;
        padding-left: 20px;
    }*/
    .sd_specials {
        padding-top: 10px;
        margin: 0 auto;
        clear: both;
    }

    .sd_prescription {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        margin: 0 auto;
        min-width: 220px;
        min-height: 102px;
        text-align: center;
        clear: both;
        border-bottom: 1px dashed black;
    }

    .sd_seals {
        padding-top: 10px;
        margin: 0 auto;
        min-width: 220px;
        min-height: 102px;
        width: 100%;
        text-align: center;
        clear: both;
        border-bottom: 1px dashed black;
    }


    /*#region Smart Device Border blocks */

    .sd_panel {
        width: 100%;
        clear: both;
    }

    .sd_left_holder {
        margin: 10px; /*top right bottom left*/
        border: 2px solid black;
        /*min-width: 220px;
        min-height: 102px;*/
    }


    .sd_left_block {
        padding: 10px;
        display: block;
        background-color: #F3F3F3;
    }


    .sd_middle_holder {
        margin: 10px; /*10px 10px 10px 10px; top right bottom left*/
        display: block;
        border: 2px solid black;
    }

    .sd_middle_block {
        padding: 10px;
        display: block;
        /*background-color: #F3F3F3*/;
    }

    .sd_right_holder {
        margin: 0px 10px 10px 10px; /*top right bottom left*/
        /*border: 2px solid black;*/
    }

    .sd_right_block {
        text-align: center;
        /*padding: 10px;
        display: block;*/
        /*background-color: #F3F3F3;*/
    }

    /*#region Special Panel */

    .sd_special_panel {
        clear: both;
        width: 100%;
    }

    .sd_special_holder {
        margin: 0px 10px 10px 10px; /*10px 10px 10px 10px; top right bottom left*/
        display: block;
        /*border: 2px solid black;*/
    }

    .sd_special_block {
        /*padding: 10px;
        display: block;*/
        text-align: center;
    }

    .prod_img {
        min-width: 320px;
        max-width: 640px;
        height: auto;
        width: 100%;
    }

        .prod_img img {
            max-width: 80%;
            max-height: 80%;
        }

    .ca_accounthead {
        font-size: 16px;
    }

    .caHeading {
        font-size: 20px;
    }

    .ca_createbtn {
        padding-bottom: 30px;
    }


    /*#region product page */
    .prod_main_img {
        clear: both;
        padding-left: 10px;
        padding-right: 10px;
    }

    .prod_main_detail {
        width: 100%;
        clear: both;
        padding-left: 10px;
        padding-right: 10px;
    }

    .prod_right_top {
        width: 100%;
    }

    .prod_right_title {
        float: left;
        width: 75%;
    }

    div.prod_right_title h1 {
        font-size: 16px;
    }

    .prod_related {
        float: left;
        width: 50%;
    }

    .prod_variant_title {
        width: 60%;
        float: left;
        /*color: #000;
        font-weight: bold;*/
    }

    .prod_variant_price {
        width: 0%;
        float: left;
    }

    .prod_variant_seperator {
        width: 0%;
        float: left;
    }

    .prod_variant_addtocartbtn {
        width: 40%;
        float: right;
        min-width: 110px;
    }
    /*#endregion*/

    /*#region Category Page */

    .cat_img {
        width: 100%;
        clear: both;
        text-align: center;
    }

    div.cat_img img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .cat_details {
        width: 100%;
        clear: both;
        text-align: center;
    }


    /*#endregion */

    /*#region Search */

    .search_results {
        clear: both;
        text-align: center;
    }

    .search_img {
        width: 100%;
        float: left;
        text-align: center;
    }

    .search_text {
        width: 100%;
        float: left;
        text-align: center;
    }

    .search_link {
        width: 100%;
        float: left;
        text-align: center;
    }

    .ShopHeadingSearch {
        font-size: 20px;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 5px;
    }

        .ShopHeadingSearch b {
            font-size: 20px;
        }

    .view {
        background: none;
        padding-left: 10px;
        padding-right: 50px;
        padding-top: 5px;
        vertical-align: bottom;
    }



    /*#endregion */

    /*#region Shopping Cart */

    .cart_items {
        clear: both;
        margin: 0px;
        padding: 0px;
        text-align: center;
    }

    .car_item_img {
        width: 20%;
        float: left;
        vertical-align: middle;
    }

    .cart_item_alt {
        width: 80%;
        float: right;
        text-align: right;
    }

    .cart_item_name {
        width: 100%;
        float: right;
    }

    .cart_item_removebtn {
        width: 100%;
        float: right;
        clear: right;
    }

    .cart_item_breif {
        width: 100%;
        float: right;
    }

    .cart_item_quantity {
        width: 100%;
        float: right;
    }

    .cart_item_subtotal {
        width: 100%;
        float: right;
    }

    .cart_update_btn {
        clear: both;
    }

    /*#endregion */

    .affiliate_scn {
        font-size: 12px;
        font-weight: bold;
    }

    .free_ship_alt {
        /*min-width: 320px;*/
        width: 100%;
        clear: both;
        height: 42px;
        line-height: 42px;
        vertical-align: middle;

        font-size: 15.5px;
        font-weight: normal;
        color: rgb(102,102,102);
        text-align: center;
    }


    .account_bill_add {
        float: left;
        width: 100%;
    }

    .account_ship_add {
        float: right;
        width: 100%;
    }

    .sd_feedback_link {
        clear: both;
        margin: 5px auto;
        border: solid 2px #000000;
        width: 220px;
        height: 50px;
        border-radius: 10px;
        background-color: #163a94;
        background-image: url('/PH%20Images/sd_feedback_hand_icon.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        text-align: center;
    }

        .sd_feedback_link a:link, .sd_feedback_link a:active, .sd_feedback_link a:hover, .sd_feedback_link a:visited {
            display: block;
            font-size: 20px;
            width: 100%;
            padding-top: 5px;
            color: white;
        }

    .sd_footer {
        /*padding-left:10px;
        padding-right:10px;*/
        margin: 0 auto;
        padding-top: 5px;
        padding-bottom: 15px;
        margin-top: 5px;
        clear: both;
        /*line-height: 20px;*/
        background-color: #3F3F3F;
        color: white;
        text-align: center;
    }

        .sd_footer a:link, .sd_footer a:hover, .sd_footer a:visited, .sd_footer a:active {
            color: white;
        }

        .sd_footer a:horizontal {
            color: red;
        }

    .sd_footer_top {
        margin: 0 auto;
    }

    .sd_footer_middle {
        clear: both;
        padding-top: 10px;
    }

    .sd_cart_discounts {
        width: 100%;
        clear: both;
    }

    .sd_discounts {
        display: block;
        text-align: left;
    }

    .primaryAddress {
        float: none !important;
        width: 100%;
        text-align: center !important;
        clear: both;
    }

    .address_left {
        float: left;
        width: 50%;
        clear: right;
    }

    .address_right {
        float: right;
        clear: right;
    }

    .orderconfirmation_block {
        padding-left: 10px;
    }
}

/* mobile bigger screen  / Tablets / IPads scn_md Between 640px - 974px*/
@media screen and (min-width: 640px) and (max-width: 974px) {

    /*#region bread crumb specific style */

    /*#region contactus header specific style */
    .contactus_header_text {
        width: 100%;
        margin: 0px;
        padding: 0px;
        float: right;

        color: #666;
        font-size: 11px;
        line-height: 15px;
        font-weight: bold;
    }

    .contactus_header_text_right {
        margin: 0px;
        float: right;
        padding-top: 0px;
    }

    .contactus_header_text_left {
        margin: 0px;
        padding-top: 0px;
    }


    .contactus_header_text a {
        color: #666;
        font-size: 11px;
    }

        .contactus_header_text a:hover {

            color: #e20d15;
            text-decoration: underline;
        }
    /*#region contactus header specific style */

    /*region contact us schedule specific style*/


    .outerDivContactUsSChedule {
        width: 60%;
    }

    .dayNameContactUsSChedule {
        float: left;
        width: 0%;
        font-weight: bold;
    }

    .startsOnContactUsSChedule {
        float: left;
        width: 50%;
    }

    .endsOnContactUsSChedule {
        float: left;
        width: 50%;
    }

    .innerDivContactUsSChedule {
        width: 100%;
        float: left;
        margin-bottom:10px;
    }

    .innerDivHeaderContactUsSChedule {
        width: 100%;
        float: left;
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
    }

    .dayNameHeaderContactUsSChedule {
        float: left;
        width: 0%;
    }

    .startsOnHeaderContactUsSChedule {
        float: left;
        width: 50%;
    }

    .endsAtHeaderContactUsSChedule {
        float: left;
        width: 50%;
    }

    .hrSolidLineContactUsSChedule {
        width: 100%;
        float: left;
        background: url("/App_Themes/Skin_7/images/horizontal_solid_line.gif") no-repeat;
        height: 1px;
        margin-bottom: 10px;
    }

    .hrDottedLineContactUsSChedule {
        width: 100%;
        float: left;
        background: url("/App_Themes/Skin_7/images/horizontal_line.gif") repeat-x;
        height: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }



    /*region contact us schedule specific style*/

    /*#region Common classes device specific style */
    .ShopHeading {
        color: #000000;

        font-size: 28px;
        font-weight: bold;
        white-space: normal;
    }

    .tophead {
        color: #3F3F3F;

        font-size: 15px;
        font-weight: bold;
    }

    /*Format propertyName_valueDesktop_valueTablet_valueMobile */
    .width_50_50_80 {
        width: 50%;
    }

    .width_80_100_100 {
        width: 100%;
    }

    .pad_LR_0_20_20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pad_0_10_10 {
        padding: 10px 10px 10px 10px;
    }

    .maxWidth_50_80_80 {
        max-width: 80%;
    }

    /*Set Screen Visibility*/
    .scn_md, .scn_full_md, .scn_md_sm {
        display: block;
    }

    .scn_full, .scn_sm, .scn_inline_full, .scn_inline_sm {
        display: none;
    }

    .scn_inline_md,
    .scn_inline_full_md,
    .scn_inline_md_sm {
        display: inline;
    }

    /*#endregion */

    body {
        background-color: white;
    }

    .wrapper {
        width: 100%;
        margin: 0 auto;
        background-color: white;
        min-width: 640px;
    }

    .wrapper_inside {
        width: 100%;
        margin: 0 auto;
        text-align: left;

        color: #666666;
        font-size: 13px;
        font-weight: normal;
    }


    .help_text {

        font-size: 22px;
        font-weight: normal;
        color: #858585;
        text-align: left;
        padding-left: 16px;
        height: 90px;
        vertical-align: middle;
        clear: both;
        text-align: center;
    }

    .help_you {

        font-size: 45px;
        font-weight: normal;
        color: #000000;
        text-align: left;
        vertical-align: bottom;
    }

    .md_head_top {
        width: 100%;
        background-color: black;
        height: 60px;
        padding-top: 5px;
        float: left;
    }

    .md_head_top_affiliate {
        float: left;
    }

    .md_head_top_account {
        float: right;
        padding-right: 10px;
    }

    .md_account_icon {
        height: 60px;
        line-height: 60px;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
        float: left;
    }

    .md_account_text {
        float: left;
        color: white;
        padding-left: 5px;
        text-align: center;
        font-size: 10px;
        float: left;
    }


        .md_account_text a:link, .md_account_text a:visited, .md_account_text a:hover {
            color: white;
            font-size: 10px;
        }

        .md_account_text a:hover {
            color: gray;
        }



    .md_head_top_cart {
        float: right;
        padding-right: 10px;
        color: white;
        font-size: 12px;
    }

        .md_head_top_cart a:link, .md_head_top_cart a:visited, .md_head_top_cart a:hover {
            color: white;
            font-size: 10px;
        }

    .md_cart_text {
        height: 60px;
        max-width: 100%;
        width: 180px;
        line-height: 60px;
        color: white;
        margin-left: 10px;
    }

        .md_cart_text a:link, .md_cart_text a:visited, .md_cart_text a:hover {
            color: white;
            font-size: 10px;
        }

        .md_cart_text a:hover {
            color: gray;
        }


    .md_head_top_wishlist {
        float: right;
    }

    .md_head_middle {
        width: 100%;
        min-width: 640px;
        height: 140px;
        /*overflow: hidden;*/
    }

    .md_head_middle_left {
        margin: 0px;
        padding: 0px;
        width: 16%;
        height: 100%;
        float: left;
    }

    .md_logo {
        padding-left: 20px;
        padding-top: 20px;
    }

    .img_md_logo {
        max-width: 100px;
    }

    .md_head_middle_centre {
        width: 65%;
        height: 100%;
        float: left;
    }

    .md_middle_top_nav {
        width: 100%;
        height: 50%;
        float: left;
        line-height: 120px;
        vertical-align: middle;
        text-align: center;
        background-image: url('/PH%20Images/tab_web/md_contact_info.png');
        background-position: center center;
        background-repeat: no-repeat;
    }

    .md_middle_top_contact {
        padding-top: 5px;
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
    }

    .md_login {
        width: 50px;
        float: right;
        padding-top: 30px;
        text-align: right;
    }

    .md_cart {
        width: 50px;
        float: right;
        padding-top: 30px;
        text-align: right;
    }

    .md_middle_top_searchbar {
        width: 100%;
        height: 50%;
        float: left;
    }

    .md_head_bottom {
        width: 100%;
        min-width: 640px;
        height: 140px;
        /*overflow: hidden;*/
    }

    .md_menus_head {
        width: 95%;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        background-color: black;
        background-image: url('/PH%20Images/tab_web/cat_left_arrow.png');
        background-position: 15px center;
        background-repeat: no-repeat;
        color: white;
        margin: 0 auto;
        /*cursor: pointer;
        cursor: hand;*/
        border: 1px solid black;
    }

    .md_cat_panel {
        margin: 0px;
        padding: 0px;
        width: 95%;
        /*display: none;*/
        border: 1px solid black;
        margin: 0 auto;
        height: 260px;
    }

        .md_cat_panel ul {
            /*margin:0px; padding:0px; width:50%; float:left; display:block; background-color:red; height:20px; list-style-type:none;*/
            margin-left: 5%;
            padding: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            display: flexbox;
            width: 25%;
            float: left;
            list-style-type: none;
            height: 240px;
            /*background: url("/App_Themes/Skin_7/images/vertical_line.gif") no-repeat;
            background-position: right center;*/
        }

            .md_cat_panel ul.vertical_line {
                margin: 0px;
                padding: 0px;
                display: flexbox;
                width: 5%;
                float: left;
                list-style-type: none;
                background: url("/App_Themes/Skin_7/images/vertical_line.gif") no-repeat;
                background-position: center center;
                height: 240px;
                /*background-position: right center;*/
            }

            .md_cat_panel ul li {
                width: 100%;
                float: left;
                display: block;
                font-size: 14px;
                text-align: left;
                vertical-align: middle;
                line-height: 30px;
                height: 25px;
                /*background-color: white;*/
            }

                /*.md_cat_panel ul li:nth-child(even) {
                    background-color: lightgray;
                }*/

                .md_cat_panel ul li a:link, .md_cat_panel ul li a:hover, .md_cat_panel ul li a:visited, .md_cat_panel ul li a:active {
                    color: black;
                    display: block;
                    line-height: 30px;
                    vertical-align: middle;
                }

    .md_menus_head h3 {
        margin: 0px;
        padding: 0px;
        padding-left: 40px;
        font-size: 15px;
        color: white;
        text-transform: uppercase;
    }

    .md_menu_panel {
        width: 220px;
        height: 415px;
        /*margin-left: -200px;*/
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        left: 0px;
        top: 50px;
        display: none;
        position: absolute;
        z-index: 1000;
    }

    /*#region Search */

    .md_searchbar_wrapper {
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        text-align: center;
        display: inline-block;
    }

        .md_searchbar_wrapper .searchinput {
            width: 100% !important;
            height: 18px;
            margin: 10px 0 0 20px;
            border: 1px solid;
            padding: 10px 10px 10px 10px;
        }

        .md_searchbar_wrapper .RadSearchBox {
            width: 90%;
        }

            .md_searchbar_wrapper .RadSearchBox .rsbInner {
                width: 100% !important;
                height: 20px;
                margin: 0 0 0 0;
                border: 1px solid #e20d15;
                padding: 5px 5px 5px 5px;
            }

            .md_searchbar_wrapper .RadSearchBox .rsbInput {
                font: 15px Arial, Helvetica, sans-serif;
                color: #515151;
            }

        .md_searchbar_wrapper .RadSearchBoxPopup {
            border: 2px solid #F38800;
        }

    /*#endregion */



    .md_head_middle_right {
        margin: 0px;
        width: 19%;
        height: 100%;
        float: right;
        text-align: right;
        line-height: 100%;
    }

    .md_menus {
        margin: 0px;
        float: right;
        padding-right: 27px;
        padding-top: 45px;
        vertical-align: bottom;
    }



    .content_left {
        width: 100%;
        clear: both;
    }

    .content_left_alt {
        padding-left: 20px;
        padding-right: 20px;
    }

    .content_right {
        width: 100%;
        clear: both;
        padding-bottom: 10px;
    }

    .md_images_home {
        width: 480px;
        margin: 0 auto;
        text-align: center;
    }

    .img_description {
        /*min-width: 300px;*/
        clear: both;
        padding-left: 20px;
    }


    /*#region Smart Device Border blocks */

    .sd_panel {
        width: 50%;
        float: left;
    }

    .sd_left_holder {
        margin: 10px 10px 10px 20px; /*top right bottom left*/
        /*border: 2px solid black;*/
        /*min-width: 220px;
        min-height: 102px;*/
    }


    .sd_left_block {
        /*padding: 10px;
        display: block;
        background-color: #F3F3F3;
        min-height: 110px;*/
    }


    .sd_middle_holder {
        margin: 10px; /*10px 10px 10px 10px; top right bottom left*/
        /*border: 2px solid black;*/
    }

    .sd_middle_block {
        /*padding: 10px;
        display: block;
        background-color: #F3F3F3;
        min-height: 110px;*/
    }

    .sd_right_holder {
        margin: 10px 20px 10px 10px; /*top right bottom left*/
        /*border: 2px solid black;*/
    }

    .sd_right_block {
        /*padding: 10px;
        display: block;
        background-color: #f3f3f3;*/
        /*min-height: 110px;*/
    }

    /*#region Special Panel */

    .sd_special_panel {
        clear: both;
        width: 100%;
    }

    .sd_special_holder {
        margin: 0px 10px 10px 10px; /*10px 10px 10px 10px; top right bottom left*/
        display: block;
        /*border: 2px solid black;*/
    }

    .sd_special_block {
        /*padding: 10px;
        display: block;*/
        text-align: center;
    }

    /*#endregion */

    /*#endregion */

    footer {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .footer_top {
        width: 70%;
        margin: 0 auto;
        padding: 0px;
    }

        .footer_top ul {
            width: 100%;
            list-style-type: none;
            margin: 0 auto;
            padding: 0px;
        }

            .footer_top ul li {
                float: left;
                display: block;
                font-size: 14px;
                text-align: center;
                vertical-align: middle;
                line-height: 30px;
                height: 30px;
                background-color: white;
            }

                /*.footer_top ul li:nth-child(even) {
                    background-color: lightgray;
                }*/

                .footer_top ul li a:link, .footer_top ul li a:active, .footer_top ul li a:visited, .footer_top ul li a:hover {
                    color: black;
                    display: block;
                    line-height: 30px;
                    vertical-align: middle;
                }

                .footer_top ul li a:hover {
                    color: red;
                }

                .footer_top ul li.bar_alt {
                    width: 19px;
                    height: 26px;
                    background-image: url("/App_Themes/Skin_7/images/sep.gif");
                    background-repeat: no-repeat;
                    background-position: center 7px;
                }

                .footer_top ul li.next_line {
                    height: 0px;
                    float: none;
                    clear: both;
                }

    /*Set Margins for Devices*/
    /*.topic_text, .tophead {
        padding-left: 15px;
        padding-right: 15px;
    }*/


    .affiliate_panel {
        color: white;
    }

    .affiliate_right select {
        width: 130px;
    }

    div.prod_right_title h1 {
        font-size: 22px;
    }

    .prod_variant_title {
        width: 45%;
        float: left;
        /*color: #000;
`       font-weight: bold;*/
    }

    .prod_variant_price {
        width: 20%;
        float: left;
    }

    .prod_variant_seperator {
        width: 10%;
        float: left;
    }

    .prod_variant_addtocartbtn {
        width: 20%;
        float: right;
    }

    /*#region Category Page */

    .cat_img {
        width: 50%;
        float: left;
    }

        .cat_img img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

    .cat_details {
        width: 50%;
        float: left;
    }


    /*#endregion */

    .free_ship_alt {
        /*min-width: 320px;*/
        width: 100%;
        clear: both;
        height: 42px;
        line-height: 42px;
        vertical-align: middle;

        font-size: 15.5px;
        font-weight: normal;
        color: rgb(102,102,102);
        text-align: center;
    }

    .sd_feedback_link {
        clear: both;
        margin: 5px auto;
        border: solid 2px #000000;
        width: 220px;
        height: 50px;
        border-radius: 10px;
        background-color: #163a94;
        background-image: url('/PH%20Images/sd_feedback_hand_icon.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        text-align: center;
    }

        .sd_feedback_link a:link, .sd_feedback_link a:active, .sd_feedback_link a:hover, .sd_feedback_link a:visited {
            display: block;
            font-size: 20px;
            width: 100%;
            padding-top: 5px;
            color: white;
        }

    // .sd_ {
    //     /*padding-left:10px;
    //     padding-right:10px;*/
    //     margin: 0 auto;
    //     padding-top: 5px;
    //     padding-bottom: 15px;
    //     margin-top: 5px;
    //     clear: both;
    //     /*height: 30px;*/
    //     background-color: #3F3F3F;
    //     color: white;
    //     text-align: center;
    // }
    //
    //     .sd_footer a:link, .sd_footer a:hover, .sd_footer a:visited, .sd_footer a:active {
    //         color: white;
    //     }
    //
    //     .sd_footer a:horizontal {
    //         color: red;
    //     }
    //
    // .sd_footer_top {
    //     margin: 0 auto;
    // }
    //
    // .sd_footer_middle {
    //     padding-top: 10px;
    //     clear: both;
    // }

    .sd_cart_discounts {
        width: 100%;
        clear: both;
    }

    .sd_discounts {
        display: block;
        text-align: left;
    }

    .orderconfirmation_block {
        padding-left: 20px;
    }
}

/* Desktop scn_full */
@media screen and (min-width: 974px) {

    /*#region Common classes device specific style */


    /*.ShopHeading {
        color: #000000;

        font-size: 28px;
        font-weight: bold;
        white-space: nowrap;
    }

    .tophead {
        color: #3F3F3F;

        font-size: 15px;
        font-weight: bold;
    }*/


    /*Format propertyName_valueDesktop_valueTablet_valueMobile */
    /*.width_50_50_80 {
        width: 50%;
    }

    .pad_LR_0_20_20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pad_0_10_10 {
        padding: 10px 10px 10px 10px;
    }*/

    /*Set Screen Visibility*/
    .scn_full, .scn_full_md {
        display: block;
    }

    .scn_md, .scn_sm, .scn_md_sm,
    .scn_inline_md,
    .scn_inline_sm,
    .scn_inline_md_sm {
        display: none;
    }

    .scn_inline_full, .scn_inline_full_md {
        display: inline;
    }
    /*#endregion */

    .wrapper {
        background-color: white;
    }

    .contactus_header_text {
        width: 100%;
        margin: 0px;
        padding: 0px;
        float: right;

        color: #666;
        font-size: 11px;
        line-height: 20px;
        padding-top: 2px;
        font-weight: bold;
    }
    .contactus_header_text_right {
        margin: 0px;
        float: right;
        padding-top: 0px;
    }

    .contactus_header_text_left {
        margin: 0px;
        float: left;
        padding-top: 0px;
    }


    .contactus_header_text a {
        color: #666;
        font-size: 11px;
    }

    .contactus_header_text a:hover {

        color: #e20d15;
        text-decoration:underline;
    }

    .outerDivContactUsSChedule {
        width : 60%;
    }

    .dayNameContactUsSChedule {
        float: left;
        width: 0%;
        font-weight:bold;    }

    .startsOnContactUsSChedule {
        float: left;
        width: 50%;
    }
    .endsOnContactUsSChedule {
        float: left;
        width: 50%;
    }

    .innerDivContactUsSChedule {
        width: 100%;
        float: left;
    }

    .innerDivHeaderContactUsSChedule {
        width: 100%;
        float: left;
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .dayNameHeaderContactUsSChedule {
        float: left;
        width: 0%;

    }
    .startsOnHeaderContactUsSChedule {
        float: left;
        width: 50%;
    }
    .endsAtHeaderContactUsSChedule {
        float: left;
        width: 50%;
    }
    .hrSolidLineContactUsSChedule {
        width: 100%;
        float: left;
        background: url("/App_Themes/Skin_7/images/horizontal_solid_line.gif") no-repeat;
        height: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .hrDottedLineContactUsSChedule {
        width: 100%;
        float: left;
        background: url("/App_Themes/Skin_7/images/horizontal_line.gif") repeat-x;
        height: 10px;
        margin-top: 10px;
    }




    .prod_variant_title {
        width: 45%;
        float: left;
        /*color: #000;
    font-weight: bold;*/
    }

    .prod_variant_price {
        width: 20%;
        float: left;
    }

    .prod_variant_seperator {
        width: 10%;
        float: left;
    }

    .prod_variant_addtocartbtn {
        width: 20%;
        float: right;
    }

    .width_80 {
        width: 100%;
    }

    .white_bg {
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        overflow: hidden;
        z-index: -100;
    }

        .white_bg div {
            width: 974px;
            background-color: white;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
        }
}


/* Styles for the Stock email notification */

.WOEOSNotifyPanel {border:1px solid #111; background-color:#fff;display:none;}
.WOEOSNotifyPanel h5 {font-size:13px; background-color: #DDD; text-align: center; }
.WOEOSNotifyContent {padding: 5px;}

.WOEOSEnterEmail { text-align: left; padding: 5px 0px; }
.WOEOSEmailInput { width:60%; }
.WOEOSSubmit { text-align: center; }

a.WOEOSnotify {background:#050; color:#fff; font-size:11px; font-weight:bold; line-height:120%; padding:3px 10px; text-align: center;}
a.WOEOSnotify:hover{text-decoration:none;}


/* Shared theme css */

.error_signin {
    border: 1px solid #FF0000;
    color: #FF0000;
    padding: 5px 5px 5px 5px;
    display: block;
    width: 470px;
    padding: 0px 0px 0px 0px;
    margin-bottom: 10px;
}


.loading_img {
    background: #FFFFFF url('/PH Images/mpc/ajax-loader.gif') no-repeat 50%;
    background-color: #FFFFFF;
    border: solid 1px #c3c3c3;
    padding: 0px;
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 35%;
    margin-left: -50px;
    width: 50px;
    height: 50px;
}

.bitcoin_right_head {
    text-align: center;
}

@media (max-width: 974px) {
    .bitcoin_right_head {
        text-align: left;
        padding-left: 10px;
    }
}


.ApplyCardButton {
    background: transparent url('/PH Images/apply_card.png') no-repeat scroll center center;
    height: 27px !important;
    width: 100px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .ApplyCardButton:hover {
        background: transparent url('/PH Images/apply_card_rollover.png') no-repeat scroll center center;
        height: 27px !important;
        width: 100px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }


// .ApplyCouponButton {
//     background: transparent url('/PH Images/apply_coupon.png') no-repeat scroll center center;
//     height: 27px !important;
//     width: 100px;
//     font-size: 0px;
//     line-height: 0px;
//     cursor: pointer;
//     border: 0;
//     clear: none;
//     margin: 0px;
//     padding: 0px;
// }
//
//     .ApplyCouponButton:hover {
//         background: transparent url('/PH Images/apply_coupon_rollover.png') no-repeat scroll center center;
//         height: 27px !important;
//         width: 100px;
//         font-size: 0px;
//         line-height: 0px;
//         cursor: pointer;
//         border: 0;
//         clear: none;
//         margin: 0px;
//         padding: 0px;
//     }

.giftcard_list {
    list-style: none;
    padding: 0px;
}

ul.giftcard_list li {
    line-height: 30px;
}

// .content_left a, .content_right a, .content_div a {
//     text-decoration: underline;
// }

#Subcategory a, .right_prod_panel a, .right_prod_list a, #SubProduct a, #SubHeading a, .ProductProdHeader a, .checkout_bar a {
    text-decoration: none;
}

.print_email a {
    text-decoration: underline !important;
}

#content_dialog, .ui-dialog {
    max-width: 90%;
    max-height: 90%;
}

.echeck_dialogue_container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    /*padding-top: 50px;*/
    /*min-width: 700px;*/
    /*min-height: 500px;*/
}

.echeck_dialogue {
    background-color: rgba(255, 255, 255, 0.75);
    /*padding: 20px;*/
    margin: 0 auto;
    max-width: 540px;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
}

.signature-pad--body {
    position: relative;
    min-height: 120px;
}

.signature_caption {
    position: absolute;
    top: 120px;
    width: 98%;
    text-align: center;
    color: gray;
    z-index: 0;
}

.signature_lbl {
    position: absolute;
    top: 10px;
    color: black;
}

#sign-pad-holder, #cheque-img, #cheque-div {
    min-width: 92%;
    position: absolute;
}

#sign-pad-holder, #cheque-img {
    border: 1px dotted grey;
    z-index: 1;
    top: 20px;
    margin-top: 10px;
}

@media screen and (max-width: 640px) {
    #sign-pad-holder {
        margin-top: 20px;
        top: 30px;
    }
}

#sign-pad-holder {
    max-width: 100%;
    height: 150px;
    /*min-width:*/
}

#sign-pad {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 640px) {
}


#cheque-div, #cheque-img {
    top: 190px;
    display: none;
}


.signature-pad--actions {
    display: none;
}

@media screen and (max-width: 640px) {
    .signature-pad--actions {
        padding-top: 10px;
        display: block;
    }
}

/*.optional_terms_box label, .mandatory_terms_box label {
    display: block;
    clear: both;
    margin-bottom: 18px;
    overflow: auto;
}

span.terms_text {
    display: block;
    float: right;
    width: calc(100% - 30px);
    line-height: 18px;
}*/

.UpdatePasswordButton {
    background: transparent url('/PH Images/update_password.png') no-repeat scroll center center;
    height: 31px !important;
    width: 204px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    border: 0;
    clear: none;
    margin: 0px;
    padding: 0px;
}

    .UpdatePasswordButton:hover {
        background: transparent url('/PH Images/update_password_rollover.png') no-repeat scroll center center;
        height: 31px !important;
        width: 204px;
        font-size: 0px;
        line-height: 0px;
        cursor: pointer;
        border: 0;
        clear: none;
        margin: 0px;
        padding: 0px;
    }

#btnDOBHelp {
    cursor: pointer;
    line-height: 30px;
    color: black;
}

@media screen and (max-width: 667px) {
    .echeck_dialogue_container{
        /*max-width: 90%;
        max-height: 95%;*/
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    .rotate_Screen {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
    /*.echeck_dialogue_container {
        transform: rotate(-90deg);
    }*/
}
