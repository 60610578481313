/*------------------------------------*\
    Product details page
\*------------------------------------*/

.btn-add-cart-ajax {
    display: none;
}

.prod_item_price {
    .price-old,
    .price-new {
        display: block;
    }
}

.price-old {
    text-decoration: line-through;
}
