
/*------------------------------------*\
    LOADER
\*------------------------------------*/
@keyframes icon-spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@mixin loader() {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: flex;
    background: rgba(0,0,0,.3);
    color: #fff;
    font-size: 17px;

    &:before {
        @include icon;
        display: inline-block;
        content: $icon-spinner;
        animation: icon-spin 2s linear infinite;
    }
}

@mixin loading() {
    &:before {
        @include icon;
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        content: $icon-spinner;
        animation: icon-spin 2s linear infinite;
        transform: translate(-50%);
        color: #fff;
        font-size: 17px;
        z-index: 15;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,.3);
      color: #fff;
      font-size: 17px;
      z-index: 10;
    }
}


.loader,
#updateProgress,
#ajax_overlay {
    @include loader;
}

.loading_img {
    display: none !important;
}

.loading {
  @include loading;
}
