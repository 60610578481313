/*------------------------------------*\
    LOGIN
\*------------------------------------*/
.page-login {
}

.login-forgot-section {
    .submitted {
        display: none;
    }
}
