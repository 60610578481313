
/*------------------------------------*\
    Fonts
\*------------------------------------*/

$font-family-name: 'Arial MT';
$font-path: "/src/app/fonts/arial-mt";

$font-family-gotham: 'Gotham';
$font-path-gotham: "/src/app/fonts/gotham";

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-Light.eot');
    src: url('#{$font-path}/ArialMT-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-Light.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-Light.woff') format('woff'),
        url('#{$font-path}/ArialMT-Light.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-Light.svg#ArialMT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-LightItalic.eot');
    src: url('#{$font-path}/ArialMT-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-LightItalic.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-LightItalic.woff') format('woff'),
        url('#{$font-path}/ArialMT-LightItalic.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-LightItalic.svg#ArialMT-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT.eot');
    src: url('#{$font-path}/ArialMT.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT.woff2') format('woff2'),
        url('#{$font-path}/ArialMT.woff') format('woff'),
        url('#{$font-path}/ArialMT.ttf') format('truetype'),
        url('#{$font-path}/ArialMT.svg#ArialMT') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-Italic.eot');
    src: url('#{$font-path}/ArialMT-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-Italic.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-Italic.woff') format('woff'),
        url('#{$font-path}/ArialMT-Italic.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-Italic.svg#ArialMT-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-Medium.eot');
    src: url('#{$font-path}/ArialMT-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-Medium.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-Medium.woff') format('woff'),
        url('#{$font-path}/ArialMT-Medium.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-Medium.svg#ArialMT-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-MediumItalic.eot');
    src: url('#{$font-path}/ArialMT-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-MediumItalic.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-MediumItalic.woff') format('woff'),
        url('#{$font-path}/ArialMT-MediumItalic.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-MediumItalic.svg#ArialMT-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}


@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-Bold.eot');
    src: url('#{$font-path}/ArialMT-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-Bold.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-Bold.woff') format('woff'),
        url('#{$font-path}/ArialMT-Bold.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-Bold.svg#ArialMT-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-path}/ArialMT-BoldItalic.eot');
    src: url('#{$font-path}/ArialMT-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/ArialMT-BoldItalic.woff2') format('woff2'),
        url('#{$font-path}/ArialMT-BoldItalic.woff') format('woff'),
        url('#{$font-path}/ArialMT-BoldItalic.ttf') format('truetype'),
        url('#{$font-path}/ArialMT-BoldItalic.svg#ArialMT-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
}



/*------------------------------------*\
    Gotham
\*------------------------------------*/

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-Thin.eot');
    src: url('#{$font-path-gotham}/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-Thin.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-Thin.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-Thin.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-Thin.svg#Gotham-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-ThinItalic.eot');
    src: url('#{$font-path-gotham}/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-ThinItalic.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-ThinItalic.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-ThinItalic.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-ThinItalic.svg#Gotham-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-Light.eot');
    src: url('#{$font-path-gotham}/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-Light.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-Light.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-Light.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-LightItalic.eot');
    src: url('#{$font-path-gotham}/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-LightItalic.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-LightItalic.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-LightItalic.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-Book.eot');
    src: url('#{$font-path-gotham}/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-Book.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-Book.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-Book.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-BookItalic.eot');
    src: url('#{$font-path-gotham}/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-BookItalic.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-BookItalic.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-BookItalic.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-Bold.eot');
    src: url('#{$font-path-gotham}/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-Bold.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-Bold.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-Bold.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-BoldItalic.eot');
    src: url('#{$font-path-gotham}/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-BoldItalic.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-BoldItalic.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-BoldItalic.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-Ultra.eot');
    src: url('#{$font-path-gotham}/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-Ultra.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-Ultra.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-Ultra.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-Ultra.svg#Gotham-Ultra') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-gotham;
    src: url('#{$font-path-gotham}/Gotham-UltraItalic.eot');
    src: url('#{$font-path-gotham}/Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path-gotham}/Gotham-UltraItalic.woff2') format('woff2'),
        url('#{$font-path-gotham}/Gotham-UltraItalic.woff') format('woff'),
        url('#{$font-path-gotham}/Gotham-UltraItalic.ttf') format('truetype'),
        url('#{$font-path-gotham}/Gotham-UltraItalic.svg#Gotham-UltraItalic') format('svg');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}
